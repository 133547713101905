import { ESTADOS_TRAMITE } from "constants/constants";

export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === ''
}


export function calcularEdad(fecha) {
  let hoy = new Date();
  let cumpleanos = new Date(fecha);
  let edad = hoy.getFullYear() - cumpleanos.getFullYear();
  let m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
  }

  return edad;
}

export const devolverNombreEstadoTramite = (id, observado) => {
    if (id === ESTADOS_TRAMITE.DRAFT) {
      return 'Iniciada';
    } else if (id === ESTADOS_TRAMITE.PENDIENTE || (id === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && !observado)) {
      return 'Pendiente';
    } else if (id === ESTADOS_TRAMITE.EN_REVISION) {
      return 'En revisión';
    } else if (id === ESTADOS_TRAMITE.VALIDADO) {
      return 'Validado';
    } else if (id === ESTADOS_TRAMITE.CANCELADA) {
      return 'Cancelada';
    } else if (id === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && observado) {
      return 'Observada';
    } else if (id === ESTADOS_TRAMITE.FINALIZADA) {
      return 'Finalizada';
    } else if (id === ESTADOS_TRAMITE.PRE_CARGA_OBSERVACION) {
      return 'Pre carga observación';
    } else if (id === ESTADOS_TRAMITE.PRE_CARGA_VALIDADA) {
      return 'Pre carga validada';
    } else if (id === ESTADOS_TRAMITE.PRE_CARGA_FINALIZADA) {
      return 'Pre carga finalizada';
    } else if (id === ESTADOS_TRAMITE.PENDIENTE_REVISION_FINAL_TTEE) {
      return 'Pendiente revisión final TTEE';
    } else {
      return 'No definido';
    }
}

export const devolverFraseEstadoTramite = (id, observado) => {
  if(id === ESTADOS_TRAMITE.DRAFT){
      return 'Completar Carga'
  } else if (id === ESTADOS_TRAMITE.PENDIENTE_EN_REVISION && observado){
      return 'Modificar'
  } else {
      return 'Ver'
  }
}


export const formatDNI = (dni) => {
  if (typeof dni !== 'string') {
    dni = dni.toString();
  }
  return dni.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatCuit = (cuit) => {
  if (cuit) {
    return cuit.replace(/(\d{2})(\d{8})(\d{1})/g, '$1-$2-$3');
  }
  return '';
}

const convertirFecha = string => {
  if (string.search('/') === -1) {
      var info = string.split('-');

      if (info[1].length === 1)
          info[1] = "0" + info[1];
  
      if (info[2].length === 1)
          info[2] = "0" + info[2];
  
      return info[0] + '-' + info[1] + '-' + info[2];
  }
  string = string.slice(0, string.search(' '));
  var info = string.split('/');

  if (info[1].length === 1)
      info[1] = "0" + info[1];

  if (info[0].length === 1)
      info[0] = "0" + info[0];

  return info[2] + '-' + info[1] + '-' + info[0];
}

const convertirPrimeraLetraMinuscula = (str) => str.charAt(0).toLowerCase() + str.slice(1);

export const compararObjetos = (obj1, obj2, path = "") => {
  let diferencias = [];

  for (let key in obj1) {
      // const key = path ? `${path}.${key}` : key; 
      // const snakeCaseKey = key.toLowerCase();
      const convertedKey = convertirPrimeraLetraMinuscula(key); 

     if( key === "familiares" || key === "CodNacionalidad" || key === "Nacimiento" || key === "Observado" || key === "domicilios" || key === "cambiaFoto" || key === "TpoOsocial" || key === "adjuntos" || key === "TipoTramite" || key === "CodEnt" || key === "EmailRepetido" || key === "LegProf" || key === "exentoTasa") { 
        continue;
      }

      if (obj2.hasOwnProperty(convertedKey)) {
          if (typeof obj1[key] === "object" && obj1[key] !== null) {
              if (Array.isArray(obj1[key])) {
                  if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[convertedKey])) {
                      diferencias.push({ campo: key, valor1: obj1[key], valor2: obj2[convertedKey] });
                  }
              } else {
                  diferencias = diferencias.concat(compararObjetos(obj1[key], obj2[convertedKey], key));
              }
            }else if (typeof obj2[convertedKey] === "string" ? obj1[key].trim() !== obj2[convertedKey].trim() : obj1[key] !== obj2[convertedKey]) {
              // diferencias.push({ campo: key, valor1: obj1[key], valor2: obj2[convertedKey] });
              diferencias.push(key);
          }
      } 
  }

  return diferencias;
}

export const compararArrays = (arr1, arr2) => { 
  const diferencias = []; 

  if(arr1.length === 0 && arr2.length === 0) {
    return diferencias;
  }

  if(arr2.length === 0) {
    diferencias.push({
      index: 0,
      changedProperties: ["Agregó un familiar nuevo"],
    });

    return diferencias;
  }

  const minLength = Math.min(arr1.length, arr2.length);

  for (let index = 0; index < minLength; index++) {
    const item1 = arr1[index]; 
    const item2 = arr2[index]; 
    const changedProperties = []; 
      
    for (const key in item1) {
      if(key === "TipoDomicilio" || key === "edad" || key === "fechaBaja" || key === "fechaIngreso" || key === "fechaEnlace" || key === "id" || key === "cuit" || key === "nombreParentesco" || key === "nombreTipoDocumento" || key === "email" || key === "nroSucursal" || key === "tpoBaja" || key === "tpoOsocial"){
        continue;
      }

      if(key === "estado" && item1[key] === 0 && item2[key] === 1) {
        changedProperties.push(["Un familiar fue dado de baja"]);
      }

      if(key === "fechaNacimiento") {
        const [year, month, day] = item1[key].split("-");
        const value1 = `${day}/${month}/${year}`;
        const value2 = item2[key].split(" ")[0];
        if (convertirFecha(value1) !== convertirFecha(value2)) {
          changedProperties.push(key);
        }
        continue;
      }

        const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
        
        const value1 = typeof item1[key] === 'string' ? item1[key].trim() : item1[key];
        const value2 = typeof item2[normalizedKey] === 'string' ? item2[normalizedKey].trim() : item2[normalizedKey];

      if (value1 !== value2) {
          changedProperties.push(key);
      }
    }

    if (changedProperties.length > 0) {
        diferencias.push({
            index,
            changedProperties,
        });
    }
  };

  if (arr1.length > arr2.length) {
    if (diferencias.length > 0) {
      diferencias[diferencias.length - 1].changedProperties.push("Agregó un familiar nuevo");
    } else {
      diferencias.push({
        index: arr2.length,
        changedProperties: ["Agregó un familiar nuevo"],
      });
    }
  }

  return diferencias;
}

export const agregarUnDiaFecha = (fechaInscripcion) => {
  const date = new Date(fechaInscripcion);
  date.setDate(date.getDate() + 1); 

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

