import React, { Fragment, useState, useEffect } from 'react';

import { Autocomplete, Button, Card, CardContent, Fab, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, Modal, MenuItem, Typography, TextField, RadioGroup, Radio, Select } from '@mui/material';
import { Buscador } from 'components/matriculas/buscador';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2'
import {
    useLoadScript
} from "@react-google-maps/api";

import "@reach/combobox/styles.css";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { Mapa } from 'components/matriculas/mapa';
import { partidosList, getPartidosAsync } from 'app/features/Partidos/partidosSlice';
import { getTipoCalleAsync } from 'app/features/TipoCalles/tipoCallesSilice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { postLocalidades } from 'app/features/Localidades/localidadesAPI';
import {DomiciliosUsuarioRR} from '../domiciliosRR/DomiciliosUsuarioRR';

const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    color_rojo: {
        color: 'red'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        bomdhadow: 24,
        p: 4,
        '@media (max-width:900px)': {
            top: '93%',
        }
    },
    modales: {
        overflow: 'scroll',

    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    mapElements: {
        height: '100%'
    }
}));

export const Domicilios = ({ datos, bloquearBtn, setDatos, location, setLocation, ValorTab, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, editarDatos, idEstado, libraries, setMatriculas }) => {
    const clases = useStyles();
    
    if (datos.domicilios[0].TpoDomicilio !== null && datos.domicilios[0].TpoDomicilio !== undefined && datos.domicilios[0].TpoDomicilio !== 0) {
        datos.domicilios.sort(function (a, b) {

            if (a.TpoDomicilio > b.TpoDomicilio) {
                return 1;
            }
            if (a.TpoDomicilio < b.TpoDomicilio) {
                return -1;
            }

            // a must be equal to b

            return 0;
        });
    }

    const dispatch = useDispatch();
    const Partidos = useSelector(partidosList);

    const [domiciliosProfesionales, setDomiciliosProfesionales] = useState({
        DomicilioRealCaja: {

            TpoDomicilio: 5,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioCorrespondencia: {
            TpoDomicilio: 4,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioConstituido: {
            TpoDomicilio: 3,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioProfesional: {
            TpoDomicilio: 2,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        },
        DomicilioParticular: {
            TpoDomicilio: 1,
            localidad: '',
            codLocalidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            delegacion: '',
            codPostal: '',
            partido: '',
            provincia: '',
            telefono: '',
            caracteristica: '',
            caracteristicaFijo: '',
            celular: '',
            tpoCalle: 0,
            edito: true
        }
    });


    const [datosDocimilio, setDatosDocimilio] = useState({
        localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristica: '',
        celular: '', codLocalidad: '', edito: true
    });
    const [open, setOpen] = useState(false);
    const [TpoDomicilio, setTipoDomicilio] = useState(0);

    const [tituloModal, setTituloModal] = useState('');

    const { handleSubmit, formState: { errors } } = useForm();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let partidosSelect = [];
    const [tipoCalleSelect, setTipoCalleSelect] = useState([]);
    const inputHandleChange = (evt) => {

        //se limitan los caracteres para que no haga problemas cuando inserta en el SQL

        if (evt.target.name == "caracteristica" || evt.target.name == "caracteristicaFijo") {
            evt.target.value = evt.target.value.replace(/\-/g, '').replace(/[^0-9]/g, "").replace(/,/g, '');
            if (evt.target.value.lenght > 4) return;
        }
        if (evt.target.name == "celular" || evt.target.name == "telefono") {
            evt.target.value = evt.target.value.replace(/\-/g, '').replace(/[^0-9]/g, "").replace(/,/g, '');
            if(evt.target.value.length > 9)  return;
        }

        if ((evt.target.name == "piso" || evt.target.name == "depto" || evt.target.name == "numero") && evt.target.value.length > 18)
            return false;

        if (evt.target.name == "codPostal" && evt.target.value.length > 10)
            return false;

          if(evt.target.name == "codPostal")  
          {
            evt.target.value = evt.target.value.replace(/[^0-9]+/g, ""); 
          }
          

        if ((evt.target.name == "calle") && evt.target.value.length > 50)
            return false;

        if ((evt.target.name == "caracteristica" || evt.target.name == "caracteristicaFijo") && evt.target.value.length > 5)
            return false;

        setDatosDocimilio({
            ...datosDocimilio,
            [evt.target.name]: evt.target.name === "tpoCarga" ? Number(evt.target.value) : evt.target.value
        })
    }

    let datosDomicilioRealCaja = {};
    let datosDomicilioCorrespondencia = {};
    let datosDomiciliosConstituido = {};
    let datosDomiciliosProfesional = {};
    let datosDomiciliosParticular = {};

    const [listaLocalidades, setListaLocalidades] = useState([]);


    const buscarLocalidades = letras => {
        let datos = {
            nombreLocalidad: letras,
            nombreProvincia: '',
            codPostal: ''
        }

        if (letras.trim().length > 2) {
            postLocalidades(datos).then(result => {
                let localidadesArr = [];
                let nombreRepetido = "";

                result.data.map((ld, index) => {
                    if (nombreRepetido !== ld.nombreLocalidad) {

                        //    if(index === 0){
                        //     setDatosDocimilio({
                        //         ...datosDocimilio,
                        //         codLocalidad: String(ld.codLocalidad),
                        //         localidad:  ld.nombreLocalidad
                        //     })
                        //    }
                        localidadesArr.push({ key: (Math.random() * (999999 - 1)), label: ld.nombreLocalidad, value: ld.codLocalidad, codPartido: ld.codPartido })
                        nombreRepetido = ld.nombreLocalidad;
                    }

                })

                setListaLocalidades(localidadesArr);
            })
        }

    }

    const [errorTelefono, setErrorTelefono] = useState({ display: "none" });
    const [errorTelefonoTexto, setErrorTelefonoText] = useState('');

    const guardarDatosTemporales = () => {

        if (isNaN(datosDocimilio.caracteristica)) {
            setErrorTelefonoText("La Caracteristica del teléfono Celular debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }

        if (isNaN(datosDocimilio.caracteristicaFijo)) {
            setErrorTelefonoText("La Caracteristica del teléfono Fijo debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }
        if (isNaN(datosDocimilio.celular)) {
            setErrorTelefonoText("El Teléfono Celular debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }
        if (isNaN(datosDocimilio.telefono)) {
            setErrorTelefonoText("El Teléfono fijo debe contener solo números")
            setErrorTelefono({ display: "" })
            return false;
        }
        setOpen(false);
        if (TpoDomicilio === 1) {
            setBloquearBtnEdit(false);
            setDomiciliosProfesionales({
                ...domiciliosProfesionales,
                DomicilioParticular: {
                    TpoDomicilio: 1,
                    localidad: datosDocimilio.localidad,
                    codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                    calle: datosDocimilio.calle,
                    numero: datosDocimilio.numero,
                    piso: datosDocimilio.piso,
                    depto: datosDocimilio.depto,
                    delegacion: datosDocimilio.delegacion,
                    codPostal: datosDocimilio.codPostal,
                    partido: datosDocimilio.partido,
                    provincia: datosDocimilio.provincia,
                    telefono: datosDocimilio.telefono,
                    caracteristica: datosDocimilio.caracteristica,
                    caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                    celular: datosDocimilio.celular,
                    tpoCalle: datosDocimilio.tpoCalle,
                    edito: true,
                    tpoCarga: datosDocimilio.tpoCarga
                }
            });

            setDatos({
                ...datos,
                domicilios: [
                    domiciliosProfesionales.DomicilioProfesional,
                    domiciliosProfesionales.DomicilioConstituido,
                    domiciliosProfesionales.DomicilioCorrespondencia,
                    domiciliosProfesionales.DomicilioRealCaja,
                    {
                        TpoDomicilio: 1,
                        localidad: datosDocimilio.localidad,
                        codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                        calle: datosDocimilio.calle,
                        numero: datosDocimilio.numero,
                        piso: datosDocimilio.piso,
                        depto: datosDocimilio.depto,
                        delegacion: datosDocimilio.delegacion,
                        codPostal: datosDocimilio.codPostal,
                        partido: datosDocimilio.partido,
                        provincia: datosDocimilio.provincia,
                        telefono: datosDocimilio.telefono,
                        caracteristica: datosDocimilio.caracteristica,
                        caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                        celular: datosDocimilio.celular,
                        tpoCalle: datosDocimilio.tpoCalle,
                        edito: true,
                        tpoCarga: datosDocimilio.tpoCarga
                    }
                ]
            });
        }
        if (editarDatos === 1 || TpoDomicilio === 1) {

            Swal.fire({
                title: 'Atención',
                html: localStorage.userType === "3" ? '¿Desea utilizar este domicilio y teléfono para los demas?' : '¿Desea utilizar este domicilio  para los demas?',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {

                    setDomiciliosProfesionales({
                        DomicilioParticular: {
                            TpoDomicilio: 1,
                            localidad: datosDocimilio.localidad,
                            codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                            calle: datosDocimilio.calle,
                            numero: datosDocimilio.numero,
                            piso: datosDocimilio.piso,
                            depto: datosDocimilio.depto,
                            delegacion: datosDocimilio.delegacion,
                            codPostal: datosDocimilio.codPostal,
                            partido: datosDocimilio.partido,
                            provincia: datosDocimilio.provincia,
                            telefono: datosDocimilio.telefono,
                            caracteristica: datosDocimilio.caracteristica,
                            caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                            celular: datosDocimilio.celular,
                            tpoCalle: datosDocimilio.tpoCalle,
                            edito: true,
                            tpoCarga: datosDocimilio.tpoCarga
                        },
                        DomicilioProfesional: {
                            TpoDomicilio: 2,
                            localidad: datosDocimilio.localidad,
                            codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                            calle: datosDocimilio.calle,
                            numero: datosDocimilio.numero,
                            piso: datosDocimilio.piso,
                            depto: datosDocimilio.depto,
                            delegacion: datosDocimilio.delegacion,
                            codPostal: datosDocimilio.codPostal,
                            partido: datosDocimilio.partido,
                            provincia: datosDocimilio.provincia,
                            telefono: datosDocimilio.telefono,
                            caracteristica: datosDocimilio.caracteristica,
                            caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                            celular: datosDocimilio.celular,
                            tpoCalle: datosDocimilio.tpoCalle,
                            edito: true,
                            tpoCarga: datosDocimilio.tpoCarga
                        },
                        DomicilioConstituido: {
                            TpoDomicilio: 3,
                            localidad: datosDocimilio.localidad,
                            codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                            calle: datosDocimilio.calle,
                            numero: datosDocimilio.numero,
                            piso: datosDocimilio.piso,
                            depto: datosDocimilio.depto,
                            delegacion: datosDocimilio.delegacion,
                            codPostal: datosDocimilio.codPostal,
                            partido: datosDocimilio.partido,
                            provincia: datosDocimilio.provincia,
                            telefono: datosDocimilio.telefono,
                            caracteristica: datosDocimilio.caracteristica,
                            caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                            celular: datosDocimilio.celular,
                            tpoCalle: datosDocimilio.tpoCalle,
                            edito: true,
                            tpoCarga: datosDocimilio.tpoCarga
                        },
                        DomicilioCorrespondencia: {
                            TpoDomicilio: 4,
                            localidad: datosDocimilio.localidad,
                            codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                            calle: datosDocimilio.calle,
                            numero: datosDocimilio.numero,
                            piso: datosDocimilio.piso,
                            depto: datosDocimilio.depto,
                            delegacion: datosDocimilio.delegacion,
                            codPostal: datosDocimilio.codPostal,
                            partido: datosDocimilio.partido,
                            provincia: datosDocimilio.provincia,
                            telefono: datosDocimilio.telefono,
                            caracteristica: datosDocimilio.caracteristica,
                            caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                            celular: datosDocimilio.celular,
                            tpoCalle: datosDocimilio.tpoCalle,
                            edito: true,
                            tpoCarga: datosDocimilio.tpoCarga
                        },
                        DomicilioRealCaja: {
                            TpoDomicilio: 5,
                            localidad: datosDocimilio.localidad,
                            codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                            calle: datosDocimilio.calle,
                            numero: datosDocimilio.numero,
                            piso: datosDocimilio.piso,
                            depto: datosDocimilio.depto,
                            delegacion: datosDocimilio.delegacion,
                            codPostal: datosDocimilio.codPostal,
                            partido: datosDocimilio.partido,
                            provincia: datosDocimilio.provincia,
                            telefono: datosDocimilio.telefono,
                            caracteristica: datosDocimilio.caracteristica,
                            caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                            celular: datosDocimilio.celular,
                            tpoCalle: datosDocimilio.tpoCalle,
                            edito: true,
                            tpoCarga: datosDocimilio.tpoCarga
                        }
                    })
                    setDatos({
                        ...datos,
                        domicilios: [
                            {
                                TpoDomicilio: 1,
                                localidad: datosDocimilio.localidad,
                                codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                                calle: datosDocimilio.calle,
                                numero: datosDocimilio.numero,
                                piso: datosDocimilio.piso,
                                depto: datosDocimilio.depto,
                                delegacion: datosDocimilio.delegacion,
                                codPostal: datosDocimilio.codPostal,
                                partido: datosDocimilio.partido,
                                provincia: datosDocimilio.provincia,
                                telefono: datosDocimilio.telefono,
                                caracteristica: datosDocimilio.caracteristica,
                                caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                                celular: datosDocimilio.celular,
                                tpoCalle: datosDocimilio.tpoCalle,
                                edito: true,
                                tpoCarga: datosDocimilio.tpoCarga
                            },
                            {
                                TpoDomicilio: 2,
                                localidad: datosDocimilio.localidad,
                                codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                                calle: datosDocimilio.calle,
                                numero: datosDocimilio.numero,
                                piso: datosDocimilio.piso,
                                depto: datosDocimilio.depto,
                                delegacion: datosDocimilio.delegacion,
                                codPostal: datosDocimilio.codPostal,
                                partido: datosDocimilio.partido,
                                provincia: datosDocimilio.provincia,
                                telefono: datosDocimilio.telefono,
                                caracteristica: datosDocimilio.caracteristica,
                                caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                                celular: datosDocimilio.celular,
                                tpoCalle: datosDocimilio.tpoCalle,
                                edito: true,
                                tpoCarga: datosDocimilio.tpoCarga
                            },
                            {
                                TpoDomicilio: 3,
                                localidad: datosDocimilio.localidad,
                                codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                                calle: datosDocimilio.calle,
                                numero: datosDocimilio.numero,
                                piso: datosDocimilio.piso,
                                depto: datosDocimilio.depto,
                                delegacion: datosDocimilio.delegacion,
                                codPostal: datosDocimilio.codPostal,
                                partido: datosDocimilio.partido,
                                provincia: datosDocimilio.provincia,
                                telefono: datosDocimilio.telefono,
                                caracteristica: datosDocimilio.caracteristica,
                                caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                                celular: datosDocimilio.celular,
                                tpoCalle: datosDocimilio.tpoCalle,
                                edito: true,
                                tpoCarga: datosDocimilio.tpoCarga
                            },
                            {
                                TpoDomicilio: 4,
                                localidad: datosDocimilio.localidad,
                                codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                                calle: datosDocimilio.calle,
                                numero: datosDocimilio.numero,
                                piso: datosDocimilio.piso,
                                depto: datosDocimilio.depto,
                                delegacion: datosDocimilio.delegacion,
                                codPostal: datosDocimilio.codPostal,
                                partido: datosDocimilio.partido,
                                provincia: datosDocimilio.provincia,
                                telefono: datosDocimilio.telefono,
                                caracteristica: datosDocimilio.caracteristica,
                                caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                                celular: datosDocimilio.celular,
                                tpoCalle: datosDocimilio.tpoCalle,
                                edito: true,
                                tpoCarga: datosDocimilio.tpoCarga
                            },
                            {
                                TpoDomicilio: 5,
                                localidad: datosDocimilio.localidad,
                                codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                                calle: datosDocimilio.calle,
                                numero: datosDocimilio.numero,
                                piso: datosDocimilio.piso,
                                depto: datosDocimilio.depto,
                                delegacion: datosDocimilio.delegacion,
                                codPostal: datosDocimilio.codPostal,
                                partido: datosDocimilio.partido,
                                provincia: datosDocimilio.provincia,
                                telefono: datosDocimilio.telefono,
                                caracteristica: datosDocimilio.caracteristica,
                                caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                                celular: datosDocimilio.celular,
                                tpoCalle: datosDocimilio.tpoCalle,
                                edito: true,
                                tpoCarga: datosDocimilio.tpoCarga
                            }
                        ]
                    });
                }

            });



        }
        if (TpoDomicilio === 2) {
            setDomiciliosProfesionales({

                ...domiciliosProfesionales,
                DomicilioProfesional: {
                    TpoDomicilio: 2,
                    localidad: datosDocimilio.localidad,
                    codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                    calle: datosDocimilio.calle,
                    numero: datosDocimilio.numero,
                    piso: datosDocimilio.piso,
                    depto: datosDocimilio.depto,
                    delegacion: datosDocimilio.delegacion,
                    codPostal: datosDocimilio.codPostal,
                    partido: datosDocimilio.partido,
                    provincia: datosDocimilio.provincia,
                    telefono: datosDocimilio.telefono,
                    caracteristica: datosDocimilio.caracteristica,
                    caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                    celular: datosDocimilio.celular,
                    tpoCalle: datosDocimilio.tpoCalle,
                    edito: true,
                    tpoCarga: datosDocimilio.tpoCarga
                }
            })
            setDatos({
                ...datos,
                domicilios: [
                    domiciliosProfesionales.DomicilioParticular,
                    domiciliosProfesionales.DomicilioConstituido,
                    domiciliosProfesionales.DomicilioCorrespondencia,
                    domiciliosProfesionales.DomicilioRealCaja,
                    {
                        TpoDomicilio: 2,
                        localidad: datosDocimilio.localidad,
                        codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                        calle: datosDocimilio.calle,
                        numero: datosDocimilio.numero,
                        piso: datosDocimilio.piso,
                        depto: datosDocimilio.depto,
                        delegacion: datosDocimilio.delegacion,
                        codPostal: datosDocimilio.codPostal,
                        partido: datosDocimilio.partido,
                        provincia: datosDocimilio.provincia,
                        telefono: datosDocimilio.telefono,
                        caracteristica: datosDocimilio.caracteristica,
                        caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                        celular: datosDocimilio.celular,
                        tpoCalle: datosDocimilio.tpoCalle,
                        edito: true,
                        tpoCarga: datosDocimilio.tpoCarga
                    }
                ]
            });
        }
        if (TpoDomicilio === 3) {
            setDomiciliosProfesionales({

                ...domiciliosProfesionales,
                DomicilioConstituido: {
                    TpoDomicilio: 3,
                    localidad: datosDocimilio.localidad,
                    codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                    calle: datosDocimilio.calle,
                    numero: datosDocimilio.numero,
                    piso: datosDocimilio.piso,
                    depto: datosDocimilio.depto,
                    delegacion: datosDocimilio.delegacion,
                    codPostal: datosDocimilio.codPostal,
                    partido: datosDocimilio.partido,
                    provincia: datosDocimilio.provincia,
                    telefono: datosDocimilio.telefono,
                    caracteristica: datosDocimilio.caracteristica,
                    caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                    celular: datosDocimilio.celular,
                    tpoCalle: datosDocimilio.tpoCalle,
                    edito: true,
                    tpoCarga: datosDocimilio.tpoCarga
                }
            })
            setDatos({
                ...datos,
                domicilios: [
                    domiciliosProfesionales.DomicilioParticular,
                    domiciliosProfesionales.DomicilioProfesional,
                    domiciliosProfesionales.DomicilioCorrespondencia,
                    domiciliosProfesionales.DomicilioRealCaja,
                    {
                        TpoDomicilio: 3,
                        localidad: datosDocimilio.localidad,
                        codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                        calle: datosDocimilio.calle,
                        numero: datosDocimilio.numero,
                        piso: datosDocimilio.piso,
                        depto: datosDocimilio.depto,
                        delegacion: datosDocimilio.delegacion,
                        codPostal: datosDocimilio.codPostal,
                        partido: datosDocimilio.partido,
                        provincia: datosDocimilio.provincia,
                        telefono: datosDocimilio.telefono,
                        caracteristica: datosDocimilio.caracteristica,
                        caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                        celular: datosDocimilio.celular,
                        tpoCalle: datosDocimilio.tpoCalle,
                        edito: true,
                        tpoCarga: datosDocimilio.tpoCarga
                    }
                ]
            });
        }
        if (TpoDomicilio === 4) {
            setDomiciliosProfesionales({

                ...domiciliosProfesionales,
                DomicilioCorrespondencia: {
                    TpoDomicilio: 4,
                    localidad: datosDocimilio.localidad,
                    codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                    calle: datosDocimilio.calle,
                    numero: datosDocimilio.numero,
                    piso: datosDocimilio.piso,
                    depto: datosDocimilio.depto,
                    delegacion: datosDocimilio.delegacion,
                    codPostal: datosDocimilio.codPostal,
                    partido: datosDocimilio.partido,
                    provincia: datosDocimilio.provincia,
                    telefono: datosDocimilio.telefono,
                    caracteristica: datosDocimilio.caracteristica,
                    caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                    celular: datosDocimilio.celular,
                    tpoCalle: datosDocimilio.tpoCalle,
                    edito: true,
                    tpoCarga: datosDocimilio.tpoCarga
                }
            })

            setDatos({
                ...datos,
                domicilios: [
                    domiciliosProfesionales.DomicilioParticular,
                    domiciliosProfesionales.DomicilioProfesional,
                    domiciliosProfesionales.DomicilioConstituido,
                    domiciliosProfesionales.DomicilioRealCaja,
                    {
                        TpoDomicilio: 4,
                        localidad: datosDocimilio.localidad,
                        codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                        calle: datosDocimilio.calle,
                        numero: datosDocimilio.numero,
                        piso: datosDocimilio.piso,
                        depto: datosDocimilio.depto,
                        delegacion: datosDocimilio.delegacion,
                        codPostal: datosDocimilio.codPostal,
                        partido: datosDocimilio.partido,
                        provincia: datosDocimilio.provincia,
                        telefono: datosDocimilio.telefono,
                        caracteristica: datosDocimilio.caracteristica,
                        caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                        celular: datosDocimilio.celular,
                        tpoCalle: datosDocimilio.tpoCalle,
                        edito: true,
                        tpoCarga: datosDocimilio.tpoCarga
                    }
                ]
            });
        }
        if (TpoDomicilio === 5) {
            setDomiciliosProfesionales({

                ...domiciliosProfesionales,
                DomicilioRealCaja: {
                    TpoDomicilio: 5,
                    localidad: datosDocimilio.localidad,
                    codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                    calle: datosDocimilio.calle,
                    numero: datosDocimilio.numero,
                    piso: datosDocimilio.piso,
                    depto: datosDocimilio.depto,
                    delegacion: datosDocimilio.delegacion,
                    codPostal: datosDocimilio.codPostal,
                    partido: datosDocimilio.partido,
                    provincia: datosDocimilio.provincia,
                    telefono: datosDocimilio.telefono,
                    caracteristica: datosDocimilio.caracteristica,
                    caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                    celular: datosDocimilio.celular,
                    tpoCalle: datosDocimilio.tpoCalle,
                    edito: true,
                    tpoCarga: datosDocimilio.tpoCarga
                }
            })
            setDatos({
                ...datos,
                domicilios: [
                    domiciliosProfesionales.DomicilioParticular,
                    domiciliosProfesionales.DomicilioProfesional,
                    domiciliosProfesionales.DomicilioConstituido,
                    domiciliosProfesionales.DomicilioCorrespondencia,
                    {
                        TpoDomicilio: 5,
                        localidad: datosDocimilio.localidad,
                        codLocalidad: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : '',
                        calle: datosDocimilio.calle,
                        numero: datosDocimilio.numero,
                        piso: datosDocimilio.piso,
                        depto: datosDocimilio.depto,
                        delegacion: datosDocimilio.delegacion,
                        codPostal: datosDocimilio.codPostal,
                        partido: datosDocimilio.partido,
                        provincia: datosDocimilio.provincia,
                        telefono: datosDocimilio.telefono,
                        caracteristica: datosDocimilio.caracteristica,
                        caracteristicaFijo: datosDocimilio.caracteristicaFijo,
                        celular: datosDocimilio.celular,
                        tpoCalle: datosDocimilio.tpoCalle,
                        edito: true,
                        tpoCarga: datosDocimilio.tpoCarga
                    }
                ]
            });
        }


    }

    const [bloquearBtnEdit, setBloquearBtnEdit] = useState(true);


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBb5RiBio4TouN2IjNLz-LVW4BbIZH0eE8",
        libraries
    })

    if (datosDocimilio.provincia !== "" || Number(datosDocimilio.tpoCarga) === 1) {


        let provincia = datosDocimilio.provincia;

        if (provincia === "Provincia de Buenos Aires")
            provincia = "Buenos Aires";

        if (provincia === "CABA")
            provincia = "Capital Federal";

        partidosSelect = [];


        Partidos.map((partido, index) => {
            if (provincia.normalize("NFD").replace(/[\u0300-\u036f]/g, "") === partido.provincia.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || datosDocimilio.tpoCarga === 1) {
                partidosSelect.push({ label: partido.descripcion.trim(), value: partido.descripcion.trim() })

            }

        })




    }

    const [anchoPantalla, setAnchoPantalla] = useState(0)

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    window.addEventListener("resize", handleResize);

    useEffect(() => {

        setAnchoPantalla(window.screen.width);
        window.addEventListener("resize", handleResize);
        if (Partidos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getPartidosAsync());
        }

        dispatch(getTipoCalleAsync()).then(response => {
            let tipoCalle = [];

            response.payload.map(tpoCalle => {
                tipoCalle.push({ value: tpoCalle.tpoCalle, label: tpoCalle.denTpoCalle })
            })

            setTipoCalleSelect(tipoCalle);
        })
        //setDomiciliosProfesionales();
        datos.domicilios.map((domicilio, index) => {

            if (index + 1 === 1 && domicilio.calle !== "" && domicilio.provincia !== "") {
                setBloquearBtnEdit(false)

            }

            if (domicilio.TpoDomicilio === 5 || ((domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && String(index + 1) === "5")) {

                datosDomicilioRealCaja = {
                    TpoDomicilio: 5,
                    localidad: domicilio.localidad,
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero,
                    piso: domicilio.piso,
                    depto: domicilio.depto,
                    delegacion: domicilio.delegacion,
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido,
                    provincia: domicilio.provincia,
                    telefono: domicilio.telefono,
                    caracteristica: domicilio.caracteristica,
                    caracteristicaFijo: domicilio.caracteristicaFijo,
                    celular: domicilio.celular,
                    tpoCalle: domicilio.tpoCalle === undefined ? 0 : domicilio.tpoCalle,
                    edito: false,
                    tpoCarga: domicilio.tpoCarga === undefined ? 1 : domicilio.tpoCarga
                };
            }
            if (domicilio.TpoDomicilio === 4 || ((domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && String(index + 1) === "4")) {
                datosDomicilioCorrespondencia = {

                    TpoDomicilio: 4,
                    localidad: domicilio.localidad,
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero,
                    piso: domicilio.piso,
                    depto: domicilio.depto,
                    delegacion: domicilio.delegacion,
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido,
                    provincia: domicilio.provincia,
                    telefono: domicilio.telefono,
                    caracteristica: domicilio.caracteristica,
                    caracteristicaFijo: domicilio.caracteristicaFijo,
                    celular: domicilio.celular,
                    tpoCalle: domicilio.tpoCalle === undefined ? 0 : domicilio.tpoCalle,
                    edito: false,
                    tpoCarga: domicilio.tpoCarga === undefined ? 1 : domicilio.tpoCarga
                };
            }
            if (domicilio.TpoDomicilio === 3 || ((domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && String(index + 1) === "3")) {
                datosDomiciliosConstituido = {

                    TpoDomicilio: 3,
                    localidad: domicilio.localidad,
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero,
                    piso: domicilio.piso,
                    depto: domicilio.depto,
                    delegacion: domicilio.delegacion,
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido,
                    provincia: domicilio.provincia,
                    telefono: domicilio.telefono,
                    caracteristica: domicilio.caracteristica,
                    caracteristicaFijo: domicilio.caracteristicaFijo,
                    celular: domicilio.celular,
                    tpoCalle: domicilio.tpoCalle === undefined ? 0 : domicilio.tpoCalle,
                    edito: false,
                    tpoCarga: domicilio.tpoCarga === undefined ? 1 : domicilio.tpoCarga
                };
            }

            if (domicilio.TpoDomicilio === 2 || ((domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && String(index + 1) === "2")) {
                datosDomiciliosProfesional = {
                    TpoDomicilio: 2,
                    localidad: domicilio.localidad,
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero,
                    piso: domicilio.piso,
                    depto: domicilio.depto,
                    delegacion: domicilio.delegacion,
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido,
                    provincia: domicilio.provincia,
                    telefono: domicilio.telefono,
                    caracteristica: domicilio.caracteristica,
                    caracteristicaFijo: domicilio.caracteristicaFijo,
                    celular: domicilio.celular,
                    tpoCalle: domicilio.tpoCalle === undefined ? 0 : domicilio.tpoCalle,
                    edito: false,
                    tpoCarga: domicilio.tpoCarga === undefined ? 1 : domicilio.tpoCarga
                };
            }

            if (domicilio.TpoDomicilio === 1 || ((domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && String(index + 1) === "1")) {

                datosDomiciliosParticular = {
                    TpoDomicilio: 1,
                    localidad: domicilio.localidad,
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero,
                    piso: domicilio.piso,
                    depto: domicilio.depto,
                    delegacion: domicilio.delegacion,
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido,
                    provincia: domicilio.provincia,
                    telefono: domicilio.telefono,
                    caracteristica: domicilio.caracteristica,
                    caracteristicaFijo: domicilio.caracteristicaFijo,
                    celular: domicilio.celular,
                    tpoCalle: domicilio.tpoCalle === undefined ? 0 : domicilio.tpoCalle,
                    edito: false,
                    tpoCarga: domicilio.tpoCarga === undefined ? 1 : domicilio.tpoCarga
                };

            }

        });
        setDomiciliosProfesionales({
            DomicilioParticular: datosDomiciliosParticular,
            DomicilioProfesional: datosDomiciliosProfesional,
            DomicilioConstituido: datosDomiciliosConstituido,
            DomicilioCorrespondencia: datosDomicilioCorrespondencia,
            DomicilioRealCaja: datosDomicilioRealCaja
        });

    }, [])

    const guardarContinuar = () => {

        let erroresMostrar = "";
        let mostrarErrorDomicilio = 0;
        let errorCaracteristica = 0;
        let errorCelular = 0;
        let errorCodLocalidad = 0;
        let errorCodPostal = 0;
        let errorPartido = 0;
        let errorTpoCalle = 0;
        datos.domicilios.map((domicilio, index) => {

            if (errorCaracteristica === 0 && (domicilio.caracteristica === null || domicilio.caracteristica === "" || isNaN(domicilio.caracteristica))) {

                if (erroresMostrar === "")
                    erroresMostrar = "<b>Característica Celular</b>";
                else
                    erroresMostrar += ", <b> Característica Celular</b>";

                errorCaracteristica = 1;
            }

            if (errorCelular === 0 && (domicilio.celular === null || domicilio.celular === "" || isNaN(domicilio.celular))) {

                if (erroresMostrar === "")
                    erroresMostrar = "<b>Celular</b>";
                else
                    erroresMostrar += ", <b>Celular</b>";

                errorCelular = 1;
            }

            if (errorCodLocalidad === 0 && (domicilio.codLocalidad === null || domicilio.codLocalidad === "" || domicilio.codLocalidad === 0)) {

                if (erroresMostrar === "")
                    erroresMostrar = "<b>Localidad</b>";
                else
                    erroresMostrar += ", <b>Localidad</b>";

                errorCodLocalidad = 1;
            }

            if (errorCodPostal === 0 && (domicilio.codPostal === null || domicilio.codPostal === "")) {

                if (erroresMostrar === "")
                    erroresMostrar = "<b>Código Postal</b>";
                else
                    erroresMostrar += ", <b>Código Postal</b>";

                errorCodPostal = 1;
            }

            if (errorPartido === 0 && (domicilio.partido === null || domicilio.partido === "")) {

                if (erroresMostrar === "")
                    erroresMostrar = "<b>Partido</b>";
                else
                    erroresMostrar += ", <b>Partido</b>";

                errorPartido = 1;
            }


            if (errorTpoCalle === 0 && (domicilio.tpoCalle === null || domicilio.tpoCalle === "" || domicilio.tpoCalle === 0)) {

                if (erroresMostrar === "")
                    erroresMostrar = "<b>Tipo de Calle</b>";
                else
                    erroresMostrar += ", <b>Tipo de Calle</b>";

                errorTpoCalle = 1;
            }


        })


        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos: ' + erroresMostrar,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            return false;
        }

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);

        // let adjuntosTemporales = [];
        // datos.adjuntos.map((adj, i) => {
        //     adjuntosTemporales.push({
        //         url: adj.url,
        //         file: adj.file,
        //         fileType: adj.fileType,
        //         fileName: adj.fileName
        //     })
        // })

        // datos.adjuntos.map((adj, i) => {
        //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].file = '';
        //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].url = '';
        // })

        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 1 }).then((value) => {
                setMatriculas(value.data);
                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                if (value.data.resultado) {
                    ValorTab("4");
                } else {
                    Swal.fire({
                        title: 'Atención',
                        html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    })
                    return false;
                }
        });
    }

    const [titulosSelect, setTitulosSelect] = useState({ label: '', value: 0 });
    const [inputValue, setInputValue] = useState('');
    const [marcadores, setMarcadores] = useState([{
        lat: location.lat,
        lng: location.lng,
        time: new Date()
    }]);

    const ingresaPartido = (event, value) => {

        if (value !== null) {
            let delegacionSelect = '';
            let provinciaSelect = '';
            Partidos.map((partido, index) => {
                if (value.value === partido.descripcion) {
                    delegacionSelect = partido.razonSocial;
                    provinciaSelect = partido.provincia;
                }


            })

            if (TpoDomicilio === 1) {


                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: value.value,
                    delegacion: delegacionSelect,
                    provincia: provinciaSelect
                })
            } else {
                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: value.value,
                    provincia: provinciaSelect
                })
            }

        } else {
            setDatosDocimilio({
                ...datosDocimilio,
                partido: '',
                provincia: ''
            })
        }
    }

    const ingresaTipoCalle = (event, value) => {

        if (value !== null) {
            setDatosDocimilio({
                ...datosDocimilio,
                tpoCalle: Number(value.value)
            })

        } else {
            setDatosDocimilio({
                ...datosDocimilio,
                tpoCalle: 0
            })
        }
    }
    const ingresaLocalidad = (event, value) => {


        if (value !== null) {

            let delegacionSelect = '';
            let partidoSelect = '';
            let provinciaSelect = '';
            Partidos.map((partido, index) => {
                if (value.codPartido === partido.codPartido) {
                    delegacionSelect = partido.razonSocial;
                    partidoSelect = partido.descripcion;
                    provinciaSelect = partido.provincia;
                }

            })

            if (TpoDomicilio === 1) {
                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: partidoSelect,
                    delegacion: delegacionSelect,
                    codLocalidad: String(value.value),
                    localidad: value.label,
                    provincia: provinciaSelect
                })
            } else {
                setDatosDocimilio({
                    ...datosDocimilio,
                    partido: partidoSelect,
                    codLocalidad: String(value.value),
                    localidad: value.label,
                    provincia: provinciaSelect
                })
            }

        } else {
            setDatosDocimilio({
                ...datosDocimilio,
                codLocalidad: '',
                localidad: '',
                partido: '',
                provincia: ''
            })
        }
    }
    const [mensajeSinOpciones, setMensajeSinOpciones] = useState('Debe ingresar al menos 5 letras y aguarde los resultados')


    return (
        <Fragment>
            <Grid container>
                {tipoCalleSelect.length >= 1 ?
                    <DomiciliosUsuarioRR datos={datos} bloquearBtn={bloquearBtn}
                        setTituloModal={setTituloModal} setOpen={setOpen}
                        setErrorTelefono={setErrorTelefono}
                        setTipoDomicilio={setTipoDomicilio} setDatosDocimilio={setDatosDocimilio}
                        bloquearBtnEdit={bloquearBtnEdit} tipoCalleSelect={tipoCalleSelect} />
                    : <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </div>
                    </Grid>
                }

            </Grid>
            {tipoCalleSelect.length >= 1 &&
                <Modal
                    className={clases.modales}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Card className={clases.modal}sx={{ boxShadow: 5, borderColor: 'black', borderRadius: '5px', border: '1px solid black'}}>
                        <CardContent>
                            <Grid container>
                                <Grid item md={10} xs={10}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Domicilio: {tituloModal} {(localStorage.userType === "3" || localStorage.userType === "6") && " y teléfono"}
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={2} container justifyContent="flex-end">
                                    <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                                </Grid>
                                <Grid item md={4}> <Typography sx={{ mt: "9px" }} ><b>Seleccione como desea cargar el domicilio:</b> </Typography></Grid>
                                <Grid item md={3} xs={3}>
                                    <FormControl>
                                        <RadioGroup row name="position" value={datosDocimilio.tpoCarga} >
                                            <FormControlLabel
                                                value={1}
                                                onChange={inputHandleChange}
                                                name="tpoCarga"
                                                control={<Radio />}
                                                label="Manualmente"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                value={0}
                                                onChange={inputHandleChange}
                                                name="tpoCarga"
                                                control={<Radio />}
                                                label="Google"
                                                labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <form onSubmit={handleSubmit(guardarDatosTemporales)}>
                                <Grid container spacing={2}>
                                    {datosDocimilio.tpoCarga === 0 ?
                                        <Grid item md={12} xs={12} sx={{ mt: 2 }} >
                                            <Grid container spacing={2}>
                                                <Grid item md={7} xs={12}>
                                                    <Buscador setLocation={setLocation}
                                                        location={location}
                                                        datosCargados={datos}
                                                        Partidos={Partidos}
                                                        TpoDomicilio={TpoDomicilio}
                                                        libraries={libraries}
                                                        setListaLocalidades={setListaLocalidades}
                                                        titulosSelect={titulosSelect}
                                                        setTitulosSelect={setTitulosSelect}
                                                        setMarcadores={setMarcadores}
                                                        setDatosDocimilio={setDatosDocimilio}
                                                        datosDocimilio={datosDocimilio}
                                                    />
                                                </Grid>
                                                <Grid item md={2} xs={6}>
                                                    <Autocomplete
                                                        name='tpoCalle'
                                                        onChange={ingresaTipoCalle}
                                                        options={tipoCalleSelect}
                                                        noOptionsText={'No se encontraron Tipos de Calle'}
                                                        value={{ label: datosDocimilio.tpoCalle !== 0 && datosDocimilio.tpoCalle !== undefined && tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label !== undefined ? tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label : '', value: datosDocimilio.tpoCalle }}
                                                        getOptionLabel={(option) => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Tipos de Calle" />}
                                                        sx={{backgroundColor: "white"}}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={6}>

                                                    <Autocomplete
                                                        name='codLocalidad'
                                                        onChange={ingresaLocalidad}
                                                        options={listaLocalidades}
                                                        noOptionsText={"No se encontraron Localidades"}
                                                        value={{ label: datosDocimilio.localidad, value: datosDocimilio.codLocalidad !== undefined ? datosDocimilio.codLocalidad : 0 }}
                                                        getOptionLabel={option => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Localidad" />}
                                                        sx={{backgroundColor: "white"}}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {anchoPantalla < 900 &&
                                                <Grid item xs={12} sx={{ mt: 2 }}>
                                                    <Mapa setDatosDocimilio={setDatosDocimilio}
                                                        titulosSelect={titulosSelect}
                                                        datosCargados={datos}
                                                        Partidos={Partidos}
                                                        TpoDomicilio={TpoDomicilio}
                                                        marcadores={marcadores}
                                                        setListaLocalidades={setListaLocalidades}
                                                        libraries={libraries}
                                                        setMarcadores={setMarcadores}
                                                        setTitulosSelect={setTitulosSelect}
                                                        datosDocimilio={datosDocimilio}
                                                        location={location}
                                                        setLocation={setLocation}
                                                    />
                                                    <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >Si la ubicación encontrada no es la correcta, por favor seleccioanela en el mapa.</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        :
                                        <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item md={3} xs={12}>
                                                    <Autocomplete
                                                        name='tpoCalle'
                                                        onChange={ingresaTipoCalle}
                                                        options={tipoCalleSelect}
                                                        noOptionsText={'No se encontraron Tipos de Calle'}
                                                        value={{ label: datosDocimilio.tpoCalle !== 0 && datosDocimilio.tpoCalle !== undefined && tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label !== undefined ? tipoCalleSelect.filter(e => e.value === datosDocimilio.tpoCalle)[0].label : '', value: datosDocimilio.tpoCalle }}
                                                        getOptionLabel={(option) => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Tipos de Calle" />}
                                                        sx={{backgroundColor: "white"}}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField label="Calle"
                                                        name='calle'
                                                        onChange={inputHandleChange}
                                                        value={datosDocimilio.calle}

                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                        sx={{backgroundColor: "white"}}
                                                    />
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <TextField label="Número"
                                                        name='numero'
                                                        onChange={inputHandleChange}
                                                        value={datosDocimilio.numero}

                                                        variant="outlined"
                                                        fullWidth
                                                        type="number"
                                                        sx={{backgroundColor: "white"}}
                                                    />
                                                </Grid>
                                                {/* <Grid item md={3} xs={12}>
                                                    <TextField label="Localidad"
                                                        name='localidad'
                                                        onChange={inputHandleChange}
                                                        value={datosDocimilio.localidad}
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                    />
                                                </Grid> */}
                                                <Grid item md={3} xs={6}>

                                                    <Autocomplete
                                                        name='codLocalidad'
                                                        onChange={ingresaLocalidad}
                                                        options={listaLocalidades}
                                                        inputValue={inputValue}
                                                        onInputChange={(event, newInputValue) => {
                                                            setInputValue(newInputValue)
                                                            if (newInputValue.trim().length >= 3)
                                                                buscarLocalidades(newInputValue)
                                                            else {
                                                                setMensajeSinOpciones("Debe ingresar al menos 3 letras y aguarde los resultados")
                                                                setListaLocalidades([]);
                                                            }
                                                        }}
                                                        noOptionsText={mensajeSinOpciones}
                                                        value={datosDocimilio.localidad !== '' && datosDocimilio.localidad !== undefined ? datosDocimilio.localidad : ''}
                                                        // getOptionLabel={option => option.label}
                                                        // isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} required label="Localidad" />}
                                                        sx={{backgroundColor: "white"}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    <Grid item md={datosDocimilio.tpoCarga === 1 ? 12 : 5} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={6}>
                                                <TextField label="Piso"
                                                    name='piso'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.piso}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={6}>
                                                <TextField label="Departamento"
                                                    name='depto'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.depto}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Autocomplete
                                                    name='partido'
                                                    onChange={ingresaPartido}
                                                    options={partidosSelect}
                                                    noOptionsText={'No se encontraron Partidos'}
                                                    value={{ label: datosDocimilio.partido, value: datosDocimilio.partido }}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                                    renderInput={(params) => <TextField {...params} required label="Partido" />}
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={6}>
                                                <TextField label="Codigo Postal"
                                                    name='codPostal'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.codPostal}
                                                    required
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={6}>
                                                <TextField label="Delegación"
                                                    name='delegacion'
                                                    disabled
                                                    required
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.delegacion}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField label="Provincia"
                                                    name='provincia'
                                                    disabled
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.provincia}
                                                    required
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 1 : 2} xs={12} className="centrar_contenido"  >
                                                <Grid className="centrar_contenido">
                                                    <Typography  ><b>Teléfono  Celular</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 3 : 4} xs={5}>
                                                <TextField label="Caracteristica"
                                                    name='caracteristica'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.caracteristica}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                0
                                                            </InputAdornment>
                                                        ),
                                                        pattern: "[0-9]+",
                                                        maxLength: 5
                                                    }}
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 8 : 6} xs={7}>
                                                <TextField label="Celular"
                                                    name='celular'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.celular}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                15
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 1 : 2} xs={12} className="centrar_contenido"  >
                                                <Grid className="centrar_contenido">
                                                    <Typography  ><b>Teléfono  Fijo</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 3 : 4} xs={5}>
                                                <TextField label="Caracteristica"
                                                    name='caracteristicaFijo'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.caracteristicaFijo}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                0
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                />
                                            </Grid>
                                            <Grid item md={datosDocimilio.tpoCarga === 1 ? 8 : 6} xs={7}>
                                                <TextField label="Fijo"
                                                    name='telefono'
                                                    onChange={inputHandleChange}
                                                    value={datosDocimilio.telefono}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    sx={{backgroundColor: "white"}}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item md={12} style={errorTelefono} sx={{ m: 0 }}>
                                                <Typography className={clases.color_rojo}  >{errorTelefonoTexto}</Typography>
                                            </Grid>
                                            <Grid item sx={{ mt: errorTelefono.display === "none" ? 3 : 0 }} md={12}>
                                                <Grid container justifyContent="center">
                                                    <Button startIcon={<CheckIcon />} type="submit" variant="contained" color="secondary" >

                                                        Confirmar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {datosDocimilio.tpoCarga === 0 && anchoPantalla > 900 &&
                                        <Grid item md={7} xs={12}>
                                            <Mapa setDatosDocimilio={setDatosDocimilio}
                                                titulosSelect={titulosSelect}
                                                marcadores={marcadores}
                                                setListaLocalidades={setListaLocalidades}
                                                setMarcadores={setMarcadores}
                                                Partidos={Partidos}
                                                libraries={libraries}
                                                setTitulosSelect={setTitulosSelect}
                                                datosDocimilio={datosDocimilio}
                                                datosCargados={datos}
                                                TpoDomicilio={TpoDomicilio}
                                                location={location}
                                                setLocation={setLocation}
                                            />
                                            <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >Si la ubicación encontrada no es la correcta, por favor seleccioanela en el mapa.</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Modal>
            }
        </Fragment>
    );
}
