import { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, Grid, Box, Typography, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { generarOperacion, grabarOperacionAltaTitulo } from 'app/features/Profesionales/profesionalesAPI';
import { DetalleMontoTasa } from 'components/reinscripcionRehabilitacion/DetalleMontoTasa';

export const SeleccionDeMedioDePago = ({setMedioDePago, setLoaderBtnGuardar, loaderBtnGuardar, bloquearBtn, setValue, resultadoPagoTasa, tasaRR = null}) => {
  const [valor, setValor] = useState(null);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const tipoTramite = localStorage.tpoTramite;

  // console.log(tipoTramite, typeof tipoTramite)

  const handleChange = (event) => {
    setValor(event.target.value);
    // setMedioDePago(event.target.value);
  };

  
  const llamarGenOp = async() => {

    if(localStorage.idLegajo === ""){
      
      const {data} = await generarOperacion();
      // console.log(data)
    }else{

      const data = await grabarOperacionAltaTitulo();
    }
  }

  useEffect(() => {
    if(localStorage.tpoTramite !== "2" && localStorage.tpoTramite !== "7") {
      llamarGenOp()
    }
  }, [])

  return (
    <>
    { (localStorage.userType !== "1" && !resultadoPagoTasa) ?
    <Grid>
      { tasaRR === null ?
        <Grid container sx={{p: 2}}>
          <Grid item xs={12} display='flex' justifyContent='center' ml={isXsScreen ? 1 : -8} >
            <Typography variant="h4" gutterBottom>
                Seleccione el medio de pago
            </Typography>
          </Grid>
          <Grid item display='flex' justifyContent='center' mt={4} mb={4} alignItems='center' width={'100vw'}  >
            <RadioGroup value={valor} onChange={handleChange} >
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ width: { xs: '60vw', sm: '30vw', md: '30vw', lg: '25vw', xl: '20vw' } }}>
              <FormControlLabel value="2" control={<Radio />} label={<Typography variant="h7"><b>Código QR</b></Typography>}/>
              <img src="/PagosQRFinal.png" alt="PagoQR" style={{height: '4em'}} />
            </Box>
              <Box display="flex" flexDirection="row" alignItems="center" gap={2} mt={4} sx={{ width: { xs: '70vw', sm: '30vw' } }}>
                <FormControlLabel value="1" control={<Radio />} label={<Typography variant="h7"><b>Tarjeta de crédito o débito</b></Typography>} />
                <img src="/Mercado-Pago.png" alt="MercadoPago"  style={isXsScreen ? { width: '40vw'} : { width: 'auto' } } />
              </Box>
            </RadioGroup>
          </Grid>

          <Grid item xs={12} display='flex' mt={4}>
            <Grid item xs={6} >
              <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("5")} variant="contained" color="error" >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} display='flex' justifyContent='flex-end'>
              <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn || !valor} variant="contained" onClick={() => setMedioDePago(valor)} color="secondary" >
                  {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        :
        <Grid container sx={{p: 2}}>
          <Grid item xs={6}>
              <Typography variant="h4" gutterBottom> Seleccione el medio de pago</Typography>
            <Grid item xs={12} >
              <RadioGroup value={valor} onChange={handleChange} >
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ width: { xs: '60vw', sm: '30vw', md: '30vw', lg: '25vw', xl: '20vw' } }}>
                <FormControlLabel value="2" control={<Radio />} label={<Typography variant="h7"><b>Código QR</b></Typography>}/>
                <img src="/PagosQRFinal.png" alt="PagoQR" style={{height: '4em'}} />
              </Box>
                <Box display="flex" flexDirection="row" alignItems="center" gap={2} mt={4} sx={{ width: { xs: '70vw', sm: '30vw' } }}>
                  <FormControlLabel value="1" control={<Radio />} label={<Typography variant="h7"><b>Tarjeta de crédito o débito</b></Typography>} />
                  <img src="/Mercado-Pago.png" alt="MercadoPago"  style={isXsScreen ? { width: '40vw'} : { width: 'auto' } } />
                </Box>
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <DetalleMontoTasa tasaRR={tasaRR}/>
          </Grid>
          <Grid item xs={12} display='flex' mt={4}>
            <Grid item xs={6} >
              <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("4")} variant="contained" color="error" >
                Atras
              </Button>
            </Grid>
            <Grid item xs={6} display='flex' justifyContent='flex-end'>
              <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn || !valor} variant="contained" onClick={() => setMedioDePago(valor)} color="secondary" >
                  {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
    :
      <Grid container>
        <Grid item xs={12}>
          <Typography>
              El pago aún no fue realizado.
          </Typography>
        </Grid>
      </Grid>
    }
  </>
  )
}
