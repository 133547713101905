import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { postLoginExterno, postLoginRR } from 'app/features/Usuarios/usuariosAPI';
import { TIPOS_TRAMITE, TIPOS_USUARIO }  from 'constants/constants';
import { createSession } from 'utils/sessionManager';
import useQuery from 'customHooks/useQuery';
import { crearNuevoTramite, obtenerListaTramites, obtenerTokenBajaFallecimiento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const LoginRR = () => {
    localStorage.setItem('token', "");
    localStorage.removeItem('usuario');
    const classes = useStyles();

    let query = useQuery();
    const [mensajePantalla, setMensajePantalla] = useState("cargando...");

    useEffect(() => {

        const usrData = { usuario: encodeURIComponent(query.get("usuario")), password: encodeURIComponent(query.get("pass")), idLegajoTemporal: 0, email: "", tpoTramite : encodeURIComponent(query.get("tpoTramite")), legProf: encodeURIComponent(query.get("legProf"))};
        postLoginRR(usrData)
            .then((value) => {
                localStorage.clear();
                const parsedData = JSON.parse(value.data);
                if (parsedData.resultado) {
                    const session = {
                        ...parsedData,
                        userType: TIPOS_USUARIO.PROFESIONAL,
                        tpoTramite: query.get("tpoTramite"),
                        legajoProfesional: query.get("legProf"),
                        validacionTitulo: true,
                        user: query.get("usuario"),
                        pass: query.get("pass")
                    }
                    createSession(session);
                    window.location.href = `/reinscripcionRehabilitacion`;
                } else {
                    setMensajePantalla("Usuario y/o contraseña incorrectos");
                }
            });
    }, []);

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle1">
                {mensajePantalla}
            </Typography>
        </Paper>
    )
}

export default LoginRR;
