import { useEffect, useState } from 'react';
import { Button, Tab, Grid, Box, Paper, Typography, CircularProgress } from '@mui/material';
import { SeleccionDeMedioDePago } from '../matriculas/seleccionDeMedioDePago';
import { PagoBrick } from '../matriculas/pagoBrick';
import { PagoQR } from '../matriculas/pagoQR';
import { PagoPendiente } from '../matriculas/pagoPendiente';
import { obtenerCalculoTasaRR } from 'app/features/Profesionales/profesionalesAPI';
import { PagoCompletado } from './PagoCompletado';
import Swal from 'sweetalert2';

export const DerechoDeInscripcion = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, resultadoPagoTasa, estadoOperacion, obtenerResultadoDePago, setEstadoOperacion, setResult, montoDeTasa, setMedioDePago, medioDePago, limpiarNotificaciones}) => {

    const [tasaRR, setTasaRR] = useState();
    const [loading, setLoading] = useState(false);
    const [banderaOperacion, setBanderaOperacion] = useState(true);
    const tipoUsuario = Number(localStorage.userType);

    const calculoDeTasa = async() => {
        setLoading(true)
        const resp = await obtenerCalculoTasaRR(localStorage.idLegajo);
        const monto = parseFloat(resp.montoTramite.toFixed(2));
        setTasaRR({...resp,
            montoTramite: monto
        });
        setLoading(false);
    }

    useEffect(() => {
        if(!resultadoPagoTasa){
            calculoDeTasa();
        }
    }, [banderaOperacion])

    useEffect(() => {
        if ( tipoUsuario === 0 && idEstado === 1) {   
            Swal.fire({
                title: 'Atención',
                html: `Sr./a Profesional, la solicitud será enviada luego de efectuar el pago.`,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: true
            });
        }
    }, []);

    if(loading) return (
        <Grid container>
            <Grid item md={12} display='flex' justifyContent='center' alignItems='center' height='30vh' >
                <CircularProgress />
            </Grid>
        </Grid>
    )

    return (
    <Grid container>
        <Grid item md={12}>
            {
                estadoOperacion === 1 ? 
                <PagoPendiente obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion}/>
                :
                (!resultadoPagoTasa ? 
                    (() => {
                        switch(medioDePago) {
                            case "0":
                                return <SeleccionDeMedioDePago setMedioDePago={setMedioDePago} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} bloquearBtn={bloquearBtn} setValue={setValue} tasaRR={tasaRR}/>
                            case "1":
                                return <PagoBrick setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago} tasaRR={tasaRR} setBanderaOperacion={setBanderaOperacion} banderaOperacion={banderaOperacion} limpiarNotificaciones={limpiarNotificaciones}/>
                            case "2":
                                return <PagoQR setMedioDePago={setMedioDePago} setResult={setResult} tasaRR={tasaRR} setBanderaOperacion={setBanderaOperacion} banderaOperacion={banderaOperacion} limpiarNotificaciones={limpiarNotificaciones}/>
                        }
                    })()
                    : 
                    <PagoCompletado datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} />
                )
            }
        </Grid>
        {/* <Grid item md={6}>
            <p>Acá va la tabla de conceptos</p>
        </Grid> */}
    </Grid>
  )
}
