import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, FormControlLabel, Typography, Grid, Fab, FormControl, RadioGroup, Radio } from '@mui/material';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { cambiarAlturaBox } from '../funciones/funcionesGlobales';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import {Pdf} from './Pdf';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { EMPTY_FILE, PDF_FILE, DEL_FILE } from 'constants/icons';
import { getLeyendasAsync } from 'app/features/Leyendas/leyendasSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TIPOS_USUARIO } from 'constants/constants';
import {descargarFile} from 'utils/archivosManager';
import { ModalTerminosyCondicionesRR } from './ModalTerminosyCondicionesRR';

//BUSQUEDA PARA EL PDF

import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';


// import { borderRadius } from '@mui/system';
import FilePreview from 'components/common/FilePreview';
import { insertarFallecimiento, obtenerGerencia, obtenerProfesionalPorDocumento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { getParentescosAsync, parentescosList } from 'app/features/Parentescos/parentescosSlice';
import { nuevoAdjunto } from 'utils/adjuntosLogic';
import { setDdjj } from 'app/features/DDJJ/ddjjRRSlice';

const useStyles = makeStyles(theme => ({
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pIcon: {
        textAlign: 'center'
    },
    pFileName: {
        textAlign: 'center',
        marginTop: '0',
        fontWeight: 'bold',
        size: '12px'
    },
    imgIcon: {
        width: '40px',
        height: '40px',
        cursor: 'pointer'
    },
    imgIconCargado: {
        width: '40px',
        height: '40px',
        cursor: 'pointer'
    },
    imgIconTexto: {
        width: '40px',
        height: '22px'
    },
    delIcon: {
        width: '25px',
        height: '28px',
        position: 'relative',
        cursor: 'pointer',
        marginTop: "10px"
    },
    delIconOpcionales: {
        background: 'red',
        width: '25px',
        borderRadius: '50%',
        position: 'relative',
        right: '3%',
        cursor: 'pointer'
    },
    modalWindow: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgb(255, 255, 255)',
        border: '2px solid #000',
        padding: '15px',
    }
}));

const elementosNoDeseados = [
    'Foto', 
    'Pago Inscripción', 
    'Certificado de libre sanción disciplinaria de otros consejos profesionales', 
    'Declaración jurada de enfermedades y patologías preexistentes', 
    'DNI',
    'Analítico',
    'FotoPerfilFull',
    localStorage.userType !== "1" ? 'FotoPerfil' : ''
];

const elementosDeseados = [
    'DNI del Solicitante - Anverso', 
    'DNI del Solicitante - Reverso'
    // 'Acta de Defunción emitida por el registro pertinente'
];


export const ArchivosAdjuntos = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, titulosProfesional, setOcultarTabs, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, setIdEstado, ocultarContenido, setOcultarContenido, ValorTab, setMatriculas, tpoUser, tpoTramite}) => {
    const [fileUpdType, setFileUpdType] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});

    const tipoUsuario = Number(localStorage.userType);
    const dispatch = useDispatch();
    //pasar a la vista del pdf
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const EstadoCivilList = useSelector(estadoCivilList);
    const NacionalidadesList = useSelector(nacionalidadesList);
    const ObrasSociales = useSelector(obrasSocialesList);
    const Parentescos = useSelector(parentescosList);
    const titulosRR = useSelector(state => state.titulosRRReducer.titulosRR);
    const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);

    const [botonConfirmarTerminos, setBotonConfirmarTerminos] = useState(true);
    const [botonCancelarTerminos, setBotonCancelarTerminos] = useState(false);
    const [estaFirmada, setEstaFirmada] = useState(null);

    const [datosAdjuntosGuardar, setDatosAdjuntosGuardar] = useState({});
    const [leyendas, setLeyendas] = useState([]);
    const [anchoPantalla, setAnchoPantalla] = useState(0);
    const [mostrarAnalitico, setMostrarAnalitico] = useState(0);
    const [nombreGerencia, setNombreGerencia] = useState('')
    const [sexoFallecido, setSexoFallecido] = useState('')

    function handleResize() {


        if ((window.innerWidth >= 900 && anchoPantalla < 900) || (window.innerWidth <= 900 && anchoPantalla > 900) || anchoPantalla === 0) {
            setAnchoPantalla(window.innerWidth);
        }

    }

    const obtenerNombreGerencia = async() => {
        const resp = await obtenerGerencia();
        setNombreGerencia(resp)
    }
    
    window.addEventListener("resize", handleResize);
    useEffect(() => {
            if (ObrasSociales.length === 0) {
                //llamo a la funcion del Slice
                dispatch(getObrasSocialesAsync());
            }
    
            if (localStorage.userType !== "10" && localStorage.validacionTitulo !== "1") {
                setMostrarAnalitico(1);
            }
    
            setAnchoPantalla(window.screen.width);
            window.addEventListener("resize", handleResize);
    
            //llamo a la funcion del Slice
            dispatch(getLeyendasAsync("Inscripcion_Profesionales")).then((value) => {
                setLeyendas(value.payload.results)
            });
    
    
            if (EstadoCivilList.length === 0) {
                //llamo a la funcion del Slice
                dispatch(getEstadoCivilAsync());
            }
    
            if (TipoDocumentos.length === 0) {
                //llamo a la funcion del Slice
                dispatch(getTipoDocumentoAsync());
            }
    
            if (NacionalidadesList.length === 0) {
                //llamo a la funcion del Slice
                dispatch(getNacionalidadesAsync());
            }
    
            if(Parentescos.length === 0) {
                dispatch(getParentescosAsync());
            }
    
        }, [])
    

    let blobDDJJ = {};
    let urlDDJJ = "";

    const [open, setOpen] = useState(false);
    const [openConfirmarTerminos, setOpenConfirmacionTerminos] = useState(false);
    const [montarPdf, setMontarPdf] = useState(false)




    //metodo para subir archivos
    const changeHandler = (evt) => {
        
        if (evt.target.files.length >= 1) {
            const tipo = evt.target.files[0].type;
            const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

            if (tipo !== 'application/pdf' && tipo !== 'image/jpeg' && tipo !== 'image/png') {
                Swal.fire({
                    title: 'Atención',
                    html: 'El tipo de archivo no es correcto, se admite unicamente: pdf, jpg, jpeg o png.',
                    icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                })
                return false;
            }
            else if ((Number(size) > 5)) {
                var textoError = 'Sr/a el tamaño de la imagen no debe superar(';
                Swal.fire({
                    title: 'Atención',
                    html: 'El tamaño del archivo  no debe superar 5MB.',
                    icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                })
                return false;
            }
            else {
                const upload = datos.adjuntos;
                upload.filter(c => c.fileType === fileUpdType)[0].fileName = evt.target.files[0].name;
                upload.filter(c => c.fileType === fileUpdType)[0].extension = tipo;
                upload.filter(c => c.fileType === fileUpdType)[0].file = evt.target.value;
                upload.filter(c => c.fileType === fileUpdType)[0].size = parseFloat(size);
                upload.filter(c => c.fileType === fileUpdType)[0].saved = false;

                const reader = new FileReader();

                reader.onload = () => {

                    if (tipo !== 'application/pdf')
                        upload.filter(c => c.fileType === fileUpdType)[0].url = reader.result;
                    else
                        upload.filter(c => c.fileType === fileUpdType)[0].url = PDF_FILE;

                    upload.filter(c => c.fileType === fileUpdType)[0].file = reader.result;
                    setDatos({
                        ...datos,
                        adjuntos: upload
                    });
                }
                reader.readAsDataURL(evt.target.files[0]);
               
            }
        }
    }



    const { handleSubmit, formState: { errors } } = useForm();


    const [perteneceOtroConsejo, setPerteneceOtroConsejo] = useState(0);

    const onSubmit = data => {

        let erroresMostrar = "";
        let erroresRR = "";
        let errorDatosPersonales = "";

        let erroresDomiciliosMostrar = "";
        let mostrarErrorDomicilio = 0;
        let errorDomicilio = 0;

        if(datos.ObraSocial === '' || datos.ObraSocial === 'Sin Datos'){
            Swal.fire({ title: 'Atención', html: 'En solapa Otros datos: Debe seleccionar una obra social', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
            return false;
        }

        datos.domicilios.map((domicilio, index) => {

            const errores = [];

            if (domicilio.calle === "") errores.push("calle");
            if (domicilio.celular === "") errores.push("celular");
            if (domicilio.caracteristica === null) errores.push("característica");
            if (domicilio.caracteristica && domicilio.caracteristica.length > 5) errores.push("característica (longitud mayor a 5)");
            if (domicilio.caracteristicaFijo && domicilio.caracteristicaFijo.length > 5) errores.push("característica fija (longitud mayor a 5)");
            if (isNaN(domicilio.caracteristica)) errores.push("característica (no es un número)");
            if (isNaN(domicilio.celular)) errores.push("celular (no es un número)");
            if (domicilio.codLocalidad === "-1")  errorDomicilio = 1;
            
        
            if (errores.length > 0 && mostrarErrorDomicilio === 0) {
                if (erroresDomiciliosMostrar !== "") erroresDomiciliosMostrar += ", ";
                erroresDomiciliosMostrar += `<b>Domicilios</b>: ${errores.join(", ")}`;
                mostrarErrorDomicilio = 1;
            }

        })

        if (errorDomicilio === 1) {
            setLoaderBtnGuardar(false);
            setBloqueBtnConfirmarInscripcion(false);
            Swal.fire({
                title: 'Atención',
                html: 'Existen domicilios que tienen ingresada la localidad <b> Otros</b>',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }

        if (datos.Apellido === "" || datos.CuitCuil === ""
            || datos.Email === "" || datos.Nombre === ""
            || datos.NroDocumento === "" || datos.TipoDocumento === "") {

            if (datos.Apellido === "") {
                errorDatosPersonales = "<b> Apellido</b>";
            }

            if (datos.CuitCuil === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b> Cuit - Cuil</b>";
                else
                    errorDatosPersonales += ", <b> Cuit - Cuil</b>";
            }

            if (datos.Email === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Email</b>";
                else
                    errorDatosPersonales += ", <b>Email</b>";
            }

            if (datos.Nombre === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Nombre</b>";
                else
                    errorDatosPersonales += ", <b>Nombre</b>";
            }

            if (datos.TipoDocumento === "") {
                if (errorDatosPersonales === "")
                    errorDatosPersonales = "<b>Tipo de Documento</b>";
                else
                    errorDatosPersonales += ", <b>Tipo Documento</b>";
            }
        }

        if(!editarFotoSlice.fotoActual && !editarFotoSlice.fotoNueva){
            Swal.fire({ title: 'Atención', html: 'Debe cargar una foto actual', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
            return false;
        }

        if (errorDatosPersonales !== "")
            erroresRR = `${localStorage.tpoTramite === "7" ? 'Solapa Reinscripción' : 'Solapa Rehabilitación'}`+ "(" + errorDatosPersonales + ")";

        if (calcularEdad(datos.Nacimiento) > 100) {
            Swal.fire({
                title: 'Atención',
                html: 'La <b>Fecha de Nacimiento</b> ingresada es incorrecta',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }

        let mostrarErrorAdjuntos = 0;

        let errorDdjjEnfermedades = 0;
        let cantidadAdjuntosFaltantes = 0;
        let nombreAdjuntosFaltantes = "";
        //* Validaciones sobre los adjuntos
        datos.adjuntos.map((adjunto, index) => {

            if ((adjunto.file === null || adjunto.file === '') && mostrarErrorAdjuntos === 0
                // && adjunto.fileType.replace(/\d/g, "").trim() !== "DNI del Solicitante - Anverso"
                // && adjunto.fileType.replace(/\d/g, "").trim() !== "DNI del Solicitante - Reverso"
                // && adjunto.fileType.replace(/\d/g, "").trim() !== "Acta de Defunción emitida por el registro pertinente"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "Otros Documentos"
                && adjunto.fileType.replace(/\d/g, "").trim() !== "FotoPerfilFull"
            ) {
                cantidadAdjuntosFaltantes++;

                if (nombreAdjuntosFaltantes === "") {
                    nombreAdjuntosFaltantes = "<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
                else {
                    nombreAdjuntosFaltantes += ",<b>" + adjunto.fileType.replace(/\d/g, "").trim() + "</b>";
                }
            }
        })

        if (cantidadAdjuntosFaltantes <= 4) {
            if (erroresMostrar !== "") {
                erroresMostrar += ", ";
            }
            erroresMostrar += nombreAdjuntosFaltantes;
        } 

        if (erroresDomiciliosMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe completar correctamente los siguientes datos:' + erroresDomiciliosMostrar,
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            });
            return false;
        }
        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe adjuntar el/los siguiente/s documento/s: </br>' + erroresMostrar,
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            });
            return false;
        }
        if (erroresRR !== "") {
            Swal.fire({
                title: 'Atención',
                html: 'Debe Revisar: </br>' + erroresRR,
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            });
            return false;
        }
        // if (datos.NroDocumento.length !== 10 && tipoUsuario !== 12 && tipoUsuario !== 1) {
        //     Swal.fire({
        //         title: 'Atención',
        //         html: 'El Numero de Documento ingresado no es valido',
        //         icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
        //     })
        //     return false;
        // } 

        let documento = datos.NroDocumento;

        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");

        let cuit = datos.CuitCuil;

        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        // if (datos.CuitCuil.length !== 13 || cuit !== documento) {
        //     Swal.fire({
        //         title: 'Atención', html: 'El CUIT-CUIL ingresado no es valido', icon: 'warning', showCloseButton: true, 
        //         showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
        //     })
        //     return false;
        // }

        if (editarFotoSlice.deseaCambiarFoto) {
            let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
            if (adjuntoDeFoto) {
                adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
            } else {
                adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
                datos.adjuntos.push(adjuntoDeFoto);
            }
            let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
            if (adjuntoDeFotoFull) {
                adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
            } else {
                adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
                datos.adjuntos.push(adjuntoDeFotoFull);
            }

        }

        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            if (!value.data.response) {
                Swal.fire({
                    title: 'Atención', html: 'El CUIT-CUIL ingresado es invalido', icon: 'warning', showCloseButton: true, 
                    showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                });
                return false;
            } else {
                setLoaderBtnGuardar(true)
                setBloquearBtn(true);
                // if( tipoUsuario === TIPOS_USUARIO.USUARIO_INTERNO ) {
                //     // await guardarInscripcion();
                //     guardarInscripcion();
                // } 
                
                if( tipoUsuario !== TIPOS_USUARIO.SECRETARIO_TECNICO ) {
                    guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: Number(localStorage.tpoTramite)}).then((value) => {
                        setMatriculas(value.data);
                        setLoaderBtnGuardar(false)
                        setBloquearBtn(false);
                        if (value.data.resultado) {
                            //TODO: Enviar a la proxima solapa
                            // setValue("5")

                            if(idEstado === 1 ) {
                                setOpenConfirmacionTerminos(true)
                                setMontarPdf(true) 

                            } else {
                                setMontarPdf(true)
                                setValue("5")
                            }
                            
                            
                            // setOcultarTabs({ display: 'none' });
                            // setOcultarContenido(true);
                            // cambiarAlturaBox('0px');
                        } else {
                            Swal.fire({
                                title: 'Atención',
                                html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                                icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                            })
                            return false;
                        }
                    });
                }
                if(  tipoUsuario === TIPOS_USUARIO.SECRETARIO_TECNICO) {
                    guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: Number(localStorage.tpoTramite) }).then((value) => {
                        setMatriculas(value.data);
                        setLoaderBtnGuardar(false)
                        setBloquearBtn(true);
                        if (value.data.resultado) {
                            Swal.fire({
                                title: '',
                                html: 'Se grabó correctamente',
                                icon: 'success', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                            })
                            setValue("4")
                        } else {
                            Swal.fire({
                                title: 'Atención',
                                html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                                icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                            })
                            return false;
                        }
                    });
                }
                
            }
        });
    }

    const guardarDatosInscripcion = () => {
        dispatch(setDdjj(blobDDJJ))

        const size = ((blobDDJJ.size / 1000) / 1000).toFixed(4);

        const reader = new FileReader();
        const upload = datos.adjuntos;


        if (upload.filter(c => c.fileType === 'Declaración Jurada').length === 0) {
            reader.readAsDataURL(blobDDJJ)

            reader.onload = () => {
                datos.adjuntos.push({ fileType: 'Declaración Jurada', fileName: 'DDJJ', extension: 'application/pdf', file: reader.result, size: parseFloat(size), url: PDF_FILE, saved: false });
            }
        } else {
            reader.readAsDataURL(blobDDJJ)

            reader.onload = () => {
                upload.filter(c => c.fileType === 'Declaración Jurada')[0].fileName = 'DDJJ';
                upload.filter(c => c.fileType === 'Declaración Jurada')[0].extension = 'application/pdf';
                upload.filter(c => c.fileType === 'Declaración Jurada')[0].size = parseFloat(size);
                upload.filter(c => c.fileType === 'Declaración Jurada')[0].saved = false;
                upload.filter(c => c.fileType === 'Declaración Jurada')[0].file = reader.result;
            }
        }
        setValue('5')

        // setOpenConfirmacionTerminos(true);
        // setBotonConfirmarTerminos(true);
        // setBotonCancelarTerminos(false);
    }

    const [bloqueBtnConfirmarInscripcion, setBloqueBtnConfirmarInscripcion] = useState(false);
    const enviarInscripcion = () => {

        setBloqueBtnConfirmarInscripcion(true);
        setLoaderBtnGuardar(true);
        let erroresMostrar = "";
        let mostrarErrorDomicilio = 0;
        let errorDomicilio = 0;
        datos.domicilios.map((domicilio, index) => {

            const errores = [];

            if (domicilio.calle === "") errores.push("calle");
            if (domicilio.celular === "") errores.push("celular");
            if (domicilio.caracteristica === null) errores.push("característica");
            if (domicilio.caracteristica && domicilio.caracteristica.length > 5) errores.push("característica (longitud mayor a 5)");
            if (domicilio.caracteristicaFijo && domicilio.caracteristicaFijo.length > 5) errores.push("característica fija (longitud mayor a 5)");
            if (isNaN(domicilio.caracteristica)) errores.push("característica (no es un número)");
            if (isNaN(domicilio.celular)) errores.push("celular (no es un número)");
            if (domicilio.codLocalidad === "-1")  errorDomicilio = 1;
            
        
            if (errores.length > 0 && mostrarErrorDomicilio === 0) {
                if (erroresMostrar !== "") erroresMostrar += ", ";
                erroresMostrar += `<b>Domicilios</b>: ${errores.join(", ")}`;
                mostrarErrorDomicilio = 1;
            }

        })

        if (errorDomicilio === 1) {
            setLoaderBtnGuardar(false);
            setBloqueBtnConfirmarInscripcion(false);
            Swal.fire({
                title: 'Atención',
                html: 'Existen domicilios que tienen ingresada la localidad <b> Otros</b>',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }

        insertarFallecimiento().then(value => {
            setLoaderBtnGuardar(false);

            if (value.data.result) {
                setIdEstado(7);
                Swal.fire({
                    title: '',
                    html: 'La solicitud se inició correctamente.',
                    icon: 'success',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                });
            } else {
                setBloqueBtnConfirmarInscripcion(false);

                Swal.fire({
                    title: 'Atención',
                    html: value.data.mensaje !== '' && value.data.mensaje !== null && value.data.mensaje.indexOf('illegal character among') < 0 ? value.data.mensaje : value.data.mensaje.indexOf('illegal character among') >= 0 ? 'Archivo no encontrado' : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                    icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                })
                return false;
            }
        });
    }

    const deleteFile = file => {
        
        const arrFiles = datos.adjuntos;
        arrFiles.filter(c => c.fileType === file)[0].fileName = '';
        arrFiles.filter(c => c.fileType === file)[0].extension = '';
        arrFiles.filter(c => c.fileType === file)[0].file = null;
        arrFiles.filter(c => c.fileType === file)[0].size = 0;
        arrFiles.filter(c => c.fileType === file)[0].url = EMPTY_FILE;
        arrFiles.filter(c => c.fileType === file)[0].saved = false;
        setDatos({
            ...datos,
            adjuntos: arrFiles
        });
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = ev => {
        if (bloquearBtn !== true || estaFirmada === false) {
            setFileUpdType(ev.target.name);
            hiddenFileInput.current.click();
        }
    };

    const popitup = (adjunto) => {
        setSelectedFile(adjunto);
        setOpenPreview(true);
    }
    const handleClose = () => setOpenPreview(false);

    const clases = useStyles();
    const handleOpen = () => {

        setOpen(true);
    }

    const eliminarArchivoAdjuntoExtra = (nombreArchivo) => {

        const arrFiles = datos.adjuntos;
        setDatos({
            ...datos,
            adjuntos: arrFiles.filter(c => c.fileType !== nombreArchivo)
        });
    };

    let sumarTitulo = 0;

    const agregarAdjunto = (data) => {
    
        datos.adjuntos.map((tit) => {
            if (tit.fileType.replace(/\d/g, "").trim() === data.replace(/\d/g, "").trim()) {
                sumarTitulo++;
            }
        });
    
        const nuevoAdjunto = { 
            fileType: data + ' ' + sumarTitulo, 
            fileName: '', 
            extension: '', 
            file: '', 
            size: 0, 
            url: EMPTY_FILE, 
            saved: false 
        };
    
        let adjuntosOrdenados = [];
        let insertado = false;
    
        datos.adjuntos.map((adj, index) => {
            adjuntosOrdenados.push(adj);
            if (!insertado && adj.fileType.indexOf(data) >= 0) {
                adjuntosOrdenados.push(nuevoAdjunto);
                insertado = true;
            }
        });
    
        if (!insertado) {
            adjuntosOrdenados.push(nuevoAdjunto);
        }
    
        setDatos({
            ...datos,
            adjuntos: adjuntosOrdenados
        });
    };

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
    const cancelarIncripcionPDF = () => {
        setOcultarContenido(false);
        cambiarAlturaBox('45px');
        setOcultarTabs({ display: '' });
    }

    const calcularEdad = (fecha) => {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edad = hoy.getFullYear() - cumpleanos.getFullYear();
        let m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    const continuarGuardadoAdjuntos = () => {
        guardarDatosInscripcion()
    }

    function downloadPDF(pdf, nombrePdf) {
        const linkSource = `${pdf}`;
        const downloadLink = document.createElement("a");
        let extension = "";
        if  (pdf.includes("data:application/pdf") && !nombrePdf.includes(".pdf")) 
            extension = ".pdf"

        downloadLink.href = linkSource;
        downloadLink.download = nombrePdf + extension;
        downloadLink.click();
    }
 
    useEffect(() => {
        if(tpoUser === TIPOS_USUARIO.USUARIO_INTERNO ){
            obtenerNombreGerencia()
        }
    }, [])

    return (
        <Grid>
            {ocultarContenido === false ?
                <form onSubmit={handleSubmit(onSubmit)}>

                    <input type="file" name="uploadFile" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} />
                    <Typography style={{ fontSize: 14 }} >
                        * <b>Haga clic en  <img src={EMPTY_FILE} className={clases.imgIconTexto} />para poder adjuntar su documento.</b> 
                    </Typography>
                    {
                        tpoUser === TIPOS_USUARIO.USUARIO_EXTERNO  &&
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}> <b>DNI del Solicitante :</b></Typography>
                        </Grid>
                    }
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                    {datos.adjuntos && datos.adjuntos
                        .filter(adjunto => !elementosNoDeseados.includes(adjunto.fileType.replace(/\d/g, "").trim()))
                        .filter(adjunto => elementosDeseados.includes(adjunto.fileType.replace(/\d/g, "").trim()))
                        .filter(adjunto => adjunto.fileType.replace(/\d/g, "").trim() !== 'Acta de Defunción emitida por el registro pertinente')
                        .filter(adjunto => adjunto.fileType.replace(/\d/g, "").trim() !== 'Otros Documentos')
                        .filter(adjunto => adjunto.fileType.replace(/\d/g, "").trim() !== 'Informe del SINTyS')
                        .map((adj, index) => {
                            return (
                                <Grid item xs={3} md={2} key={index} >
                                    <Grid container align='center' direction="column" key={index}>
                                        <Grid item key={index}>
                                            <p className={clases.pIcon} style={{ fontSize: adj.fileType.length < 30 ? 17 : 17, margin: 0 }} title={adj.fileType}>
                                                <b> {adj.fileType.replace(/\d/g, "").trim() === "DNI del Solicitante - Anverso" ? "DNI Anverso" : "DNI Reverso"} </b>
                                            </p>

                                            {
                                                (adj.fileType.replace(/\d/g, "").trim() === "DNI" || adj.fileType.search('-') !== -1) ?

                                                    <p className={clases.pIcon} style={{ fontSize: 13, margin: 0 }}
                                                        title={adj.fileType}>
                                                    </p>
                                                    :
                                                    <br />
                                            }

                                            { ///EN CASO DE NO ESTAR EL ADJUNTO MUESTRO LA IMAGEN PARA SUBIR EL ADJUNTO
                                                adj.file !== null && adj.file !== '' ?
                                                    <img src={adj.url} className={clases.imgIcon} alt='empty file'
                                                        title='Ver archivo' onClick={() => popitup(adj)} />
                                                    :
                                                    <img src={adj.url}
                                                        className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                            }
                                            {(localStorage.userType !== "1" && bloquearBtn !== true && adj.file !== null && adj.file !== '') &&
                                                <img src={DEL_FILE} className={clases.delIcon}
                                                    alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                            }
                                            <p className={clases.pFileName} style={{ marginBottom: 0 }} >{adj.fileName.length >= 19 ?
                                                adj.fileName.substring(0, 14) + '...' : adj.fileName}</p>
                                            <p className={clases.pIcon} style={{ marginTop: 0, marginBottom: 0 }} title={adj.fileType}>

                                                {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && adj.fileType !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de 50 años)" &&
                                                    <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>
                                                }
                                                {/* {
                                                    ((localStorage.userType !== "1" && bloquearBtn !== true) &&
                                                        adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                    ) &&
                                                    <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                } */}

                                                {((adj.extension === "application/pdf" || adj.extension === "image/jpeg" || adj.extension === "image/png" || adj.extension === "image/tiff") && ((idEstado !== 1 && tipoUsuario !== 0) || (idEstado !== 1 && !datos.Observado))) &&
                                                    <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2 }} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                }
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        {datos.adjuntos && datos.adjuntos
                        .filter(adjunto => !elementosNoDeseados.includes(adjunto.fileType.replace(/\d/g, "").trim()))
                        .filter(adjunto => !elementosDeseados.includes(adjunto.fileType.replace(/\d/g, "").trim()))
                        .filter(adjunto => !(adjunto.fileType === "Declaración Jurada" && idEstado === 1)) 
                        .filter(adj => 
                            (idEstado !== 1 && adj.fileType !== "Declaracion Jurada" && localStorage.userType != TIPOS_USUARIO.SECRETARIO_TECNICO && (localStorage.userType != TIPOS_USUARIO.PROFESIONAL || datos.Observado === false)) 
                            || ((idEstado !== 1) && adj.file !== null && adj.file !== '') 
                            || (localStorage.userType == TIPOS_USUARIO.SECRETARIO_TECNICO && adj.file !== null && adj.file !== '')
                        )
                        .map((adj, index) => {
                            
                                return (
                                    <Grid item xs={3} md={2} key={index}>
                                        {/* <Grid align='center' key={index} mt={adj.fileType.replace(/\d/g, "").trim() === 'Otros Documentos' ? 4.5 : 0}> */}
                                        <Grid align='center' key={index} mt={adj.fileType.replace(/\d/g, "").trim() === 'Otros Documentos' ? 0 : 0}>
                                            <Grid item>
                                            <p className={clases.pFileName} style={{ whiteSpace: 'pre-wrap', marginBottom: adj.fileType === "FotoPerfil" ? "0.5rem" : 0 }} >{adj.fileName === "DDJJ" ? `Solicitud de \n ${tpoTramite === 7 ? "Reinscripción" : "Rehabilitación"}` : adj.fileName.length >= 15 ? adj.fileName.substring(0, 11) + '...' : adj.fileName}</p>
                                                {
                                                    adj.file !== null && adj.file !== '' ?
                                                        <img src={adj.url} className={clases.imgIconCargado} alt='empty file' title='Ver archivo' onClick={() => popitup(adj)} />
                                                        :
                                                        <img src={adj.url} className={clases.imgIcon} alt='Subir archivo' name={adj.fileType} onClick={handleClick} title='Subir archivo' />
                                                }
                                                {(localStorage.userType !== "1" && bloquearBtn !== true && adj.fileType !== "Declaración Jurada" && adj.file !== null && adj.file !== '') &&

                                                    <img src={DEL_FILE} className={clases.delIcon} alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                }
                                                {(localStorage.userType === "1" && adj.fileType.replace(/\d/g, "").trim() === 'Informe del SINTyS' && adj.file !== null && adj.file !== '' && idEstado === 6) &&

                                                    <img src={DEL_FILE} className={clases.delIcon} alt='Quitar archivo' title='Quitar archivo' onClick={() => deleteFile(adj.fileType)} />
                                                }
                                                {/* <p className={clases.pFileName} style={{ whiteSpace: 'pre-wrap', marginBottom: 0 }} >{adj.fileName === "DDJJ" ? `Solicitud de \n ${tpoTramite === 7 ? "Reinscripción" : "Rehabilitación"}` : adj.fileName.length >= 15 ? adj.fileName.substring(0, 11) + '...' : adj.fileName}</p> */}

                                                <p className={clases.pIcon} style={{ whiteSpace: 'pre-wrap', fontSize: 13, marginTop: 0 }} title={adj.fileType}>

                                                    {((localStorage.userType !== "1" && bloquearBtn !== true) && adj.fileType.replace(/[^0-9]/g, "").length > 0) && adj.fileType !== "Declaración jurada de enfermedades y patologías preexistentes (para mayores de 50 años)" && <Button aria-label="edit" onClick={() => eliminarArchivoAdjuntoExtra(adj.fileType)}><DeleteIcon color="error" style={{ fontSize: 30 }} />  </Button>}
                                                    {
                                                        ((localStorage.userType !== "1" && bloquearBtn !== true && adj.fileType !== "Declaración Jurada") &&
                                                            adj.fileType.replace(/[^0-9]/g, "").length === 0
                                                        ) &&
                                                        <Fab title={"Agregar " + adj.fileType} color="secondary" size="small" variant="contained" aria-label="edit" onClick={() => agregarAdjunto(adj.fileType)}><AddIcon />  </Fab>
                                                    }

                                                    {(adj.extension === "application/pdf" || adj.extension === "image/jpeg" || adj.extension === "image/png" || adj.extension === "image/tiff") &&
                                                        <Fab title={"Descargar " + adj.fileType} sx={{ ml: 2, mt: adj.fileType === "FotoPerfil" ? 2 : 0}} color="" size="small" variant="contained" onClick={() => downloadPDF(adj.file, adj.fileType)}><FileDownloadIcon />  </Fab>

                                                    }
                                                    

                                                </p>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                        }
                        )}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}> </Grid>
                        <Grid item md={6} xs={6} sx={{ mt: 3 }} container>

                            <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("3")} variant="contained" color="error" disabled={bloquearBtn} >
                                Atras
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                                {(localStorage.userType !== "1" && localStorage.userType !== "10") &&
                                    <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary">
                                        {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                    </Button>
                                }
                                {
                                    (localStorage.userType === "1") &&
                                    <Grid>
                                       {
                                        <Grid>
                                            <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn && (estaFirmada || estaFirmada === null) || idEstado !== 6} variant="contained" type="submit" color="secondary">
                                                {loaderBtnGuardar === true ? <CircularProgress /> : ''} Continuar
                                            </Button>
                                        </Grid>
                                       } 
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form >
                : <Grid>

                    <Typography sx={{ mb: 1, fontSize: 14 }} ><b>* Verifique que los datos ingresados sean los correctos y luego haga click en el botón aceptar para enviar su solicitud de Reinscripcion/Rehabilitacion.</b></Typography>
                    <PDFViewer style={{
                        width: '100%',
                        height: '65vh'
                    }}  showToolbar={false}>

                        <Pdf datos={datos} TipoDocumentos={TipoDocumentos} leyendas={leyendas} nombreGerencia={nombreGerencia} EstadoCivilList={EstadoCivilList} Parentescos={Parentescos}/>

                    </PDFViewer>
                    <PDFDownloadLink document={<Pdf datos={datos} TipoDocumentos={TipoDocumentos} leyendas={leyendas} nombreGerencia={nombreGerencia} EstadoCivilList={EstadoCivilList} /> } fileName="DDJJ.pdf">
                        {({ blob, url, loading, error }) => {
                            blobDDJJ = blob;
                            urlDDJJ = url;
                        }

                        }
                    </PDFDownloadLink>
                    <Grid container>
                        <Grid item md={6} sx={{ mt: 3 }} container>
                            <Button startIcon={<HighlightOffIcon />} onClick={() => cancelarIncripcionPDF()} variant="contained" color="error" >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                                <Button startIcon={<CheckIcon />} disabled={bloquearBtn} variant="contained" onClick={() => guardarDatosInscripcion()} color="secondary">
                                    {loaderBtnGuardar === true ? <CircularProgress /> : ''}  Aceptar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <FilePreview file={selectedFile} onClose={handleClose} opened={openPreview} />
            <ModalTerminosyCondicionesRR
                onClose={() => setOpenConfirmacionTerminos(false)}
                open={openConfirmarTerminos}
                onSuccess={continuarGuardadoAdjuntos}
                setValue={setValue}
            />
            {
                montarPdf &&
                <PDFDownloadLink document={<Pdf datos={datos} ObrasSociales={ObrasSociales} titulosProfesional={titulosProfesional} TipoDocumentos={TipoDocumentos} EstadoCivilList={EstadoCivilList} leyendas={leyendas} Parentescos={Parentescos} titulosRR={titulosRR}/>} fileName="DDJJ.pdf">
                {({ blob, url, loading, error }) => {
                    // console.log(blob)
                    blobDDJJ = blob;
                    urlDDJJ = url;

                }

                }
                </PDFDownloadLink>
            }
        </Grid>

    );
}

