import { Divider, Grid, Typography } from '@mui/material'
import { formatDate } from 'utils/utils'

const formatNumber = (number) => {
  return String(number).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const DetalleMontoTasa = ({tasaRR}) => {

  const montoOrdenado = tasaRR.conceptos.$values.sort((a, b) => {
    const order = [5, 3, 4];
    return order.indexOf(parseInt(a.$id)) - order.indexOf(parseInt(b.$id));
  })

  return (
    <Grid ml={6}>
        <Typography variant="h4" gutterBottom>Detalle</Typography>
        <Grid display='flex' justifyContent='space-between' width={'85%'} >
            <Typography mb={2}><b>{localStorage.tpoTramite === "7" ? "Reinscripción" : "Rehabilitación"} al: {formatDate(new Date())}</b></Typography>
        
            <Typography><b>Importe</b></Typography>
        </Grid>
        <Divider sx={{width: '85%'}}/>
        {
            montoOrdenado.map((monto, index) => {
            return (
                <Grid item xs={12} key={index}>
                <Grid display='flex' justifyContent='space-between' width={'85%'} mb={2} mt={2}>
                    <Typography>{monto.descripcion}</Typography>
                    <Typography>${formatNumber(monto.monto)}</Typography>
                </Grid>
                <Divider sx={{width: '85%'}}/>
                </Grid>
            )
            })
        }
        <Grid  mt={1} display='flex' justifyContent='flex-end' width='85%'>
            <Typography   mr={6}> <b>Total:</b></Typography>
            <Typography color={'red'}> $ {formatNumber(tasaRR.montoTramite)}</Typography>
        </Grid>
    </Grid>
  )
}