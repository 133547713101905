import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Card, CardContent, Grid, Fab, Modal, Typography, FormControl, InputLabel, Select, TextField, MenuItem, Button, Radio, RadioGroup, FormControlLabel, FormLabel, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { parentescosList, getParentescosAsync } from 'app/features/Parentescos/parentescosSlice';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    color_texto_aclaracion: {
        //color: 'gray',
        fontSize: 1
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    backGroundBoxEliminado: {
        backgroundColor: '#E1E1E1!important'
    },
    deleteFab: {
        backgroundColor: 'red!important'
    },
    fabStyle: {
        backgroundColor: "white !important",
        color: '#d32f2f !important',
        borderColor: '#d32f2f !important',
        borderWidth: '2px !important', 
        borderStyle: 'solid !important', 
        "&:hover": {
            backgroundColor: '#c9e3ca !important',
            color: "white"
        }
    },
    color_rojo: {
        color: 'red'
    },
    modales: {
        overflow: 'scroll',

    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        '@media (max-width:900px)': {
            top: '49%',
        }
    }
}));

export const GrupoFamiliar = ({ datos, bloquearBtn, setDatos, setValue, ObrasSociales, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setMatriculas }) => {
    const clases = useStyles();

    const [open, setOpen] = useState(false);
    let obraSocialObjeto = [];
    let obraSocialNombre = "";
    const Parentescos = useSelector(parentescosList);
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const [familiaresAgregados, setFamiliaresAgregados] = useState([]);
    const [familiaresGuardados, setFamiliaresGuardados] = useState(0);
    const [editarRegistro, setEditarRegistro] = useState(0);
    const [editarRegistroIndex, setEditarRegistroIndex] = useState(0);

    const [errorModal, setErrorModal] = useState({ display: "none" });
    const [errorTexto, setErrorTexto] = useState('');

    const dispatch = useDispatch();
    const [grupoFamiliar, setDatosGrupoFamiliar] = useState({
        id: 0,
        codParentesco: '',
        nombreParentesco: '',
        nombre: 0,
        apellido: '',
        tipoDocumento: '',
        nombreTipoDocumento: '',
        numeroDocumento: '',
        fechaNacimiento: '',
        edad: '',
        sexo: null,
        incapacitado: '',
        obraSocial: '',
        ocupacion: '',
        lugarNacimiento: '',
        codNacionalidad: '',
        tpoOsocial: 0,
        cargo: ''

    });

    if (familiaresGuardados === 0) {
        if (Array.isArray(datos.familiares)) {
            setFamiliaresAgregados(datos.familiares);

        }
        setFamiliaresGuardados(1);
    }

    const handleOpen = (index) => {
        setErrorModal({ display: "none" })
        if (!isNaN(index)) {
            setDatosGrupoFamiliar({
                codParentesco: familiaresAgregados[index].codParentesco,
                nombreParentesco: familiaresAgregados[index].nombreParentesco,
                nombre: familiaresAgregados[index].nombre,
                apellido: familiaresAgregados[index].apellido,
                tipoDocumento: familiaresAgregados[index].tipoDocumento,
                nombreTipoDocumento: familiaresAgregados[index].nombreTipoDocumento,
                numeroDocumento: familiaresAgregados[index].numeroDocumento,
                fechaNacimiento: familiaresAgregados[index].fechaNacimiento,
                edad: familiaresAgregados[index].edad,
                incapacitado: familiaresAgregados[index].incapacitado,
                obraSocial: familiaresAgregados[index].obraSocial,
                tpoOsocial: familiaresAgregados[index].tpoOsocial,
                codNacionalidad: familiaresAgregados[index].codNacionalidad,
                ocupacion: familiaresAgregados[index].ocupacion,
                lugarNacimiento: familiaresAgregados[index].lugarNacimiento,
                sexo: familiaresAgregados[index].sexo,
                cargo: familiaresAgregados[index].cargo
            })

            setEditarRegistroIndex(index);
            setEditarRegistro(1);


        } else {
            setDatosGrupoFamiliar({
                id: 0,
                codParentesco: '',
                nombreParentesco: '',
                nombre: '',
                apellido: '',
                tipoDocumento: '',
                nombreTipoDocumento: '',
                numeroDocumento: '',
                fechaNacimiento: '',
                edad: '',
                sexo: null,
                incapacitado: '',
                ocupacion: '',
                lugarNacimiento: '',
                codNacionalidad: '',
                obraSocial: '',
                tpoOsocial: 0,
                cargo: ''
            })
            setEditarRegistroIndex(9999);
            setEditarRegistro(0);
        }
        setOpen(true);
    }


    useEffect(() => {

        if (Parentescos.length === 0) {

            //llamo a la funcion del Slice
            dispatch(getParentescosAsync());
        }
        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }
    }, [])

    const handleClose = () => setOpen(false);
    const ingresaObraSocial = (event, value) => {

        if (value !== null) {

            setDatosGrupoFamiliar({
                ...grupoFamiliar,
                obraSocial: value.label,
                tpoOsocial: Number(value.value)
            });

        } else {

            setDatosGrupoFamiliar({
                ...grupoFamiliar,
                tpoOsocial: 0,
                obraSocial: ''
            });
        }
    }
    const inputHandleChange = (evt) => {

        if (evt.target.name === "numeroDocumento") {
            evt.target.value = evt.target.value.replace(/\./g, '').replace(/[a-z ]/g, "");
        }

        if (evt.target.name === "fechaNacimiento" && evt.target.value !== "") {




            var hoy = new Date();
            var fechaFormulario = new Date(evt.target.value);

            // Comparamos solo las fechas => no las horas!!
            hoy.setHours(0, 0, 0, 0);  // Lo iniciamos a 00:00 horas

            if (hoy <= fechaFormulario) {


                var mes = (hoy.getMonth() + 1);
                if ((hoy.getMonth() + 1) < 10) {
                    mes = "0" + (hoy.getMonth() + 1)
                }
                //hoy.getFullYear() + "-" + mes + "-" +  hoy.getDate()
                setDatosGrupoFamiliar({
                    ...grupoFamiliar,
                    [evt.target.name]: '',
                    edad: 0
                });
                setErrorTexto("La fecha ingresada no debe ser posterior a la fecha actual");

                setErrorModal({ display: "" })
                return false;
            }

            setDatosGrupoFamiliar({
                ...grupoFamiliar,
                fechaNacimiento: evt.target.value,
                edad: calcularEdad(evt.target.value)
            });
        } else if (evt.target.name === "sexo") {
            setDatosGrupoFamiliar({
                ...grupoFamiliar,
                [evt.target.name]: Number(evt.target.value)
            });
        } else {
            setDatosGrupoFamiliar({
                ...grupoFamiliar,
                [evt.target.name]: evt.target.value
            });
        }

    }

    const { handleSubmit, formState: { errors } } = useForm();

    const eliminarFamiliar = (index) => {


        Swal.fire({
            title: 'Atención',
            html: '¿Esta seguro que desea quitar esta información?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText:
                'Aceptar',
            cancelButtonText:
                'Cancelar',
            allowOutsideClick: true
        }).then((result) => {

            if (result.value) {


                if (familiaresAgregados.filter(item => item.id === index)[0].idFam !==0) {
                    familiaresAgregados.filter(item => item.id === index)[0].estado = 0;
                    setFamiliaresAgregados(familiaresAgregados)
                    setDatos({
                        ...datos,
                        familiares: familiaresAgregados
                    })
                } else {
                    if (familiaresAgregados.length === 1) {
                        setFamiliaresAgregados([])
                    } else {

                        setFamiliaresAgregados(familiaresAgregados.filter(item => item.id !== index))
                    }
    

                    setDatos({
                        ...datos,
                        familiares: familiaresAgregados.filter(item => item.id !== index)
                    })
                }
 

                
            }
        });
    }
    const guardarContinuar = () => {

        // setDatos({
        //     ...datos,
        //     familiares: familiaresAgregados
        // })

        setLoaderBtnGuardar(true)
        setBloquearBtn(true);
        // let adjuntosTemporales = [];
        // datos.adjuntos.map((adj, i) => {
        //     adjuntosTemporales.push({
        //         url: adj.url,
        //         file: adj.file,
        //         fileType: adj.fileType,
        //         fileName: adj.fileName
        //     })
        // })

        // datos.adjuntos.map((adj, i) => {
        //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].file = '';
        //     datos.adjuntos.filter(c => c.fileType === adj.fileType)[0].url = '';
        // })

        guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: 1 }).then((value) => {
                setMatriculas(value.data);
                setLoaderBtnGuardar(false)
                setBloquearBtn(false);
                if (value.data.resultado) {
                    setValue("3");
                } else {
                    Swal.fire({
                        title: 'Atención',
                        html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonText:
                            'Aceptar',
                        cancelButtonText:
                            'Cancelar',
                        allowOutsideClick: true
                    })
                    return false;
                }
        });
    }

    const agregarFamiliar = () => {

        if (grupoFamiliar.sexo === null) {
            setErrorTexto("Debe seleccionar el sexo");

            setErrorModal({ display: "" })
            return false;
        }

        Parentescos.map((parentesco, index) => {
            if (parentesco.codParentesco === grupoFamiliar.codParentesco) {

                grupoFamiliar.nombreParentesco = parentesco.descripcion;
            }
        })
        TipoDocumentos.map((documento, index) => {
            if (documento.tipoDocumento === grupoFamiliar.tipoDocumento) {
                grupoFamiliar.nombreTipoDocumento = documento.descripcion;
            }
        })

        let detenerGuardado = 0;
        familiaresAgregados.filter(item => item.id !== editarRegistroIndex).map((familiar, index) => {
            if (
                ((familiar.codParentesco === 51 || familiar.codParentesco === 63) && familiar.estado !== 0)
                && (grupoFamiliar.codParentesco === 63 || grupoFamiliar.codParentesco === 51) 
                && index !== editarRegistroIndex) {
                setErrorModal({ display: "" })
                detenerGuardado = 1;
                const tipoFamiliarExistente = familiar.codParentesco !== 51 ? "conviviente" : "cónyuge";
                const tipoFamiliarNuevo = grupoFamiliar.codParentesco !== 51 ? "conviviente" : "cónyuge";
                setErrorTexto(`No puede cargar un ${tipoFamiliarNuevo} debido que ya ha añadido un ${tipoFamiliarExistente}`);
                return false;
            }
        })

        if (detenerGuardado === 1)
            return false;


        if (editarRegistro === 1) {

            familiaresAgregados[editarRegistroIndex].nombre = grupoFamiliar.nombre;
            familiaresAgregados[editarRegistroIndex].apellido = grupoFamiliar.apellido;
            familiaresAgregados[editarRegistroIndex].codParentesco = grupoFamiliar.codParentesco;
            familiaresAgregados[editarRegistroIndex].nombreParentesco = grupoFamiliar.nombreParentesco;
            familiaresAgregados[editarRegistroIndex].tipoDocumento = grupoFamiliar.tipoDocumento;
            familiaresAgregados[editarRegistroIndex].nombreTipoDocumento = grupoFamiliar.nombreTipoDocumento;
            familiaresAgregados[editarRegistroIndex].numeroDocumento = grupoFamiliar.numeroDocumento;
            familiaresAgregados[editarRegistroIndex].fechaNacimiento = grupoFamiliar.fechaNacimiento;
            familiaresAgregados[editarRegistroIndex].edad = grupoFamiliar.edad;
            familiaresAgregados[editarRegistroIndex].sexo = grupoFamiliar.sexo;
            familiaresAgregados[editarRegistroIndex].incapacitado = grupoFamiliar.incapacitado;
            familiaresAgregados[editarRegistroIndex].obraSocial = grupoFamiliar.obraSocial;
            familiaresAgregados[editarRegistroIndex].tpoOsocial = grupoFamiliar.tpoOsocial;
            familiaresAgregados[editarRegistroIndex].cargo = grupoFamiliar.cargo;
            familiaresAgregados[editarRegistroIndex].codNacionalidad = grupoFamiliar.codNacionalidad;
            familiaresAgregados[editarRegistroIndex].ocupacion = grupoFamiliar.ocupacion;
            familiaresAgregados[editarRegistroIndex].lugarNacimiento = grupoFamiliar.lugarNacimiento;

            setFamiliaresAgregados(familiaresAgregados)
            setDatos({
                ...datos,
                familiares: familiaresAgregados
            })
        } else {

            const listaFamiliares = [...familiaresAgregados, {
                id: familiaresAgregados.length,
                nombre: grupoFamiliar.nombre,
                apellido: grupoFamiliar.apellido,
                codParentesco: grupoFamiliar.codParentesco,
                nombreParentesco: grupoFamiliar.nombreParentesco,
                tipoDocumento: grupoFamiliar.tipoDocumento,
                nombreTipoDocumento: grupoFamiliar.nombreTipoDocumento,
                numeroDocumento: grupoFamiliar.numeroDocumento,
                fechaNacimiento: grupoFamiliar.fechaNacimiento,
                sexo: grupoFamiliar.sexo,
                edad: grupoFamiliar.edad,
                incapacitado: grupoFamiliar.incapacitado,
                obraSocial: grupoFamiliar.obraSocial,
                tpoOsocial: grupoFamiliar.tpoOsocial,
                codNacionalidad: grupoFamiliar.codNacionalidad,
                ocupacion: grupoFamiliar.ocupacion,
                lugarNacimiento: grupoFamiliar.lugarNacimiento,
                cargo: grupoFamiliar.cargo,
                idFam: 0,
                estado: 1
            }]
            setDatos({
                ...datos,
                familiares: listaFamiliares
            })
            setFamiliaresAgregados(listaFamiliares)
        }

        setOpen(false);
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const convertDateFormat = (value) => {
        // TODO: ajustar con alguna librería estándar
        var info = value;
        if (info.includes('-')) {
            info = info.split('-');
            return info[2] + '/' + info[1] + '/' + info[0];
        } else {
            info = value.replace(" 00:00:00", "");
            info = info.replace("00:00:00", "");
            info = info.split('/');
            return info[0] + '/' + info[1] + '/' + info[2];
        }
    }

    // const convertDateFormat = (value) => {
    //     let info = value.split('/');
    //     return `${info[2]}/${info[1]}/${info[0]}`;
    // }

    const calcularEdad = (fecha) => {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edad = hoy.getFullYear() - cumpleanos.getFullYear();
        let m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    ObrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })
    let ObrasSocialesOrdenadas = [];
    function SortArray(x, y) {
        if (x.label < y.label) { return -1; }
        if (x.label > y.label) { return 1; }
        return 0;
    }

    if (obraSocialObjeto.length > 0) {

        obraSocialNombre = "";

        obraSocialObjeto.map((obraSocial, index) => {
            if (obraSocialNombre.toUpperCase() !== obraSocial.label.trim().toUpperCase()) {

                let agregarObraSocial = 0;
                ObrasSocialesOrdenadas.map(obraSocialRepetida => {
                    if (obraSocialRepetida.label.trim().toUpperCase() === obraSocial.label.trim().toUpperCase())
                        agregarObraSocial = 1;

                })
                if (agregarObraSocial === 0)
                    ObrasSocialesOrdenadas.push({ key: index, label: obraSocial.label.trim().toUpperCase(), value: obraSocial.value })
            }


            obraSocialNombre = obraSocial.label.trim();


        })

    }

    return (
        <Fragment>
            <Box pl={2}>
                <Typography className={clases.color_texto_aclaracion} sx={{ mb: 1 }} >
                    {localStorage.userType !== "1" && <b>Nuevo Familiar</b>}
                    {(localStorage.userType !== "1" && bloquearBtn !== true) &&
                        <Fab sx={{ m: 2 }} size="small" color="secondary" aria-label="edit" onClick={handleOpen}>
                            <AddIcon />
                        </Fab>
                        
                    }
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {familiaresAgregados.filter(item => localStorage.userType === "0" ? item.estado !== 0 : item.estado != 2).map((familiarAgregado, index) =>
                    <Grid item md={6} key={index}>
                        <Card variant='custom' className={familiarAgregado.estado === 0 ? clases.backGroundBoxEliminado : familiarAgregado.idFam === 0 && localStorage.userType !== "0" ? clases.backGroundBoxAgregado : ''} sx={{ boxShadow: 5, background: '#e4f0ec', borderColor: '#b8daba', borderRadius: '5px', border: '3px solid #b8daba', minHeight: '375px'}}>
                            <CardContent>
                                <Grid container>
                                    <Grid item md={12} className={clases.centrarContenido}>
                                        <h3 style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}> <PersonIcon />{familiarAgregado.apellido + " " + familiarAgregado.nombre}</h3>
                                    </Grid>

                                    <Grid item md={6} sx={{ mb: 2 }}>
                                        Documento: <b>{familiarAgregado.nombreTipoDocumento ?? "DNI"} {familiarAgregado.numeroDocumento}</b>
                                    </Grid>
                                    <Grid item md={6} sx={{ mb: 2 }}>
                                        Fecha Nacimiento: <b>{(familiarAgregado.fechaNacimiento !== null && familiarAgregado.fechaNacimiento !== "") && convertDateFormat(familiarAgregado.fechaNacimiento)}</b>
                                    </Grid>

                                    <Grid item md={2} sx={{ mb: 2 }}>
                                        Edad: <b>{familiarAgregado.edad}</b>
                                    </Grid>
                                    <Grid item md={4} sx={{ mb: 2 }}>
                                        A cargo: <b>{familiarAgregado.cargo === true && "SI" || familiarAgregado.cargo === false && "NO"}</b>
                                    </Grid>
                                    <Grid item md={4} sx={{ mb: 2 }}>
                                        Incapacitado: <b>{familiarAgregado.incapacitado === true && "SI" || familiarAgregado.incapacitado === false && "NO"}</b>
                                    </Grid>
                                    <Grid item md={2} sx={{ mb: 2 }}>
                                        Sexo: <b>{familiarAgregado.sexo === 0 && "F" || familiarAgregado.sexo === 1 && "M" || familiarAgregado.sexo === 2 && "X"}</b>
                                    </Grid>

                                    <Grid item md={6} sx={{ mb: 2 }}>
                                        Obra Social: <b>{familiarAgregado.obraSocial}</b>
                                    </Grid>
                                    <Grid item md={6} sx={{ mb: 2 }}>
                                        Parentesco: <b>{familiarAgregado.nombreParentesco ?? "No informado"}</b>
                                    </Grid>
                                    
                                    <Grid item md={6} sx={{ mb: 2 }}>
                                        Nacionalidad: <b>{familiarAgregado.codNacionalidad === 1 ? "Argentina" : familiarAgregado.codNacionalidad === 2 ? "Extranjera" : "Sin Informacion"}</b>
                                    </Grid>
                                    <Grid item md={6} sx={{ mb: 2 }}>
                                        Lugar de Nacimiento: <b>{familiarAgregado.lugarNacimiento}</b>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 2 }}>
                                        Ocupacion: <b>{familiarAgregado.ocupacion}</b>
                                    </Grid>

                                    {(localStorage.userType !== "1" && bloquearBtn !== true) &&
                                        <Grid item md={12} className={clases.floatRight}>
                                            <Fab size="small" className={clases.fabStyle} sx={{ mr: 2, color: "white" }} aria-label="eliminar" onClick={() => eliminarFamiliar(familiarAgregado.id)}>
                                                <PersonRemoveOutlinedIcon />
                                            </Fab>
                                            <Fab size="small" color="secondary" aria-label="edit" onClick={() => handleOpen(familiarAgregado.id)}>
                                                <EditIcon />
                                            </Fab>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>

            <Modal
                open={open}
                className={clases.modales}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={clases.modal} sx={{ boxShadow: 5, borderColor: 'black', borderRadius: '5px', border: '1px solid black'}}>
                    <CardContent>
                        <Grid container style={{ marginBottom: 15 }}>


                            <Grid item container justifyContent="flex-end" >
                                <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                            </Grid>
                        </Grid>
                        <form onSubmit={handleSubmit(agregarFamiliar)}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={6}>
                                    <TextField label="Apellido"
                                        name='apellido'
                                        onChange={inputHandleChange}
                                        required
                                        value={grupoFamiliar.apellido}
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}}
                                    />
                                </Grid>

                                <Grid item md={6} xs={6}>
                                    <TextField label="Nombre"
                                        name='nombre'
                                        onChange={inputHandleChange}
                                        required
                                        value={grupoFamiliar.nombre}
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}}
                                    />
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <FormControl fullWidth sx={{backgroundColor: "white"}}>
                                        <InputLabel id="labelParentesco">Parentesco *</InputLabel>
                                        <Select
                                            labelId="labelParentesco"
                                            name="codParentesco"
                                            required
                                            label="Parentesco"
                                            value={grupoFamiliar.codParentesco}
                                            onChange={inputHandleChange}
                                        >
                                            {

                                                Parentescos.map((parentesco, index) =>

                                                    <MenuItem key={index} value={parentesco.codParentesco}>{parentesco.descripcion}</MenuItem>
                                                )


                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={6}>

                                    <FormControl fullWidth sx={{backgroundColor: "white"}}>
                                        <InputLabel id="acargoid">A Cargo *</InputLabel>
                                        <Select
                                            labelId="acargoid"
                                            name="cargo"
                                            required
                                            onChange={inputHandleChange}
                                            value={grupoFamiliar.cargo}
                                            label="A Cargo"
                                        >
                                            <MenuItem key={2} value={true}>Si</MenuItem>
                                            <MenuItem key={1} value={false}>No</MenuItem>


                                        </Select>
                                    </FormControl>


                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl>
                                        <FormLabel id="demo-form-control-label-placement">Sexo *</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-form-control-label-placement"
                                            name="position"
                                            value={grupoFamiliar.sexo}
                                        >
                                            <FormControlLabel
                                                value={0}
                                                onChange={inputHandleChange}
                                                name="sexo"
                                                control={<Radio />}
                                                label="F"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                value={1}
                                                onChange={inputHandleChange}
                                                name="sexo"
                                                control={<Radio />}
                                                label="M"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                value={2}
                                                onChange={inputHandleChange}
                                                name="sexo"
                                                control={<Radio />}
                                                label="X"
                                                labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>


                                <Grid item md={4} xs={6}>
                                    <FormControl fullWidth sx={{backgroundColor: "white"}}>
                                        <InputLabel id="labelTipoDocumento">Tipo de documento *</InputLabel>
                                        <Select
                                            labelId="labelTipoDocumento"
                                            name="tipoDocumento"
                                            required
                                            onChange={inputHandleChange}
                                            value={grupoFamiliar.tipoDocumento}
                                            label="Tipo de documento"
                                        >
                                            {

                                                TipoDocumentos.map((documento, index) =>

                                                    <MenuItem key={index} value={documento.tipoDocumento}>{documento.descripcion}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item md={4} xs={6}>
                                    <TextField label="Numero"
                                        name='numeroDocumento'
                                        value={grupoFamiliar.numeroDocumento}
                                        onChange={inputHandleChange}
                                        inputProps={{ maxLength: 8 }}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}}
                                    />
                                </Grid>

                                <Grid item md={4} xs={12}>

                                    <TextField label="Fecha de Nacimiento"
                                        name='fechaNacimiento'
                                        onChange={inputHandleChange}
                                        value={grupoFamiliar.fechaNacimiento}
                                        required
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        sx={{backgroundColor: "white"}}
                                    />


                                </Grid>
                                <Grid item md={4} xs={6}>

                                    <TextField label="Edad"
                                        name='edad'
                                        disabled
                                        //variant="filled"
                                        onChange={inputHandleChange}
                                        value={grupoFamiliar.edad}
                                        required
                                        variant="filled"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}}
                                    />


                                </Grid>
                                <Grid item md={4} xs={6}>

                                    <FormControl fullWidth sx={{backgroundColor: "white"}}>
                                        <InputLabel id="incapacitado">Incapacitado *</InputLabel>
                                        <Select
                                            labelId="incapacitado"
                                            name="incapacitado"
                                            required
                                            onChange={inputHandleChange}
                                            value={grupoFamiliar.incapacitado}
                                            label="Incapacitado"
                                        >
                                            <MenuItem key={2} value={true}>Si</MenuItem>
                                            <MenuItem key={1} value={false}>No</MenuItem>


                                        </Select>
                                    </FormControl>


                                </Grid>
                                <Grid item md={4} xs={12}>
                                    {/* 
                                    <TextField label="Obra Social"
                                        name='obraSocial'
                                        onChange={inputHandleChange}
                                        value={grupoFamiliar.obraSocial}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}}
                                    /> */}
                                    <Autocomplete
                                        name='obraSocial'
                                        onChange={ingresaObraSocial}
                                        options={ObrasSocialesOrdenadas}
                                        noOptionsText={'No se encontraron Obras Sociales'}
                                        value={{ label: grupoFamiliar.obraSocial, value: grupoFamiliar.obraSocial }}
                                        getOptionLabel={(option) => option.label}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        renderInput={(params) => <TextField {...params} variant={bloquearBtn === true ? "filled" : "outlined"} required label="Obra Social" />}
                                        sx={{backgroundColor: "white"}}
                                    />

                                </Grid>
                                <Grid item md={4} xs={6}>

                                    <TextField label="Ocupación"
                                        name='ocupacion'
                                        onChange={inputHandleChange}
                                        value={grupoFamiliar.ocupacion}
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}} />
                                </Grid>
                                <Grid item md={4} xs={6}>

                                    <TextField label="Lugar de Nacimiento"
                                        name='lugarNacimiento'
                                        onChange={inputHandleChange}
                                        value={grupoFamiliar.lugarNacimiento}
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        sx={{backgroundColor: "white"}} />
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <FormControl fullWidth sx={{backgroundColor: "white"}}>
                                        <InputLabel id="nacionalidadid">Nacionalidad *</InputLabel>
                                        <Select
                                            labelId="nacionalidadid"
                                            name="codNacionalidad"
                                            required
                                            onChange={inputHandleChange}
                                            value={grupoFamiliar.codNacionalidad == 0 ? '' : grupoFamiliar.codNacionalidad}
                                            label="Nacionalidad"
                                        >
                                            <MenuItem key={1} value={1}>Argentina</MenuItem>
                                            <MenuItem key={2} value={2}>Extranjera</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography className={clases.color_rojo} style={errorModal} >{errorTexto}</Typography>
                                </Grid>
                                <Grid item md={12} className={clases.floatRight}>
                                    <Button variant="contained" color="secondary" type="submit" startIcon={<CheckIcon />}>
                                        Confirmar
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
            {/* <Grid container>
                <Grid item md={12}> </Grid>
                <Grid item md={6} xs={6} sx={{ mt: 3 }} container>
                    <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("1")} variant="contained" type="submit" color="error" >
                        Atras
                    </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                        <Button disabled={bloquearBtn} endIcon={<ArrowForwardIosIcon />} onClick={() => guardarContinuar()} variant="contained" color="secondary" >

                            {loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar
                        </Button>
                    </Grid>
                </Grid>
            </Grid> */}
        </Fragment>
    );
}

