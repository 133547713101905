import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, Modal, Typography, Backdrop, Fade } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getProfesionalLegajo, obtenerCarnetProf } from 'app/features/Profesionales/profesionalesAPI';
import { formatDate, formatDNI, isNullOrEmpty } from 'utils/utils';
import { obtenerProfConTitulos } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { EMPTY_FILE } from 'constants/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setCambiaFoto } from 'app/features/FotoProfesional/editarDatosProfesionalSlice';
import { parentescosList } from 'app/features/Parentescos/parentescosSlice';
import { tipoDocumentoList } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { tipoDomicilioList } from 'app/features/TipoDomicilio/tipoDomicilioSlice';
import { nuevoAdjunto } from 'utils/adjuntosLogic';

export const DatosMatricula = ({ datos, bloquearBtn, setBloquearBtn, setDatos, setValue, loaderBtnGuardar, setLoaderBtnGuardar, idEstado, datosMatricula, datosFirma , tpoTramite, estadoProf }) => {

  const dispatch = useDispatch();
  const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);
  const [croppedImage, setCroppedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const Parentescos = useSelector(parentescosList);
  const TipoDocumentos = useSelector(tipoDocumentoList);
  const TipoDomicilios = useSelector(tipoDomicilioList);
  const titulosRR = useSelector(state => state.titulosRRReducer.titulosRR);

  const tipoDocFam = TipoDocumentos.filter(t => t.tipoDocumento === datos.TipoDocumento)[0]?.descripcion.trim();

  const tamanioFuente = 18;
  let idLegajo = localStorage.userType !== "1" ? localStorage.idLegajo : datos.LegProf;
  let legProfFormateado = idLegajo.slice(0, idLegajo.length - 1) + '/' + idLegajo.slice(idLegajo.length - 1);

  const handleContinue = () => {
    if (editarFotoSlice.deseaCambiarFoto) {
        let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
        if (adjuntoDeFoto) {
            adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
        } else {
            adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
            datos.adjuntos.push(adjuntoDeFoto);
        }
        let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
        if (adjuntoDeFotoFull) {
            adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
        } else {
            adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
            datos.adjuntos.push(adjuntoDeFotoFull);
        }

    }

    setValue("2")
  }

  const handleClose = () => {
    setOpen(false);
  }

  const fechaFormateada = (value) => {
    if (localStorage.userType !== "1") {
        let fechaNacimiento = datosMatricula?.nacimiento.split(" ")[0]
        // let [dia, mes, anio] = fechaNacimiento.split("/");
        // if( !mes.startsWith("0")) {
        //     mes = "0" + mes 
        // }
        // return `${dia}/${mes}/${anio}`;
        return datosMatricula?.nacimiento.split(" ")[0]
    } else {
        return datosMatricula?.nacimiento.split(" ")[0]
    }

  }

    const calcularEdad = (fecha) => {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edad = hoy.getFullYear() - cumpleanos.getFullYear();
        let m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        return edad;
    }

    const convertirFecha = string => {
        if (string.search('/') === -1) {
            var info = string.split('-');

            if (info[1].length === 1)
                info[1] = "0" + info[1];
        
            if (info[2].length === 1)
                info[2] = "0" + info[2];
        
            return info[0] + '-' + info[1] + '-' + info[2];
        }
        string = string.slice(0, string.search(' '));
        var info = string.split('/');

        if (info[1].length === 1)
            info[1] = "0" + info[1];

        if (info[0].length === 1)
            info[0] = "0" + info[0];

        return info[2] + '-' + info[1] + '-' + info[0];
    }

    const cargarDatos = () => {

        if (datosMatricula.familiares?.length > 0) {
            datosMatricula.familiares.map((fam) => {
                let fechaNacimiento = fam.fechaNacimiento.split(" ")[0];
                let [dia, mes, anio] = fechaNacimiento.split("/");
                fam.fechaNacimiento = `${anio}-${mes}-${dia}`;
                fam.edad = calcularEdad(fam.fechaNacimiento);
            });
        }
        
        let familiaresArr = [];

        datosMatricula.familiares.map((fam, index) => {
            let parentescoFam = Parentescos.filter(p => p.codParentesco === fam.codParentesco)[0]?.descripcion.trim();
            let tipoDocFam = TipoDocumentos.filter(t => t.tipoDocumento === fam.tipoDocumento)[0]?.descripcion.trim();
            familiaresArr.push({
                nombreParentesco: parentescoFam === undefined ? null : parentescoFam,
                nombreTipoDocumento: tipoDocFam === undefined ? null : tipoDocFam,
                apellido: fam.apellido,
                cargo: fam.cargo,
                codParentesco: fam.codParentesco,
                edad: Number(calcularEdad(convertirFecha(fam.fechaNacimiento))),
                fechaNacimiento: convertirFecha(fam.fechaNacimiento),
                id: index,
                incapacitado: fam.incapacitado,
                nombre: fam.nombre.trim(),
                codNacionalidad: fam.codNacionalidad,
                ocupacion: fam.ocupacion,
                lugarNacimiento: fam.lugarNacimiento,
                numeroDocumento: fam.numeroDocumento,
                obraSocial: fam.obraSocial.trim(),
                sexo: fam.sexo,
                tipoDocumento: fam.tipoDocumento,
                tpoOsocial: fam.tpoOsocial,
                idFam: fam.idFam,
                estado: fam.estado === null || fam.estado === undefined ? 1 : fam.estado,
            })
        })

        setDatos({
            Nombre: datosMatricula.nombre.trim(),
            Apellido: datosMatricula.apellido.trim(),
            PerteneceOtroConsejo: datosMatricula.perteneceOtroConsejo === undefined ? null : datosMatricula.perteneceOtroConsejo,
            Nacimiento: datosMatricula.nacimiento,
            NroDocumento: datosMatricula.nroDocumento,
            ObraSocial:datosMatricula.obraSocial !== undefined && datosMatricula.obraSocial !== null ? datosMatricula.obraSocial : '',
            sexo: datosMatricula.sexo ? datosMatricula.sexo : 0,
            EstadoCivil: datosMatricula.estadoCivil ? datosMatricula.estadoCivil : '',
            TpoOsocial: datosMatricula.tpoOsocial,
            TipoDocumento: datosMatricula.tipoDocumento,
            CuitCuil: datosMatricula.cuitCuil,
            Nacionalidad: datosMatricula.nacionalidad ? datosMatricula.nacionalidad : '',
            CodNacionalidad: datosMatricula.nacionalidad.trim() === 'ARGENTINO' ? 1 : 2,
            Email: "",
            LegProf: localStorage.idLegajo,
            titulos: datosMatricula.titulos,
            familiares: familiaresArr,
            domicilios: Array.isArray(datosMatricula.domicilios) ? datosMatricula.domicilios : datos.domicilios,
            Celular: datosMatricula.celular ? datosMatricula.celular : '',
            EmailRepetido: "",
            CodEnt: datosMatricula.CodEnt ? datosMatricula.CodEnt : null,
            TipoTramite: datosMatricula.CodEnt ? datosMatricula.CodEnt : null,
            adjuntos: [
                { fileType: 'DNI del Solicitante - Anverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
                { fileType: 'DNI del Solicitante - Reverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            ]
        });
    }

  useEffect(() => {
    if(datos.Apellido === "") {
        cargarDatos();
    }
    }, [])

  return (
    <Grid container key={crypto.randomUUID()}>
        <Card sx={{ boxShadow: 5, background: '#e4f0ec', width: '100%', borderColor: '#b8daba', borderRadius: '5px', border: '3px solid #b8daba', mb:2}}>
            <CardContent>
                <Grid display="flex" justifyContent="center">
                    <Typography variant="h6" component="span" textAlign='center'> Actual Estado Matricular: <b>{tpoTramite === 7 ? "Cancelado" : "Suspendido"}</b> </Typography>
                    <Typography variant="h6" component="span" textAlign='center' ml={2} >Desde: <b>{formatDate(estadoProf.fecEstado)}</b></Typography>
                </Grid>
            </CardContent>
        </Card>
        <Card sx={{ boxShadow: 5, background: '#e4f0ec', width: '100%', borderColor: '#b8daba', borderRadius: '5px', border: '3px solid #b8daba'}} >
        <CardContent>
        <Grid item xs={12} md={12} mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography fontSize={tamanioFuente}>Nombre: <b>{datosMatricula?.nombre}</b></Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography fontSize={tamanioFuente}>Apellido: <b>{datosMatricula?.apellido}</b></Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography fontSize={tamanioFuente}>Fecha de Nacimiento: <b>{fechaFormateada()}</b></Typography>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} md={12} mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography fontSize={tamanioFuente}>Tipo de documento: <b>{tipoDocFam}</b></Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography fontSize={tamanioFuente}>Numero: <b>{datosMatricula !== null && datosMatricula?.nroDocumento}</b></Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography fontSize={tamanioFuente}>Legajo: <b>{legProfFormateado}</b></Typography>
                </Grid>
            </Grid>
        </Grid>

        {
            titulosRR?.map((titulos, index) => (
                <Grid item xs={12} md={12} mt={2} key={`${titulos.id}-${index}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography fontSize={tamanioFuente}>Titulo: <b>{titulos.descrip.trim()}</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography fontSize={tamanioFuente}>Tomo: <b>{titulos.tomo}</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography fontSize={tamanioFuente}>Folio: <b>{titulos.folio}</b></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))
        }

        <Grid item xs={12} md={12} mt={3}>
            <Typography fontSize={tamanioFuente} mb={2}>Firma del profesional registrada en el Sistema de Matrículas</Typography>
            {
                (datosFirma !== null && datosFirma.firma === null) ? 
                <Typography fontSize={tamanioFuente}><b><i>No se ha encontrado firma</i></b></Typography> 
                : <img src={`data:image/jpeg;base64,${datosFirma?.firma}`} alt="Firma" onClick={() => setOpen(true)} style={{width: '20%', border: '2px solid #000', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}/>
            }
        </Grid>
        
        </CardContent>
        </Card>
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            {
                datosFirma?.firma &&
                <Fade in={open} timeout={500}>
                    <img src={`data:image/jpeg;base64,${datosFirma?.firma}`} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '30%', height: '30%', border: '2px solid #000', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}/>
                </Fade>
            }
        </Modal>
        <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
            <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" onClick={handleContinue} color="secondary" >
                {loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar
            </Button>
        </Grid>
    </Grid>
  )
}