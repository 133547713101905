import { Button, Modal, Typography, Card, CardContent, Grid, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { TIPOS_USUARIO } from "constants/constants";
import { useDispatch } from "react-redux";
import { setAceptarTyc } from "app/features/TyC/tyCSlice";

const useStyles = makeStyles(theme => ({
    modalStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    floatCenter: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export const ModalTerminosyCondicionesRR = ({ open, onClose, onSuccess, setValue }) => {
    
    const clases = useStyles();
    const dispatch = useDispatch()
    const [aceptoTerminosYCondiciones, setAceptoTerminosYCondiciones] = useState(false);
    const userType = Number(localStorage.userType)

    const handleClose = (event) => {
        setAceptoTerminosYCondiciones(false);
        onClose(event);
    }
    const handleCloseGuardar = (event) => {
        dispatch(setAceptarTyc())
        handleClose(event);
        onSuccess();
        // setValue("5");
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={clases.modalStyle} 
        >
            <Card className={'card-aceptar-ddjj'}>
                <CardContent>
                    <Grid container style={{ marginBottom: 15 }}>
                        <Grid item container justifyContent="flex-end" >
                            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                        </Grid>
                            <Typography style={{ fontSize: 13 }} ><b>Declaro que la clave/contraseña y demás datos de identificación personal
                                requeridos para acceder a dichos trámites son de mi exclusivo conocimiento, asumiendo toda responsabilidad derivada
                                tanto de su uso como de su confidencialidad. Asimismo, manifiesto con carácter de DECLARACIÓN JURADA que la información consignada
                                en este formulario es correcta y completa sin omitir ni falsear dato alguno que deba contener, siendo fiel expresión de la verdad. 
                                A su vez, presto expresa conformidad para que todas las notificaciones y/o comunicaciones que el CPCEPBA deba cursarme -referidas a 
                                la presente solicitud- se dirijan a la casilla de correo electrónico y/o teléfono celular/ fijo denunciado en el 
                                presente formulario, donde serán consideradas válidas todas las notificaciones que se me remitan. Dejo expresa constancia de que 
                                conozco y acepto los términos y condiciones establecidos por el CPCEPBA para la realización del presente trámite.
                            </b></Typography>
                        <FormGroup>
                            <FormControlLabel value={aceptoTerminosYCondiciones} control={<Checkbox />} onClick={value => setAceptoTerminosYCondiciones(value.target.checked)} label="Acepto los términos y condiciones" />
                        </FormGroup>
                        <Grid item md={12} className={clases.floatCenter} sx={{ mt: 2 }}>
                            <Button variant="contained" disabled={!aceptoTerminosYCondiciones} sx={{ mr: 2 }} onClick={handleCloseGuardar} color="secondary" type="submit" startIcon={<CheckIcon />}>
                                Aceptar
                            </Button>
                            <Button variant="contained" color="error" onClick={handleClose} type="submit" startIcon={<HighlightOffIcon />}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Modal>);
}

