import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tycAceptado: false,
};

const tycSlice = createSlice({
    name: 'tyc',
    initialState,
    reducers: {
        setAceptarTyc: (state) => {
            state.tycAceptado = true;
        },
        setDeclinarTyc: (state) => {
            state.tycAceptado = false;
        },
    },
});

export const { setAceptarTyc, setDeclinarTyc } = tycSlice.actions;

export default tycSlice.reducer;