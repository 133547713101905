import React, { useState, useEffect, Fragment } from 'react';
import logo from './../../Logos/Imagen_logo_cpba.jpg';
import { Page, Image, Text, Svg, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { tipoDocumentoList } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { Typography } from '@mui/material';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 30,
        paddingRight: 30,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    section_center: {
        backgroundColor: "#D9D8D8",
        marginLeft: 0,
        fontSize: 15,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 5,
        height: 65,
        width: '50%',
        border: '1'
    },
    section_center_text: {
        marginTop: 12,
        fontSize: 11,
        marginBottom: 1,
        justifyContent: "center",
        alignItems: "center",
        width: '90%'
    },
    section_right: {
        marginLeft: 0,
        fontSize: 15,
        paddingTop: 5,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 5,
        height: 65,
        width: '22%',
        border: '1'
    },
    section_left: {
        marginTop: 5,
        height: 65,
        width: '35%',
        border: 1
    },
    profile_container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20',
        marginBottom: '20px',
        height: '150',
        fontFamily: 'Helvetica-Bold',
    },
    name_text: {
        paddingTop: '10px',
        paddingBottom: '5px',
        fontSize: '14px',
        fontWeight: '900',
        color: 'white',
    },
    profession_text: {
        color: '#d1d5db',
        fontSize: '11px',
    },

    profile_img: {
        width: '60px',
        height: '60px',
        borderRadius: '90',
    },
    profile_line: {
        marginTop: '10px',
        width: '10%',
        height: '1px',
        backgroundColor: '#FFF',
        textAlign: 'center',
    },
    row: {
        flexDirection: 'row',
    },

    rowTable: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        fontStyle: 'bold',
    },
    viewer: {
        width: 800, //the pdf viewer will take up all of the width and height
        height: 800,
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
        borderColor: '#3778C2',
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        backgroundColor: '#3778C2',
        color: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 5,
    },

    nombres: {
        width: '20%',
        fontSize: 9,
        marginTop: 5,
        height: 20,
        borderRightWidth: 1,
    },
    parentesco: {
        width: '12%',//mostrar el parentesco con un filter
        fontSize: 9,
        borderRightWidth: 1,
    },
    sexo: {
        width: '6%',//mostrar el parentesco con un filter
        fontSize: 9,
        borderRightWidth: 1,
    },
    fecNacimiento: {
        width: '12%',
        fontSize: 9,
        borderRightWidth: 1,
    },
    documento: {
        width: '15%',
        fontSize: 9,
        borderRightWidth: 1,
    },
    cargo: {
        width: '9%',
        fontSize: 9,
        borderRightWidth: 1,
    },
    edad: {
        width: '8%',
        fontSize: 9,
        borderRightWidth: 1,
    },
    incapacitado: {
        width: '10%',
        fontSize: 8,
        borderRightWidth: 1,
    },
    obraSocial: {
        fontSize: 9,
        width: '18%'
    },
    nombresContenidoTabla: {
        width: '20%',
        fontSize: 8,
        textAlign: 'center',
        borderRightWidth: 1,
    },
    parentescoContenidoTabla: {
        width: '12%',//mostrar el parentesco con un filter
        textAlign: 'center',
        fontSize: 9,
        height: 18,
        borderRightWidth: 1,
    },
    sexoContenidoTabla: {
        width: '6%',//mostrar el parentesco con un filter
        textAlign: 'center',
        fontSize: 9,
        height: 18,
        borderRightWidth: 1,
    },
    fecNacimientoContenidoTabla: {
        width: '12%',
        fontSize: 9,
        height: 18,
        textAlign: 'center',
        borderRightWidth: 1,
    },
    documentoContenidoTabla: {
        width: '15%',
        fontSize: 8,
        height: 18,
        textAlign: 'center',
        borderRightWidth: 1,
    },
    cargoContenidoTabla: {
        width: '9%',
        fontSize: 9,
        height: 18,
        textAlign: 'center',
        borderRightWidth: 1,
    },
    edadContenidoTabla: {
        width: '8%',
        fontSize: 9,
        height: 18,
        textAlign: 'center',
        borderRightWidth: 1,
    },
    firmaProfesional: {
        position: 'absolute',
        fontSize: 12,
        bottom: 100,
        left: 200,
        right: 0
    },
    incapacitadoContenidoTabla: {
        width: '10%',
        fontSize: 9,
        height: 18,
        textAlign: 'center',
        borderRightWidth: 1,
    },
    obraSocialContenidoTabla: {
        textAlign: 'center',
        fontSize: 7,
        height: 18,
        width: '18%'
    }
});


export const Pdf = ({ datos, EstadoCivilList, TipoDocumentos, titulosProfesional, ObrasSociales, leyendas, Parentescos, titulosRR }) => {


    Font.register({
        family: "Oswald",
        src: `https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf`,
        fontWeight: 600
    });
    let idLegajo = localStorage.userType !== "1" ? localStorage.idLegajo : datos.LegProf;
    let legProfFormateado = idLegajo.slice(0, 5) + '/' + idLegajo.slice(5);
    const fechaHoy = new Date();
    fechaHoy.setDate(fechaHoy.getDate() + 1);
    const fechaManana = fechaHoy.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });

    // const TipoDocumentos = useSelector(tipoDocumentoList);

    const getDescripcionTipoDocumento = (tipoDocumento) => {
        const documento = TipoDocumentos.find(doc => doc.tipoDocumento === tipoDocumento);
        return documento ? documento.descripcion.trim() : '';
    };

    
    const getDescripcionParentesco = (parentesco) => {
        const parent = Parentescos.find(parent => parent.codParentesco === parentesco);
        return parent ? parent.descripcion.trim() : '';
    };

    const renderSwitch = param => {

        switch (param) {
            case 1:
                return 'Particular';
            case 2:
                return 'Profesional';
            case 3:
                return 'Constituido';
            case 4:
                return 'Correspondencia';
            case 5:
                return 'Real Caja';

        }
    }

    const convertDateFormat = (string) => {

        var info = string.split('-');
        return info[2] + '/' + info[1] + '/' + info[0];
    }

    const formatDate = (date) => {
        let formatted_date = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "/" + date.getFullYear()

        return formatted_date;
    }



    return (

        <Document>
            <Page size="LEGAL" style={styles.page}>


                {/* </Svg> */}
                <Fragment>
                    <View style={styles.row}>
                        <View style={styles.section_left}>
                            <Image src={logo} style={{ width: 130, height: 135, marginLeft: 18 }} />
                            {/* <Svg viewBox="0 0 240 40"> */}

                        </View>

                        <View style={styles.section_center}>
                            {/* <Text style={{ marginTop: 5, fontSize: 11, marginBottom: 1 }}>DECLARACIÓN JURADA DE INSCRIPCIÓN</Text>
                            <Text style={{ borderTop: 1, width: '256px', margin: 5 }}></Text>
                            <Text style={{ marginTop: 2, fontSize: 11 }}>JURAMENTO PROFESIONAL</Text> */}
                            <Text style={styles.section_center_text}>SOLICITUD DE {localStorage.tpoTramite === "7" ? "Reinscripción" : "Rehabilitación"} en la matrícula</Text>

                        </View>


                        <View style={styles.section_right}>
                            <Text style={{ fontSize: 10, padding: 0, marginBottom: 6 }}>F-PE-03-01 {"\n"} Rev. 00</Text>
                            <Text style={{ borderTop: 1, width: '114px', marginTop: 3, marginBottom: 8 }}></Text>
                            <Text style={{ fontSize: 11, marginBottom: 11 }}>Página 1 de 1</Text>
                        </View>
                    </View>
                </Fragment>

                <Fragment>
                    <View style={styles.row}>
                        <Text style={{ marginTop: 5, fontFamily: 'Oswald' }}>Sres. C.P.C.E.P.B.A <text style={{ color: "white" }}>{"".padEnd(110, "_")}</text><text >{formatDate(new Date())}</text></Text>
                        <Text> </Text>
                    </View>

                </Fragment>
                <Fragment>

                    <View style={styles.row}>

                        <Text style={{ fontSize: 11, marginTop: 3 }}>Quién subscribe, Dr/a {datos.Nombre + " " + datos.Apellido}, DNI {datos.NroDocumento},
                        {titulosRR.map((prof, index) => (
                            <text key={index}>
                                {" "}{prof.descrip.trim()}, inscripto/a al T: {prof.tomo} F: {prof.folio},
                            </text>
                        ))} Legajo: {legProfFormateado}, con estado {localStorage.tpoTramite === "7" ? "Cancelado" : "Suspendido"}, solicita se proceda a {localStorage.tpoTramite === "7" ? "reinscribir" : "rehabilitar"} {titulosRR.length > 1 ? "sus matrículas" : "su matrícula"} con fecha {fechaManana}.
                        {'\n'}
                        {/* {titulosRR.map((prof, index) => (
                            <text key={index}>
                                {" "}Título: {prof.descrip.trim()}, Tomo: {prof.tomo}, Folio: {prof.folio},
                                {index !== titulosRR.length - 1 && ','}
                            </text>
                        ))} */}


                            {/* {'\n'} */}
                            <text style={{ marginTop: 10, marginBottom: 3, fontFamily: 'Oswald' }}>DATOS PERSONALES Y DE CONTACTO:</text>
                            {'\n'}


                            Fecha de nacimiento: {datos.Nacimiento.split(" ")[0]} <text style={{ color: "white" }}>s </text>
                            CUIT/CUIL: {datos.CuitCuil} <text style={{ color: "white" }}>ss </text>
                            Nacionalidad: {datos.Nacionalidad} <text style={{ color: "white" }}>s </text>
                            Estado civil: {EstadoCivilList.filter(estadoCivil => estadoCivil.estadoCivil === datos.EstadoCivil)[0].descripcion.trim()} <text style={{ color: "white" }}>s </text>
                            Obra social: {datos.ObraSocial} <text style={{ color: "white" }}>s</text>
                        </Text>

                    </View>
                    <View style={styles.row}>
                        <Text>E-mail: <text style={{ color: "white" }}>a </text> <text style={{ fontFamily: 'Oswald' }}>{datos.Email}</text> <text style={{ color: "white" }}>a </text>
                            Celular: {(datos.domicilios[0].caracteristica !== null && datos.domicilios[0].caracteristica !== "") && "0" + datos.domicilios[0].caracteristica + "-"}{(datos.domicilios[0].celular !== null && datos.domicilios[0].celular !== "") && + datos.domicilios[0].celular + " "} <text style={{ color: "white" }}>a </text>
                        </Text>

                    </View>

                </Fragment>
                <Fragment>
                    <View style={styles.row}>

                        <Text style={{ fontFamily: 'Oswald' }}>Presta expresa conformidad para que todas las notificaciones 
                            y/o comunicaciones que el CPCEPBA deba cursar -referidas a la presente solicitud- se dirijan a 
                            la casilla de correo electrónico y/o teléfono celular denunciado en el presente formulario,
                            donde serán consideradas válidas todas las notificaciones que se remitan. </Text>
                    </View>
                </Fragment>
                <Fragment>
                    <View style={styles.row}>
                        <Text style={{ marginTop: 5, fontFamily: 'Oswald' }}>GRUPO  FAMILIAR (Cónyuge/ conviviente, hijo/s, curador, tutor)</Text>
                    </View>

                    <View style={styles.tableContainer}>
                        <View style={styles.container}>
                            <Text style={styles.nombres}>Apellidos y Nombres {"\n"}</Text>
                            <Text style={styles.sexo}>Sexo </Text>
                            <Text style={styles.parentesco}>Parentesco </Text>
                            <Text style={styles.fecNacimiento}>Fecha de  Nacimiento</Text>
                            <Text style={styles.documento}>Tipo y N°  {"\n"} de Documento</Text>
                            <Text style={styles.cargo}>A Cargo</Text>
                            <Text style={styles.edad}>Edad</Text>
                            <Text style={styles.incapacitado}>Incapacitado</Text>
                            <Text style={styles.obraSocial}>Obra Social</Text>
                        </View>
                        
                        {datos.familiares !== null && datos.familiares !== undefined ? datos.familiares.filter(item => item.estado !== 0).map((familiar, index) =>
                            <View key={index} style={styles.rowTable}>
                                <Text style={styles.nombresContenidoTabla}  >{familiar.apellido + '\n' + familiar.nombre} </Text>
                                <Text style={styles.sexoContenidoTabla}>{familiar.sexo === 0 ?  "F" : familiar.sexo === 1 ?  "M": "X"}</Text>
                                <Text style={styles.parentescoContenidoTabla}>{familiar.nombreParentesco}</Text>
                                <Text style={styles.fecNacimientoContenidoTabla}>{familiar.fechaNacimiento !== null && familiar.fechaNacimiento.split(' ')[0]}</Text>
                                <Text style={styles.documentoContenidoTabla}> {getDescripcionTipoDocumento(familiar.tipoDocumento)} {familiar.numeroDocumento}</Text>
                                <Text style={styles.cargoContenidoTabla}>{familiar.cargo === true && "SI" || familiar.cargo === false && "NO"}</Text>
                                <Text style={styles.edadContenidoTabla}>{familiar.edad}</Text>
                                <Text style={styles.incapacitadoContenidoTabla}>{familiar.incapacitado === true && "SI" || familiar.incapacitado === false && "NO"}</Text>
                                <Text style={styles.obraSocialContenidoTabla} >{familiar.obraSocial}</Text>
                            </View>
                        ) : <View style={styles.rowTable}>
                            <Text style={{ width: '100%' }}> </Text>
                        </View>}

                    </View>
                </Fragment>
                <Fragment>
                    <View style={styles.row}>
                        <Text style={{ marginTop: 5, fontWeight: 'bold', fontFamily: 'Oswald' }}>DOMICILIOS</Text>
                    </View>

                    {datos.domicilios.map((domicilio, index) =>
                        <Fragment key={index} >
                            <Text style={[styles.row, { fontFamily: 'Oswald', marginTop: 4, marginBottom: 2 }]}>  {(domicilio.TpoDomicilio !== undefined && domicilio.TpoDomicilio !== 0) &&
                                "Domicilio " + renderSwitch(domicilio.TpoDomicilio)
                            }
                                {
                                    (domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) &&
                                    "Domicilio " + renderSwitch(Number(index + 1))
                                }
                            </Text>
                            <Text style={{ fontSize: 9 }}>
                                Calle:  <text style={{ fontFamily: 'Oswald' }}>{domicilio.calle.replace("Calle", "") + " "}  </text><text style={{ color: "white" }}>s</text>
                                N°:<text style={{ fontFamily: 'Oswald' }}>{domicilio.numero + " "}</text><text style={{ color: "white" }}>s</text>
                                Piso: <text style={{ fontFamily: 'Oswald' }}>{domicilio.piso + " "}</text><text style={{ color: "white" }}></text>
                                Dpto.:<text style={{ fontFamily: 'Oswald' }}>{domicilio.depto + " "}</text><text style={{ color: "white" }}>s</text>
                                Código postal: <text style={{ fontFamily: 'Oswald' }}>{domicilio.codPostal + " "}   </text><text style={{ color: "white" }}></text>
                                Localidad:<text style={{ fontFamily: 'Oswald' }}>{domicilio.localidad + " "}</text><text style={{ color: "white" }}>eo</text>
                                Celular:<text style={{ fontFamily: 'Oswald' }}>{(domicilio.caracteristica !== null && domicilio.caracteristica !== "") && "0" + domicilio.caracteristica + "-"}{(domicilio.celular !== null && domicilio.celular !== "") && "15" + domicilio.celular + " "}</text><text style={{ color: "white" }}></text>
                                Teléfono Fijo:<text style={{ fontFamily: 'Oswald' }}>{(domicilio.caracteristicaFijo !== null && domicilio.caracteristicaFijo !== "" && domicilio.caracteristicaFijo !== undefined) && "0" + domicilio.caracteristicaFijo + "-"}{(domicilio.telefono !== null && domicilio.telefono !== "") && domicilio.telefono}</text><text style={{ color: "white" }}>s</text>
                                Provincia:  <text style={{ fontFamily: 'Oswald' }}>{domicilio.provincia === "Provincia de Buenos Aires" ? "Buenos Aires" : domicilio.provincia}</text>
                            </Text>

                        </Fragment>
                    )}

                </Fragment>

                <Fragment>
                    <View style={styles.row}>
                        <Text style={{ marginTop: 5, fontWeight: 'bold', fontFamily: 'Oswald' }}>DECLARA BAJO SU RESPONSABILIDAD</Text>
                    </View>
                    <View style={{ fontFamily: 'Oswald', marginTop: 1 }}>
                        <Text style={{ fontSize: 10}}>-- Que no se encuentra comprendido en ninguna de las causales previstas en el artículo 24 y en los incisos b y c del artículo 25 de la Ley 10620.</Text>
                        <Text style={{ fontSize: 10}}>-- Asimismo, manifiesta con carácter de declaración jurada, que la información consignada en este formulario es correcta y completa sin omitir ni falsear dato alguno que deba contener, siendo fiel expresión de la verdad.</Text>
                    </View>

                </Fragment>
                <Fragment>
                    <View fixed 
                        style={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            // justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >   
                        <div>
                            <Text style={{
                                marginTop: '30px'
                            }}>
                                <text> {"Dr/a "}  
                                { titulosProfesional.length > 0 &&
                                    
                                    titulosProfesional.map(tituloCargado => tituloCargado.abreviatura + " ")
                                }

                                {
                                    datos.Nombre + " " + datos.Apellido
                                }  
                                </text>
                            </Text>
                            <Text> 
                                {TipoDocumentos.filter(Documento => Documento.tipoDocumento === datos.TipoDocumento)[0].descripcion.trim() + " " + datos.NroDocumento + " "} 
                            </Text>
                        </div>
                    </View>

                </Fragment>

            </Page>


        </Document>
    )
};
