import React, { useState, useEffect, Fragment } from 'react';
import { Domicilios } from 'components/matriculas/domicilios';
import { Titulos } from 'components/matriculas/titulos'
import { GrupoFamiliar } from 'components/matriculas/grupoFamiliar';
import { Chat } from 'components/chat/chat';
import { ArchivosAdjuntos } from 'components/matriculas/archivosAdjuntos';
import DatosPersonales from 'components/matriculas/datosPersonales';
import { useSelector, useDispatch } from 'react-redux';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { Button, Tab, Grid, Box, Paper, Typography } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { matriculasList, getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';

import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';


import { EMPTY_FILE } from 'constants/icons';

// Styles
import 'scss/components/header.scss';
import { useForm } from 'react-hook-form';
import { isValid } from 'date-fns';
import { ESTADOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import { PagoBrick } from 'components/matriculas/pagoBrick';
import { PagoCompletado } from 'components/matriculas/pagoCompletado';
import { getDerechoInscripcion, getResultadoPagoDeTasa, getMontoDeTasa, getEstadoOperacion, getOperacion, obtenerCodigoQR } from 'app/features/Profesionales/profesionalesAPI';
import { PagoPendiente } from 'components/matriculas/pagoPendiente';
import { DerechoDeInscripcion } from 'components/matriculas/derechoDeInscripcion';
import { obtenerQrAsync, setCodigoQr } from 'app/features/CodigoQr/codigoQrSlice';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },

    ocultarBotonesContinuar: {

        '@media (min-width:780px)': {
            display: 'none'
        }
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }

}));

const libraries = ['places'];

const Matriculas = () => {
    const dispatch = useDispatch();
    const clases = useStyles();
    const [tabSelectedIndex, setTabSelected] = useState('1');
    const [Matriculas, setMatriculas] = useState([]);
    const [bloquearBtn, setBloquearBtn] = useState(false);
    const [mostrarDatos, setMostrarDatos] = useState(false);
    const [guardarDatosTemporales, setGuardarDatosTemporales] = useState(0);
    const [ocultarContenido, setOcultarContenido] = useState(false);
    const ObrasSociales = useSelector(obrasSocialesList);

    const [imprimirResultado, setImprimirResultado] = useState(0);

    // console.log(tabSelectedIndex)

    const [datos, setDatos] = useState({
        Nombre: '',
        Apellido: '',
        Nacimiento: '',
        NroDocumento: '',
        EstadoCivil: '',
        TipoDocumento: '',
        CuitCuil: '',
        sexo: null,
        Observado: false,
        TpoOsocial: '',
        PerteneceOtroConsejo: null,
        Nacionalidad: '',
        Email: localStorage.email,
        titulos: [],
        domicilios: [
            { tpoDomicilio: "1", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' , edito: false, tpoCarga:1},
            { tpoDomicilio: "2", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 },
            { tpoDomicilio: "4", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 },
            { tpoDomicilio: "3", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 },
            { tpoDomicilio: "5", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 }
        ],
        adjuntos: [
            { fileType: 'DNI', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Foto', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Pago Inscripción', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Certificado de libre sanción disciplinaria de otros consejos profesionales', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Declaración jurada de enfermedades y patologías preexistentes', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'Analítico', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true }

        ]
    });

    const [idEstado, setIdEstado] = useState(1);

    if (imprimirResultado === 0 && Matriculas.codigoError === 0) {
     
        //Sacar boton revisar inscripcion y agregar campo Observado cambiar nombre observaciones por revisar inscripcion lado matriculas
    //observado en true puede editar siempre y cuando este en estado 6
    //observado en false no puede editar
        if (Matriculas.mensaje !== "Legajo no encontrado!" && Matriculas.result.jsonDatos !== null) {

            const datosProfesional = JSON.parse(Matriculas.result.jsonDatos);
            setIdEstado(Matriculas.result.idEstado);
            if(Matriculas.result.idEstado === ESTADOS_TRAMITE.DRAFT){
                Swal.fire({
                    title: '',
                    html: 'Se recuerda que antes de comenzar a cargar la solicitud de inscripción  <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de inscripción detallados en el siguiente link:<a href="https://www.cpba.com.ar/component/k2/item/13820" target="_blank">clic aquí</a>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            }

            if (Matriculas.result.idEstado === ESTADOS_TRAMITE.PENDIENTE || Matriculas.result.idEstado === ESTADOS_TRAMITE.FINALIZADA ||  (Matriculas.result.idEstado === 6 && !datosProfesional.Observado) || localStorage.userType === "1") {
                setBloquearBtn(true);
            }

            let arrFiles = [];
            let analiticoTitulo = 0;

            
            if (Array.isArray(datosProfesional.adjuntos)) {

                datosProfesional.adjuntos.map((adj) => {


                    if (adj.fileType.replace(/\d/g, "").trim() === "DNI" || adj.fileType.replace(/\d/g, "").trim() === "Foto" || adj.fileType.replace(/\d/g, "").trim() === "Certificado"
                        || adj.fileType.replace(/\d/g, "").trim() === "Certificado de libre sanción disciplinaria de otros consejos profesionales" || adj.fileType.replace(/\d/g, "").trim() === "Declaracion Jurada"
                        || adj.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)"
                        || adj.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes"
                        || adj.fileType.replace(/\d/g, "").trim() === "Analítico" || adj.fileType.replace(/\d/g, "").trim() === "Pago Inscripción") {

                        if (adj.fileType === "Certificado") {
                            adj.fileType = "Certificado de libre sanción disciplinaria de otros consejos profesionales";
                        }

                        if (adj.fileType.replace(/\d/g, "").trim() === "Declaración jurada de enfermedades y patologías preexistentes (para mayores de  años)") {
                            adj.fileType = "Declaración jurada de enfermedades y patologías preexistentes";
                        }
                        if (adj.fileType.replace(/\d/g, "").trim() === "Analítico") {
                            analiticoTitulo = 1;
                        }

                        arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file !== null ? adj.file : '', size: adj.size, url: adj.url === "" ? EMPTY_FILE : adj.url, saved: true });
                    } else {

                        datosProfesional.titulos.map((tit) => {

                            // console.log(adj.fileType);
                            if ('Título - ' + tit.nombreTitulo.trim() === adj.fileType.replace(/\d/g, "").trim()) {


                                if (adj.url === "") {
                                    adj.url = EMPTY_FILE;
                                }

                                arrFiles.push({ fileType: adj.fileType, fileName: adj.fileName, extension: adj.extension, file: adj.file, size: adj.size, url: adj.url, saved: true });
                            } else if ('Título - ' + tit.nombreTitulo.trim() === adj.fileType) {

                            }
                        })
                    }

                })
                // alert(analiticoTitulo);
                if (analiticoTitulo === 0)
                    arrFiles.push({ fileType: 'Analítico', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true });
            } else {
                arrFiles = datos.adjuntos;
            }
           
            datosProfesional.titulos.map((tit) => (arrFiles.filter(c => c.fileType === 'Título - ' + tit.nombreTitulo.trim()).length < 1)
                && arrFiles.push({ fileType: 'Título - ' + tit.nombreTitulo.trim(), fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true }));

            let adjuntosOrdenados = 0;
            let adjuntosArray = [];
            let titulosTotales = 0;
            let dniTotales = 0;
            let fotoTotales = 0;
            let analiticoTotales = 0;
            let libreSancion = 0;
            let declaracionJuradaEnfermedadesTotales = 0;
            let pagoTotales = 0;
            //console.log(arrFiles)
            arrFiles.map((adj, index) => {

                if (adj.fileType.search('-') >= 0)
                    titulosTotales++;

                if (adj.fileType.indexOf('DNI') >= 0)
                    dniTotales++;

                if (adj.fileType.indexOf('Foto') >= 0)
                    fotoTotales++;

                if (adj.fileType.indexOf('Pago') >= 0)
                    pagoTotales++;

                if (adj.fileType.indexOf('Analítico') >= 0)
                    analiticoTotales++;

                if (adj.fileType.indexOf('enfermedades') >= 0)
                declaracionJuradaEnfermedadesTotales++;

                if (adj.fileType.indexOf('sanción') >= 0)
                    libreSancion++;

            });
            let dni = 0;
            for (let i = 0; i < arrFiles.length;) {
                arrFiles.map((adj, index) => {
                    
                    if (
                        (adjuntosOrdenados >= 0 && adjuntosOrdenados < dniTotales && adj.fileType.indexOf('DNI') >= 0) ||
                        (adjuntosOrdenados >= dniTotales  && adjuntosOrdenados < (dniTotales + fotoTotales) && adj.fileType.indexOf('Foto') >= 0) ||
                        (adjuntosOrdenados >= (dniTotales + fotoTotales) && adjuntosOrdenados < (dniTotales + fotoTotales + pagoTotales) && adj.fileType.indexOf('Pago') >= 0) ||
                        (adjuntosOrdenados >= (dniTotales + fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales+ fotoTotales + pagoTotales) && adj.fileType.search('-') >= 0) ||
                        (adjuntosOrdenados >= (titulosTotales + dniTotales+ fotoTotales + pagoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion) && adj.fileType.indexOf('sanción') >= 0) ||
                        (adjuntosOrdenados >= (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion) && adjuntosOrdenados < (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adj.fileType.indexOf('enfermedades') >= 0) ||
                        (adjuntosOrdenados >= (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales) && adjuntosOrdenados < (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales) && adj.fileType.indexOf('Analítico') >= 0) ||
                        (adjuntosOrdenados >= (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales  + analiticoTotales) && adjuntosOrdenados < (titulosTotales + dniTotales+ fotoTotales + pagoTotales + libreSancion + declaracionJuradaEnfermedadesTotales + analiticoTotales + 1) && adj.fileName === "DDJJ") && arrFiles.length > i) {
                          
                            adjuntosArray.push(adj);
                        adjuntosOrdenados++;
                        i++
                    }

                });
            }
          
            setDatos({
                ...datos,
                Nombre: datosProfesional.Nombre,
                Apellido: datosProfesional.Apellido,
                PerteneceOtroConsejo: datosProfesional.PerteneceOtroConsejo === undefined ? null : datosProfesional.PerteneceOtroConsejo,
                Nacimiento: datosProfesional.Nacimiento,
                NroDocumento: datosProfesional.NroDocumento,
                ObraSocial:datosProfesional.ObraSocial !== undefined && datosProfesional.ObraSocial !== null ? datosProfesional.ObraSocial : '',
                sexo: datosProfesional.sexo ? datosProfesional.sexo : 0,
                EstadoCivil: datosProfesional.EstadoCivil ? datosProfesional.EstadoCivil : '',
                TpoOsocial: datosProfesional.TpoOsocial,
                TipoDocumento: datosProfesional.TipoDocumento,
                CuitCuil: datosProfesional.CuitCuil,
                Observado: datosProfesional.Observado !== undefined && datosProfesional.Observado !== null ? datosProfesional.Observado: false,
                Nacionalidad: datosProfesional.Nacionalidad ? datosProfesional.Nacionalidad : '',
                Email: datosProfesional.Email ? datosProfesional.Email : localStorage.email,
                titulos: datosProfesional.titulos,
                familiares: datosProfesional.familiares,
                domicilios: Array.isArray(datosProfesional.domicilios) ? datosProfesional.domicilios : datos.domicilios,
                adjuntos: adjuntosArray,
                exentoTasa: datosProfesional.exentoTasa !== undefined ? datosProfesional.exentoTasa : true
            })
            //  console.log(datosProfesional.adjuntos);

            setTimeout(() => setMostrarDatos(true), 1000);

            //   setMostrarDatos(true);
            setImprimirResultado(1);
        } else if (Matriculas.codigoError !== null && imprimirResultado === 0) {
            // alert(Matriculas.codigoError);
            Swal.fire({
                title: '',
                html: 'Se recuerda que antes de comenzar a cargar la solicitud de inscripción  <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de inscripción detallados en el siguiente link:<a href="https://www.cpba.com.ar/component/k2/item/13820" target="_blank">clic aquí</a>',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText:
                    'Aceptar',
                cancelButtonText:
                    'Cancelar',
                allowOutsideClick: true
            });
            setImprimirResultado(1);
            setTimeout(() => setMostrarDatos(true), 1000);
        }

    }

    const [location, setLocation] = useState({
        lat: -62.7113387,
        lng: -95.00657030000001,
    });

    const handleChange = (event, newValue) => {
        if(tabSelectedIndex === "1"){

            const nacimientoValido = isValid(new Date(datos.Nacimiento));
            const nombreValido = datos.Nombre !== "";
            const apellidoValido = datos.Apellido !== "";
            const tipoDocumentoValido = datos.TipoDocumento !== "";
            const nroDocumentoValido = datos.NroDocumento !== "";
            const sexoValido = datos.sexo >= 0;
            const estadoCivilValido = datos.EstadoCivil !== "";
            const nacionalidadValido = datos.Nacionalidad !== "";
            const cuitValido = datos.CuitCuil !== "";
            const obraSocialValido = datos.ObraSocial !== "";

            const arrValidacion = [
                {campo: "Fecha de Nacimiento", valido: nacimientoValido}, 
                {campo: "Nombre", valido: nombreValido},
                {campo: "Apellido", valido: apellidoValido},
                {campo: "Tipo de Documento", valido: tipoDocumentoValido},
                {campo: "Número de Documento", valido: nroDocumentoValido},
                {campo: "Sexo", valido: sexoValido},
                {campo: "Estado Civil", valido: estadoCivilValido},
                {campo: "Nacionalidad", valido: nacionalidadValido},
                {campo: "CUIT", valido: cuitValido},
                {campo: "Obra Social", valido: obraSocialValido}
            ];



            if(idEstado != 2 && arrValidacion.map(a => a.valido).includes(false)){

                const camposInvalidos = arrValidacion.filter(a => a.valido === false).map(a => a.campo).join(", ");

                Swal.fire({
                    title: '',
                    html: 'Los siguientes campos son obligatorios: <br><b>' + camposInvalidos + '</b>',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    confirmButtonText:
                        'Aceptar',
                    cancelButtonText:
                        'Cancelar',
                    allowOutsideClick: true
                });
            }else{
                setTabSelected(newValue);
            }
        }else{
            setTabSelected(newValue);
        }
    };
    const [loaderBtnGuardar, setLoaderBtnGuardar] = useState(false);
    const [resultadoPagoTasa, setResult] = useState();
    // const [resultadoDerechoInscripcion, setResultadoDerechoInscripcion] = useState();
    const [montoDeTasa, setMontoDeTasa] = useState();
    const [estadoOperacion, setEstadoOperacion] = useState();
    const [medioDePago, setMedioDePago] = useState("0");

    const obtenerResultadoDePago = async() => {

       const data = await getResultadoPagoDeTasa();
        // console.log(resp)
        setResult(data.resultado);   
    }
    
    const obtenerEstadoOperacion = async() => {
        const data = await getEstadoOperacion();
        console.log(data)
        setEstadoOperacion(data)
    }

    const obtenerOperacion = async() => {
        
        const data = await getOperacion();
        if (data) {
            setEstadoOperacion(data.estado)
            setMontoDeTasa(data.monto)
        }
    }

    const obtenerQR = async() => {
        
        dispatch(obtenerQrAsync()).then((data) => {
            if(data.payload.qr === null || !data.payload.qr) {
                setMedioDePago("0")
            }else{
                dispatch(setCodigoQr(data.payload))
                setMedioDePago("2")
           }
        
        }).catch((error) => {
            dispatch(setCodigoQr({qr: null, tiempoVerificacion: null, vencimiento: null}))
            setMedioDePago("0")
        })

    }
    
    useEffect(() => {
        if (Matriculas.length === 0) {
            let Null = null;


            //llamo a la funcion del Slice
            dispatch(getMatriculasAsync({ Null, Null })).then(valor => setMatriculas(valor.payload));
        }
        
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        navigator.geolocation.getCurrentPosition(position => {
            // console.log(position);
            setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, [
        Matriculas
    ])

    useEffect(() => {
        obtenerResultadoDePago();   
    }, [])

    useEffect(() => {
        obtenerEstadoOperacion();   
    }, [])

    useEffect(() => {
        obtenerOperacion();   
    }, [Matriculas])

    useEffect(() => {
        obtenerQR();
    },[])
    
    
    if (localStorage.token === "") {

        window.location.href = `/login`;
    }

    const cambiarTab = (tabNuevo) => {
        if (tabNuevo === 1 && tabSelectedIndex !== "1") {

            let nuevoValor = String(Number(tabSelectedIndex) - 1);
            setTabSelected(nuevoValor);
        } else if (tabNuevo === 2 && tabSelectedIndex !== "6") {
            let nuevoValor = String(Number(tabSelectedIndex) + 1);
            setTabSelected(nuevoValor);
        }

    }
    
    const [ocultarTabs, setOcultarTabs] = useState({ display: '' });


    // console.dir(Matriculas);
    return (
        
        <Grid container >


            {mostrarDatos ?
                <Grid item xs={12} md={11}>

                    <TabContext value={tabSelectedIndex}>

                        <Box >
                            <Grid container style={ocultarTabs} >
                                <Grid item xs={2} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(1)}><ArrowBackIosIcon /></Button>
                                </Grid>
                                <Grid item md={12} xs={9}>
                                    <TabList onChange={handleChange} >

                                        <Tab label={<b>Datos Personales</b>} value="1" style={ocultarTabs} />
                                        <Tab label={<b>Grupo familiar primario</b>} value="2" style={ocultarTabs} />
                                        <Tab label={<b>Domicilios</b>} value="3" style={ocultarTabs} />
                                        <Tab label={<b>Título a inscribir</b>} value="4" style={ocultarTabs} />
                                        <Tab label={<b>Adjuntos</b>} value="5" style={ocultarTabs} />                                        
                                        { (!(Matriculas.result.exentoTasa == null? true : Matriculas.result.exentoTasa) || resultadoPagoTasa) && <Tab label ={<b>Derecho de Inscripción</b>} value="6" style={ocultarTabs} /> }

                                    </TabList>
                                </Grid>
                                <Grid item xs={1} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(2)}><ArrowForwardIosIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>

                        <Paper>
                            <TabPanel value="1">
                                <DatosPersonales datos={datos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="2"  >
                                <GrupoFamiliar datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="3" >
                                <Domicilios datos={datos} libraries={libraries} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} location={location} setLocation={setLocation} ValorTab={setTabSelected} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} editarDatos={0} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="4"  >
                                <Titulos datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} tab={tabSelectedIndex} loaderBtnGuardar={loaderBtnGuardar} idEstado={idEstado} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                            </TabPanel>
                            <TabPanel value="5" >
                                <ArchivosAdjuntos datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado}  ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} ValorTab={setTabSelected} setMatriculas={setMatriculas} />
                            </TabPanel>
                            { 
                                (!(Matriculas.result.exentoTasa == null? true : Matriculas.result.exentoTasa) || resultadoPagoTasa) &&
                                <TabPanel value="6" >
                                    <DerechoDeInscripcion datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion} setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago} medioDePago={medioDePago}/>
                                </TabPanel>
                            } 
                        </Paper>

                    </TabContext>

                </Grid>
                :
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                </Grid>
            }

            {((idEstado !== 1 &&  idEstado !== 7 && localStorage.userType !== "0")  || (localStorage.userType === "0" && datos.Observado === true  && idEstado !== 7) || (localStorage.userType === "1" && idEstado !== 7))
            && <Chat datos={datos} setDatos={setDatos} setIdEstado={setIdEstado}/>} 

        </Grid>


    );
};

export default Matriculas;
