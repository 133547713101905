import React, { useState, useEffect, useRef } from 'react';
// import { Chat } from 'components/chat/chat';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { OtrosDatos } from 'components/reinscripcionRehabilitacion/OtrosDatos';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tab, Grid, Box, Paper } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { getMatriculasAsync } from 'app/features/Matriculas/matriculasSlice';

import { obrasSocialesList, getObrasSocialesAsync } from 'app/features/TiposObrasSociales/tiposObrasSocialesSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { EMPTY_FILE } from 'constants/icons';

// Styles
import 'scss/components/header.scss';
import { ESTADOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import { getEstadoOperacion, getNroOperacion, getOperacion, getProfesionalLegajo, getResultadoPagoDeTasa, obtenerCarnetProf, obtenerEstadoProf } from 'app/features/Profesionales/profesionalesAPI';
import { obtenerQrAsync, setCodigoQr } from 'app/features/CodigoQr/codigoQrSlice';
import { DerechoDeInscripcion } from 'components/reinscripcionRehabilitacion/derechoDeInscripcion';
import { DatosMatricula } from 'components/reinscripcionRehabilitacion/DatosMatricula';
import { RR } from 'components/reinscripcionRehabilitacion/RR';
// import { obtenerProfConTitulos } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { ArchivosAdjuntos } from 'components/reinscripcionRehabilitacion/ArchivosAdjuntos';
import { agregarUnDiaFecha, compararArrays, compararObjetos, formatCuit, formatDate, formatDNI } from 'utils/utils';
import { obtenerTitulosRRAsync } from 'app/features/ReinscripcionRehabilitacion/titulosRRSlice';
import { fetchFotoProfesionalAsync, setCambiaFoto, setFotoNueva } from 'app/features/FotoProfesional/editarDatosProfesionalSlice';
import { ChatRR } from 'components/chat/chatRR';
import { getTipoDocumentoAsync, tipoDocumentoList } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { getParentescosAsync, parentescosList } from 'app/features/Parentescos/parentescosSlice';
import { getTipoDomicilioAsync, tipoDomicilioList } from 'app/features/TipoDomicilio/tipoDomicilioSlice';
import { cancelarTramite, enviarBeacon, obtenerTiempoDeEsperaTramite } from 'app/features/Matriculas/matriculasApi';
import { CANCELAR_TRAMITE, LINK } from 'constants/routes/endpoints';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    ocultarBotonesContinuar: {
        '@media (min-width:780px)': {
            display: 'none'
        }
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
}));

const libraries = ['places'];

const ReinscripcionRehabilitacion = () => {
    const dispatch = useDispatch();
    const clases = useStyles();
    const {pathname} = useLocation();
    const [tabSelectedIndex, setTabSelected] = useState('1');
    const [Matriculas, setMatriculas] = useState([]);
    const [bloquearBtn, setBloquearBtn] = useState(false);
    const [mostrarDatos, setMostrarDatos] = useState(false);
    const [guardarDatosTemporales, setGuardarDatosTemporales] = useState(0);
    const [ocultarContenido, setOcultarContenido] = useState(false);
    const ObrasSociales = useSelector(obrasSocialesList);
    const [idEstado, setIdEstado] = useState(1);
    const [imprimirResultado, setImprimirResultado] = useState(0);
    const [ocultarTabs, setOcultarTabs] = useState({ display: '' });
    const [cantidadTramites, setCantidadTramites] = useState(0);
    const [loaderBtnGuardar, setLoaderBtnGuardar] = useState(false);
    const [tpoUser, setTpoUser] = useState(false);
    const [tpoTramite, setTpoTramite] = useState(false);
    const [resultadoPagoTasa, setResult] = useState();
    // const [resultadoDerechoInscripcion, setResultadoDerechoInscripcion] = useState();
    const [montoDeTasa, setMontoDeTasa] = useState();
    const [estadoOperacion, setEstadoOperacion] = useState();
    const [medioDePago, setMedioDePago] = useState("0");
    const [datosMatricula, setDatosMatricula] = useState(null);
    const [datosFirma, setDatosFirma] = useState(null);
    const [estadoProf, setEstadoProf] = useState(null);
    const [datosModificados, setDatosModificados] = useState([]);
    const [datosDomicilioModificados, setDatosDomicilioModificados] = useState([]);
    const [datosFamiliarModificados, setDatosFamiliarModificados] = useState([]);
    const userType = Number(localStorage.userType);
    let idLegajo = 0;
    const Parentescos = useSelector(parentescosList);
    const TipoDocumentos = useSelector(tipoDocumentoList);
    const TipoDomicilios = useSelector(tipoDomicilioList);
    const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);
    const {tycAceptado} = useSelector(state => state.tyCReducer);
    const [fechaInscripcion, setFechaInscripcion] = useState(null);
    const [notificarTiempo, setNotificarTiempo] = useState(false);
    const [tiempoNotificacion, setTiempoNotificacion] = useState(null)
    
    
    const [datos, setDatos] = useState({
        Nombre: '',
        Apellido: '',
        NroDocumento: '',
        TipoDocumento: '',
        CuitCuil: '',
        Observado: false,
        PerteneceOtroConsejo: null,
        Nacionalidad: '',
        Email: localStorage.email,
        Celular: '',
        titulos: [],
        domicilios: [
            { tpoDomicilio: "1", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' , edito: false, tpoCarga:1},
            { tpoDomicilio: "2", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 },
            { tpoDomicilio: "4", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 },
            { tpoDomicilio: "3", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 },
            { tpoDomicilio: "5", localidad: '', calle: '', numero: '', piso: '', depto: '', delegacion: '', codPostal: '', partido: '', provincia: '', caracteristicaFijo: '', telefono: '', caracteristicaFijo: '', caracteristica: '', celular: '', codLocalidad : '' ,edito: false, tpoCarga:1 }
        ],
        adjuntos: [
            { fileType: 'DNI del Solicitante - Anverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
            { fileType: 'DNI del Solicitante - Reverso', fileName: '', extension: '', file: '', size: 0, url: EMPTY_FILE, saved: true },
        ]
    });

    const obtenerMatricula = async(legProf = null) => {
        (localStorage.userType === "1") ? idLegajo = legProf : idLegajo = localStorage.idLegajo
        const {data} = await getProfesionalLegajo({idLegajo: idLegajo, token: localStorage.token});
        setDatosMatricula({...data.result, nroDocumento: formatDNI(data.result.nroDocumento), cuitCuil: formatCuit(data.result.cuitCuil)});
    } 

    const obtenerEstadoProfesional = async(legProf = null) => {
        (localStorage.userType === "1") ? idLegajo = legProf : idLegajo = localStorage.idLegajo
        const resp = await obtenerEstadoProf(idLegajo);
        setEstadoProf(resp);
    }

    const obtenerFirma = async(legProf = null) => {
        (localStorage.userType === "1") ? idLegajo = legProf : idLegajo = localStorage.idLegajo
        const resp = await obtenerCarnetProf(idLegajo);
        setDatosFirma(resp.result.datosFirma);
    }

    const cargarDatosMatricula = () =>{
        if (imprimirResultado === 0 && Matriculas.codigoError === 0) {
            if (Matriculas.mensaje !== "Legajo no encontrado!" && Matriculas.result.jsonDatos !== null) {

                const datosProfesional = JSON.parse(Matriculas.result.jsonDatos);
                setIdEstado(Matriculas.result.idEstado);
                setTpoUser(Matriculas.result.tpoUsuario);
                setTpoTramite(Matriculas.result.tpoTramite )


                if(Matriculas.result.idEstado === 6 && datosProfesional.Observado){
                    const fechaHoy = new Date();
                    fechaHoy.setDate(fechaHoy.getDate() + 1);
                    setFechaInscripcion(fechaHoy.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }));
                } else {
                    const fechaInsc = Matriculas.result.fehaInscripcion ? Matriculas.result.fehaInscripcion : Matriculas.result.fechaInicioTramite;
                    // console.log(Matriculas.result.fehaInscripcion)
                    setFechaInscripcion(agregarUnDiaFecha(fechaInsc));
                }

                if( pathname === '/reinscripcionRehabilitacion'){
                    if(Matriculas.result.idEstado === ESTADOS_TRAMITE.DRAFT ){
                        Swal.fire({ 
                            title: '', html: `Se recuerda que antes de comenzar a cargar la solicitud <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de ${localStorage.tpoTramite === '7' ? 'reinscripción': 'rehabilitación'} detallados en el siguiente link:<br /> <a href="https://www.cpba.com.ar/component/k2/item/8690" target="_blank">clic aquí</a>` ,
                            icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                        });
                    }
                }

                if (Matriculas.result.idEstado === ESTADOS_TRAMITE.PENDIENTE || Matriculas.result.idEstado === ESTADOS_TRAMITE.FINALIZADA ||  (Matriculas.result.idEstado === 6 && !datosProfesional.Observado) || localStorage.userType === "1") {
                    setBloquearBtn(true);
                }

                const tieneFotoCargada = datosProfesional.adjuntos.find(a => a.fileType === "FotoPerfil");
                
                if(tieneFotoCargada) {
                    dispatch(setCambiaFoto(datosProfesional.cambiaFoto));
                    dispatch(setFotoNueva(tieneFotoCargada.url));
                }

                let arrFiles = [];
                let analiticoTitulo = 0;

                let validFileTypes;

                    validFileTypes = [
                        'DNI del Solicitante - Anverso',
                        'DNI del Solicitante - Reverso',
                        'Declaración Jurada',
                        'FotoPerfil',
                        'FotoPerfilFull'
                    ];            

                if (Array.isArray(datosProfesional.adjuntos)) {
                    datosProfesional.adjuntos.map((adj) => {
                        if (validFileTypes.includes(adj.fileType.replace(/\d/g, "").trim())) {
                            arrFiles.push({
                                fileType: adj.fileType,
                                fileName: adj.fileName,
                                extension: adj.extension,
                                file: adj.file !== null ? adj.file : '',
                                size: adj.size,
                                url: adj.url === "" ? EMPTY_FILE : adj.url,
                                saved: true
                            });
                        }
                    });
                } else {
                    arrFiles = datos.adjuntos;
                }

                // if (datosProfesional.familiares?.length > 0) {
                //     datosProfesional.familiares.map((fam) => {
                //         let fechaNacimiento = fam.fechaNacimiento.split(" ")[0];
                //         let [dia, mes, anio] = fechaNacimiento.split("/");
                //         fam.fechaNacimiento = `${dia}-${mes}-${anio}`;
                //     });
                // }

                setDatos({
                    ...datos,
                    Nombre: datosProfesional.Nombre,
                    Apellido: datosProfesional.Apellido,
                    PerteneceOtroConsejo: datosProfesional.PerteneceOtroConsejo === undefined ? null : datosProfesional.PerteneceOtroConsejo,
                    Nacimiento: datosProfesional.Nacimiento,
                    NroDocumento: datosProfesional.NroDocumento,
                    ObraSocial:datosProfesional.ObraSocial !== undefined && datosProfesional.ObraSocial !== null ? datosProfesional.ObraSocial : '',
                    sexo: datosProfesional.sexo ? datosProfesional.sexo : 0,
                    EstadoCivil: datosProfesional.EstadoCivil ? datosProfesional.EstadoCivil : '',
                    TpoOsocial: datosProfesional.TpoOsocial,
                    TipoDocumento: datosProfesional.TipoDocumento,
                    CuitCuil: datosProfesional.CuitCuil,
                    Observado: datosProfesional.Observado !== undefined && datosProfesional.Observado !== null ? datosProfesional.Observado: false,
                    Nacionalidad: datosProfesional.Nacionalidad ? datosProfesional.Nacionalidad : '',
                    Email: datosProfesional.Email ? datosProfesional.Email : "",
                    titulos: datosProfesional.titulos,
                    familiares: datosProfesional.familiares,
                    domicilios: Array.isArray(datosProfesional.domicilios) ? datosProfesional.domicilios : datos.domicilios,
                    exentoTasa: datosProfesional.exentoTasa !== undefined ? datosProfesional.exentoTasa : true,
                    adjuntos: arrFiles,
                    LegProf: (localStorage.userType !== "1") ? localStorage.idLegajo : String(datosProfesional.LegProf),
                    Celular: datosProfesional.Celular ? datosProfesional.Celular : '',
                    EmailRepetido: datosProfesional.EmailRepetido !== undefined ? datosProfesional.EmailRepetido : datosProfesional.Email !== undefined && datosProfesional.Email !== "null" ? datosProfesional.Email : localStorage.email,
                    // bajaFallecimiento: datosProfesional.bajaFallecimiento ? datosProfesional.bajaFallecimiento : [{ LegProf: '0', Nombre: '0', Apellido: '0', codParentesco: '', FecFallecido: '',TipoDocumento: 0, NroDocumento: '', Titulo: '', Tomo: 0 , Folio: 0, FecActa: '', NroActa: null, ActaTomo: null, ActaFolio: null, ActaAnio:null, ActaJurisdiccion: '', Sexo: '', TitulosFallecido: [] }],
                    CodEnt: datosProfesional.CodEnt ? datosProfesional.CodEnt : null,
                    TipoTramite: datosProfesional.CodEnt ? datosProfesional.CodEnt : null,
                    cambiaFoto: typeof datosProfesional.cambiaFoto === 'boolean' ? datosProfesional.cambiaFoto : null,
                    CodNacionalidad: datosProfesional.CodNacionalidad ? datosProfesional.CodNacionalidad : null
                })

                if (localStorage.userType === "1") {
                    obtenerFirma(datosProfesional.LegProf);
                    obtenerMatricula(datosProfesional.LegProf);
                    obtenerEstadoProfesional(datosProfesional.LegProf);
                }

                setTimeout(() => setMostrarDatos(true), 1000);
                setImprimirResultado(1);
            } else if (Matriculas.codigoError !== null && imprimirResultado === 0) {
                if( pathname === '/reinscripcionRehabilitacion'){
                    Swal.fire({ 
                title: '', html: `Se recuerda que antes de comenzar a cargar la solicitud <u><b>deberá poseer en formato JPG, PDF, JPEG o PNG</b></u> los documentos indicados en los requisitos de ${localStorage.tpoTramite === '7' ? 'reinscripción': 'rehabilitación'} detallados en el siguiente link:<br /> <a href="https://www.cpba.com.ar/component/k2/item/8690" target="_blank">clic aquí</a>`,
                        icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                    });
                }
                setTpoTramite(Number(localStorage.tpoTramite))
                setImprimirResultado(1);
                setTimeout(() => setMostrarDatos(true), 1000);
            }

        }
    }
    
    const [location, setLocation] = useState({
        lat: -62.7113387,
        lng: -95.00657030000001,
    });

    const handleChange = (event, newValue) => {
        if(tabSelectedIndex === "1"){

            const arrValidacion = []


            if(userType !== TIPOS_USUARIO.SECRETARIO_TECNICO && idEstado != 2 && arrValidacion.map(a => a.valido).includes(false)){

                const camposInvalidos = arrValidacion.filter(a => a.valido === false).map(a => a.campo).join(", ");
                Swal.fire({ title: '', html: 'Los siguientes campos son obligatorios: <br><b>' + camposInvalidos + '</b>', 
                    icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', 
                    cancelButtonText: 'Cancelar', allowOutsideClick: true});
            }else{
                setTabSelected(newValue);
            }
        }else{
            setTabSelected(newValue);
        }
    };
    
    if (localStorage.token === "") {
        window.location.href = `/login`;
    }

    const cambiarTab = (tabNuevo) => {
        if (tabNuevo === 1 && tabSelectedIndex !== "1") {
            let nuevoValor = String(Number(tabSelectedIndex) - 1);
            setTabSelected(nuevoValor);
        } else if (tabNuevo === 2 && tabSelectedIndex !== "6") {
            let nuevoValor = String(Number(tabSelectedIndex) + 1);
            setTabSelected(nuevoValor);
        }
    }

    
    const obtenerResultadoDePago = async() => {

        const data = await getResultadoPagoDeTasa();
        setResult(data.resultado);
    }
     
    const obtenerEstadoOperacion = async() => {
        const data = await getEstadoOperacion();
        setEstadoOperacion(data)
    }
 
     const obtenerNroOperacion = async() => {
         
        const data = await getNroOperacion();
         if (data) {
            setEstadoOperacion(data.estado)
            setMontoDeTasa(data.monto)
        }
    }
 
    const obtenerQR = async() => {
         
        dispatch(obtenerQrAsync()).then((data) => {
            if(data.payload.qr === null || !data.payload.qr) {
                setMedioDePago("0")
            }else{
                dispatch(setCodigoQr(data.payload))
                setMedioDePago("2")
        }
        
        }).catch((error) => {
            dispatch(setCodigoQr({qr: null, tiempoVerificacion: null, vencimiento: null}))
            setMedioDePago("0")
        })
 
    }

    const cargarFoto = () =>{
        const adjuntoFotoNueva = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
        dispatch(setFotoNueva(adjuntoFotoNueva.url));
    }

    const comparador = (tipoDeArray) => {
        if( tipoDeArray === "datos"){
            const diferencias = compararObjetos(datos, datosMatricula);
            setDatosModificados([...diferencias])
        }
        if( tipoDeArray === "domicilios"){
            const diferencias = compararArrays(datos.domicilios, datosMatricula.domicilios);
            setDatosDomicilioModificados([...diferencias])
        }
        if( tipoDeArray === "familiares"){
            const diferencias = compararArrays(datos.familiares, datosMatricula.familiares);
            setDatosFamiliarModificados([...diferencias])
        }
    }

    const intervalIdRef = useRef(null);
    const timeoutIdRef = useRef(null);

    const limpiarNotificaciones = () => {
        clearInterval(intervalIdRef.current);
        clearTimeout(timeoutIdRef.current);
    };

    const obtenerTiempoNotificacion = async() => {
        const resp = await obtenerTiempoDeEsperaTramite();
        setTiempoNotificacion(resp)
        contadorNotificacion(resp)
    }

    const contadorNotificacion = (minutos) => {

        clearInterval(intervalIdRef.current);
        clearTimeout(timeoutIdRef.current);
    
        intervalIdRef.current = setInterval(() => {
            timeoutIdRef.current = setTimeout(() => {
                Swal.close();
                cancelarTramite();
                localStorage.clear();
                window.location.href = LINK;
            }, 2 * 60 * 1000); 
    
            Swal.fire({
                title: '',
                html: `Su sesión está por expirar.  <br> ¿Desea continuar con la solicitud?`,
                icon: 'warning',
                showCloseButton: false,
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false,
                customClass: {
                    popup: clases.swalCustomClass,
                }
            }).then((result) => {
                clearTimeout(timeoutIdRef.current); 
                // if (result.dismiss === Swal.DismissReason.cancel) {
                //     cancelarTramite();
                //     localStorage.clear();
                //     window.location.href = LINK;
                // }
            });
    
        }, minutos * 60 * 1000); 
    };
     
    useEffect(() => {
        if (Matriculas.length === 0) {
            let Null = null;
            //llamo a la funcion del Slice
            dispatch(getMatriculasAsync({ Null, Null })).then(valor => {
                setMatriculas(valor.payload)
                
            });
        }
        
        if (ObrasSociales.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getObrasSocialesAsync());
        }

        navigator.geolocation.getCurrentPosition(position => {
            setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
        cargarDatosMatricula();
    }, [
        Matriculas
    ])
 
    useEffect(() => {
        obtenerResultadoDePago();   
    }, [])
 
    useEffect(() => {
        obtenerEstadoOperacion();   
    }, [])

    useEffect(() => {
        obtenerNroOperacion();   
    }, [Matriculas])

    useEffect(() => {
        obtenerQR();
    },[])

    useEffect(() => {
        if (Matriculas.length === 0) {
            let Null = null;
            dispatch(getMatriculasAsync({ Null, Null })).then(valor => {
                setMatriculas(valor.payload)
                // localStorage.setItem('usuario', `${valor.payload.apellido} ${valor.payload.nombre}`);
            });
        }
    }, [ Matriculas ])

    useEffect(() => {
        if(localStorage.userType !== "1"){
            obtenerMatricula();
        }
    }, [])

    useEffect(() => {
        if(localStorage.userType !== "1"){
            obtenerFirma();
        }
    }, [])

    useEffect(() => {
        if(localStorage.userType !== "1"){
            obtenerEstadoProfesional();
        }
    }, [])

    useEffect(() => {

        let legajo = datos.LegProf;

        if(datos?.LegProf === undefined){
            legajo = localStorage.idLegajo
        }

        dispatch(obtenerTitulosRRAsync(legajo))
    }, [Matriculas])
    
    useEffect(() => {

        if(Matriculas.hasOwnProperty('resultado')){
            let legajo = 0;

            localStorage.userType !== "1" ? legajo = localStorage.idLegajo : legajo = datos.LegProf  
            
            dispatch(fetchFotoProfesionalAsync(legajo));
        }
    }, [Matriculas]);

    useEffect(() => {
        if (localStorage.userType === "1" && datosMatricula !== null && datos.Nombre !== '') {
            comparador("datos");
        }
    }, [datos, datosMatricula]);

    useEffect(() => {
        if (localStorage.userType === "1" && datosMatricula !== null && datos.Nombre !== '') {
            comparador("domicilios");
        }
    }, [datos, datosMatricula]);

    useEffect(() => {
        if (localStorage.userType === "1" && datosMatricula !== null && datos.Nombre !== '') {
            comparador("familiares");
        }
    }, [datos, datosMatricula]);
      
    useEffect(() => {
        if(datos.cambiaFoto && datos.Nombre !== '' ) {
            cargarFoto();
        }
    }, []);
    
    useEffect(() => {
        if (TipoDocumentos.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getTipoDocumentoAsync());
        }
        
        if (Parentescos.length === 0) {
            
            //llamo a la funcion del Slice
            dispatch(getParentescosAsync());
        }
        
        if (TipoDomicilios.length === 0) {
      
            //llamo a la funcion del Slice
            dispatch(getTipoDomicilioAsync());
        }
    }, []);
    
    useEffect(() => {
        if(datos.cambiaFoto !== null){
            dispatch(setCambiaFoto(datos.cambiaFoto));
        }
    }, [Matriculas])
    
    useEffect(() => {
        if(userType === TIPOS_USUARIO.PROFESIONAL && bloquearBtn) {
            setTabSelected('4')
        }
    }, [Matriculas])
        
    useEffect(() => {
        if(resultadoPagoTasa === true) {
            limpiarNotificaciones();
        } else if( userType === 0 && idEstado === 1) {
            obtenerTiempoNotificacion();
        }
    }, [resultadoPagoTasa])
    
    return (
        <Grid container >
            {mostrarDatos ?
                <Grid item md={12}>
                    <TabContext value={tabSelectedIndex}>
                        <Box >
                            <Grid container style={ocultarTabs} >
                                <Grid item xs={2} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(1)}><ArrowBackIosIcon /></Button>
                                </Grid>
                                <Grid item md={12} xs={9}>
                                    <TabList onChange={handleChange} >
                                        <Tab label={<b>Datos de Matrícula</b>} value="1" style={ocultarTabs} />
                                        <Tab label={<b>{tpoTramite === 7 ? "Reinscripción" : "Rehabilitación"}</b>} value="2" style={ocultarTabs} />
                                        <Tab label={<b>Otros Datos</b>} value="3" style={ocultarTabs} />
                                        <Tab label={<b>Adjuntos</b>} value="4" style={ocultarTabs} />
                                        { (idEstado !== 1 || (idEstado === 1 && tycAceptado)) && <Tab label={<b>Medio de pago</b>} value="5" style={ocultarTabs} /> }
                                    </TabList>
                                </Grid>
                                <Grid item xs={1} className={clases.ocultarBotonesContinuar}>
                                    <Button onClick={() => cambiarTab(2)}><ArrowForwardIosIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Paper>
                            <TabPanel value="1" >
                                <DatosMatricula  datos={datos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setTabSelected} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} datosMatricula={datosMatricula} datosFirma={datosFirma} tpoTramite={tpoTramite} estadoProf={estadoProf}/>
                            </TabPanel>
                            <TabPanel value="2"  >
                                <RR datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} setMatriculas={setMatriculas} tpoTramite={tpoTramite} datosModificados={datosModificados} fechaInscripcion={fechaInscripcion}/>
                            </TabPanel>
                            <TabPanel value="3" >
                                <OtrosDatos datos={datos} libraries={libraries} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setTabSelected} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} location={location} setLocation={setLocation} datosModificados={datosModificados} datosDomicilioModificados={datosDomicilioModificados} datosFamiliarModificados={datosFamiliarModificados}/>
                            </TabPanel>
                            
                            <TabPanel value="4" >
                                <ArchivosAdjuntos datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado}  ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} ValorTab={setTabSelected} setMatriculas={setMatriculas} tpoUser={tpoUser} tpoTramite={tpoTramite}/>
                            </TabPanel>
                            
                            <TabPanel value="5" >
                                <DerechoDeInscripcion datos={datos} setDatos={setDatos} titulosProfesional={[]} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setTabSelected} setOcultarTabs={setOcultarTabs} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} idEstado={idEstado} setIdEstado={setIdEstado} ocultarContenido={ocultarContenido} setOcultarContenido={setOcultarContenido} resultadoPagoTasa={resultadoPagoTasa}  estadoOperacion={estadoOperacion} obtenerResultadoDePago={obtenerResultadoDePago} setEstadoOperacion={setEstadoOperacion} setResult={setResult} montoDeTasa={montoDeTasa} setMedioDePago={setMedioDePago} medioDePago={medioDePago} limpiarNotificaciones={limpiarNotificaciones}/>
                            </TabPanel>
                        </Paper>
                    </TabContext>
                </Grid>
                :
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </div>
                </Grid>
            }
            {((idEstado !== 1 )) && 
            <ChatRR datos={datos} setDatos={setDatos} setIdEstado={setIdEstado} tpoTramite={tpoTramite} idEstado={idEstado}/>} 
        </Grid>
    );
}

export default ReinscripcionRehabilitacion;