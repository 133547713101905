import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardContent, Grid, Fab, Typography, Box, Paper, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { makeStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
    floatRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    centrarContenido: {
        display: 'flex',
        justifyContent: 'center'
    },
    backGroundBox: {
        backgroundColor: '#00b347!important'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    mapContainer: {
        height: '100vh', width: '100%'
    },
    mapElements: {
        height: '100%'
    },
    tabPanelContainer: {
        width: '100%', 
        overflow: 'hidden' 
    },
    tabPanel: {
        width: '100%'        
    },
    "@media (max-width: 910px)": {
        ocultarBotonEditar: {
            display: "none"
        },
        mostrarBotonEditarCelular: {
            display: "",
            marginLeft: "10px!important"
        }
    },
    "@media (min-width: 910px)": {
        mostrarBotonEditarCelular: {
            display: "none"
        },
        ocultarBotonEditar: {
            display: ""
        },
    }
}));

export const DomiciliosUsuarioRR = ({ datos, bloquearBtn, setTituloModal, setOpen, setTipoDomicilio, setDatosDocimilio, bloquearBtnEdit, setErrorTelefono, tipoCalleSelect }) => {
    const clases = useStyles();
    const [tabSelectedIndex, setTabSelectedIndex] = useState('0');

    const handleOpen = TpoDomicilio => {
        TpoDomicilio = Number(TpoDomicilio);
        setTituloModal(renderSwitch(TpoDomicilio));
        let paso = 0;

        datos.domicilios.map((domicilio, index) => {
            if ((domicilio.TpoDomicilio === TpoDomicilio || domicilio.TpoDomicilio === undefined || domicilio.TpoDomicilio === 0) && paso === 0) {
                setDatosDocimilio({
                    TpoDomicilio: TpoDomicilio,
                    localidad: domicilio.localidad !== null ? domicilio.localidad : '',
                    codLocalidad: domicilio.codLocalidad !== undefined ? domicilio.codLocalidad : '',
                    calle: domicilio.calle,
                    numero: domicilio.numero.trim(),
                    piso: domicilio.piso.trim(),
                    depto: domicilio.depto.trim(),
                    tpoCalle: domicilio.tpoCalle !== undefined ? domicilio.tpoCalle : 0,
                    delegacion: domicilio.delegacion !== null ? domicilio.delegacion : '',
                    codPostal: domicilio.codPostal,
                    partido: domicilio.partido !== null ? domicilio.partido : '',
                    provincia: domicilio.provincia !== null ? domicilio.provincia : '',
                    telefono: domicilio.telefono.trim(),
                    caracteristica: domicilio.caracteristica !== null ? domicilio.caracteristica : '',
                    caracteristicaFijo: domicilio.caracteristicaFijo !== null ? domicilio.caracteristicaFijo : '',
                    celular: domicilio.celular.trim(),
                    tpoCarga: 1 
                });

                paso = 1;
                return setDatosDocimilio;
            }
        });
        setErrorTelefono({ display: "none" });
        setOpen(true);
        setTipoDomicilio(Number(TpoDomicilio));
    };

    if (datos.domicilios[0].TpoDomicilio !== null && datos.domicilios[0].TpoDomicilio !== undefined && datos.domicilios[0].TpoDomicilio !== 0) {
        datos.domicilios.sort(function (a, b) {
            if (a.TpoDomicilio === null) {
            } else {
                if (a.TpoDomicilio > b.TpoDomicilio) {
                    return 1;
                }
                if (a.TpoDomicilio < b.TpoDomicilio) {
                    return -1;
                }
            }
            return 0;
        });
    }

    let estilo = {};
    const renderSwitch = param => {
        switch (param) {
            case 1:
                estilo = { marginRight: '-30%', marginLeft: "22%" };
                return 'Particular';
            case 2:
                estilo = { marginRight: '-22%', marginLeft: "22%" };
                return 'Profesional';
            case 3:
                estilo = { marginRight: '-20%', marginLeft: "25%" };
                return 'Constituido';
            case 4:
                estilo = { marginRight: '-25%', marginLeft: "12%" };
                return 'Correspondencia';
            case 5:
                estilo = { marginRight: '-25%', marginLeft: "25%" };
                return 'Real Caja';
        }
    };

    const handleChange = (event, newValue) => {
        setTabSelectedIndex(newValue);
    };

    return (
        <Fragment>
            <TabContext value={tabSelectedIndex}>
                <Box>
                    <Grid container>
                        <Grid item md={12} xs={9}>
                            <TabList onChange={handleChange}>
                                {datos.domicilios.map((domicilio, index) => (
                                    <Tab key={index} label={<b>{renderSwitch(domicilio.tpoDomicilio || domicilio.TpoDomicilio)}</b>} value={String(index)} />
                                ))}
                            </TabList>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={clases.tabPanelContainer}>
                    {datos.domicilios.map((domicilio, index) => (
                        <TabPanel key={index} value={String(index)} className={clases.tabPanel}>
                            <Grid item md={12} xs={12}>
                                <Card sx={{ boxShadow: 5, background: '#e4f0ec', borderColor: '#b8daba', borderRadius: '5px', border: '3px solid #b8daba' }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Provincia: <b>{domicilio.provincia}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Localidad: <b>{domicilio.localidad}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Calle: <b>{domicilio.calle} {domicilio.numero}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Tipo: <b>{domicilio.tpoCalle !== undefined && domicilio.tpoCalle !== 0 ? tipoCalleSelect.filter(e => e.value === domicilio.tpoCalle)[0].label : ""}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Piso: <b>{domicilio.piso}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Dpto.: <b>{domicilio.depto}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Delegación: <b>{domicilio.delegacion}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Cod. Postal: <b>{domicilio.codPostal}</b>
                                            </Grid>
                                            <Grid item md={3} sx={{ mb: 2 }}>
                                                Partido: <b>{domicilio.partido}</b>
                                            </Grid>
                                            <Grid item md={3}>
                                                Fijo: <b>{(domicilio.caracteristicaFijo !== null && domicilio.caracteristicaFijo !== "" && domicilio.caracteristicaFijo !== undefined) && "(0" + domicilio.caracteristicaFijo + ")"}{(domicilio.telefono !== null && domicilio.telefono !== "") && domicilio.telefono}</b>
                                            </Grid>
                                            <Grid item md={3}>
                                                Celular: <b>{(domicilio.caracteristica !== null && domicilio.caracteristica !== "") && "(0" + domicilio.caracteristica + ")"}{(domicilio.celular !== null && domicilio.celular !== "") && "15-" + domicilio.celular}</b>
                                            </Grid>
                                            {(localStorage.userType !== "1" && (bloquearBtn !== true)) &&
                                                <Grid sx={{ position: 'absolute', right: '22%' }}>
                                                    <Fab size="small" color="secondary" aria-label="edit" onClick={() => handleOpen(Number(index + 1))}>
                                                        <EditIcon />
                                                    </Fab>
                                                </Grid>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </TabPanel>
                    ))}
                </Box>
            </TabContext>
        </Fragment>
    );
}