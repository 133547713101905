import React, { useEffect } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, TextField, Typography, Box, FormControl, FormLabel, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import MiniaturaFotoNueva from 'components/editarDatosPersonales/MiniaturaFotoNueva';
import { useDispatch, useSelector } from 'react-redux';
import ModalNuevaFoto from 'components/editarDatosPersonales/ModalNuevaFoto';
import { ESTADOS_TRAMITE, TIPOS_USUARIO } from 'constants/constants';
import { clearFotoNueva, fetchFotoProfesionalAsync, setCambiaFoto, setFotoNueva, setFotoNuevaFullSize } from 'app/features/FotoProfesional/editarDatosProfesionalSlice';
import { formatDate, isNullOrEmpty } from 'utils/utils';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { nuevoAdjunto } from 'utils/adjuntosLogic';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

export const RR = ({ datos, bloquearBtn, setDatos, setValue, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setIdEstado, setMatriculas, tpoTramite, datosModificados, fechaInscripcion }) => {

  const { handleSubmit, formState: { errors }, getValues, watch, register, trigger, setError, } = useForm({
    defaultValues: {
      Email: datos.Email ? datos.Email : '',
      EmailRepetido: datos.EmailRepetido ? datos.EmailRepetido : ''
    }
  });

  const dispatch = useDispatch();
  const [openNuevaFoto, setOpenNuevaFoto] = React.useState(false);
  const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);
  const tipoUsuario = localStorage.userType;
  const fechaHoy = new Date();
  fechaHoy.setDate(fechaHoy.getDate() + 1);
  const fechaManana = fechaHoy.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatos({ ...datos, [name]: value });
  }
  
  const hiddenFileInput = React.useRef(null);

  const isEditionDisabled = () => {
    const observado = datos.Observado;
    const isProfesional = localStorage.userType == TIPOS_USUARIO.PROFESIONAL;
    return !isProfesional || (idEstado !== ESTADOS_TRAMITE.DRAFT && !observado);
  }

  const handleImageChange = (e) => {
    // cambiaFoto
    let cambiafoto
    // setDatos({
    //   ...datos,
    //   cambiaFoto: 
    // })
  }
  

  const mostrarAlertaTipoArchivos = () => {
    Swal.fire({ title: 'Atención', html: ` Solo se permiten archivos .jpg, .jpeg y .png  `, showCloseButton: true, confirmButtonText: 'Aceptar', allowOutsideClick: true, showCancelButton: true, cancelButtonText: 'Cancelar'})
    .then((result) => {
        if (result.isConfirmed) {
          hiddenFileInput.current.click();
        }
    })
  }

  const handlecambiaFoto = (event, newcambiaFoto) => {
    dispatch(setCambiaFoto(newcambiaFoto));
    if(event.target.innerText === "NO"){
      dispatch(clearFotoNueva());
      datos.adjuntos = datos.adjuntos.filter(a => a.fileType !== "FotoPerfil");
    }
    setDatos({
      ...datos,
      cambiaFoto: newcambiaFoto
    })
  };

  const changeHandler = (evt) => {

    if (evt.target.files.length >= 1) {
      const tipo = evt.target.files[0].type;
      const size = ((evt.target.files[0].size / 1000) / 1000).toFixed(4);

      if (tipo !== 'image/jpeg' && tipo !== 'image/png') {
        Swal.fire({title: 'Atención', html: 'El tipo de archivo no es correcto, se admite unicamente: jpg, jpeg o png.', icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText:'Cancelar', allowOutsideClick: true})
        return false;
      }
      else if ((Number(size) > 5)) {
        Swal.fire({title: 'Atención', html: 'El tamaño del archivo  no debe superar 5MB.', icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true})
        return false;
      }
      else {
        const foto = evt.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          dispatch(setFotoNuevaFullSize(reader.result));
          setOpenNuevaFoto(true);
        }
        reader.readAsDataURL(foto);
      }
    }
  }

  const onSubmit = async (data) => {
    if (isNullOrEmpty(editarFotoSlice.deseaCambiarFoto)) {
      Swal.fire({ title: 'Atención', html: 'Debe responder si desea cambiar su Foto', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
      return false;
    }

    if (editarFotoSlice.deseaCambiarFoto && isNullOrEmpty(editarFotoSlice.fotoNueva)) {
      Swal.fire({ title: 'Atención', html: 'Debe cargar una nueva foto', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
      return false;
    }

    if(!editarFotoSlice.fotoActual && !editarFotoSlice.fotoNueva){
      Swal.fire({ title: 'Atención', html: 'Debe cargar una foto actual', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
      return false;
    }

    setDatos({
      ...datos,
      cambiaFoto: editarFotoSlice.deseaCambiarFoto
    })

    setLoaderBtnGuardar(true);
    setBloquearBtn(true);
    const isValid = await trigger(['Email', 'EmailRepetido']);
    if (isValid) {
      if (editarFotoSlice.deseaCambiarFoto) {
        let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
        if (adjuntoDeFoto) {
          adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
        } else {
          adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
          datos.adjuntos.push(adjuntoDeFoto);
        }
        let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
        if (adjuntoDeFotoFull) {
          adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
        } else {
          adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
          datos.adjuntos.push(adjuntoDeFotoFull);
        }
      }
      datos.cambiaFoto = editarFotoSlice.deseaCambiarFoto;
      datos.cambiaDNI = editarFotoSlice.cambiaDNI;

      guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: Number(localStorage.tpoTramite) }).then(value => {
        setLoaderBtnGuardar(false);
        setBloquearBtn(false);
        if (value.data.resultado) {
          setValue("3");
          // setLegajoTemporal(value.data.result.idLegajoTemporal)
          localStorage.setItem('idLegajoTemporal', value.data.result.idLegajoTemporal);
        } else {
          Swal.fire({ title: 'Atención', html: 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo', icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true})
          return false;
        }
      });
    } else {
      setLoaderBtnGuardar(false);
      setBloquearBtn(false);
      setError('form', { type: 'manual', message: 'Please correct the errors before submitting.' });
    }

  }

  // useEffect(() => {
  //   let legajo = localStorage.idLegajo;
  //   dispatch(fetchFotoProfesionalAsync(legajo));
  // }, []);

  return (
    <Grid container>
      <Card sx={{ boxShadow: 5, background: '#e4f0ec', width: '90%',margin:'auto', borderColor: '#b8daba', borderRadius: '5px', border: '3px solid #b8daba'}}>
        <CardContent>
          <Grid item display='flex' alignItems='center'>
            <Typography variant="h6" component="span" textAlign='center' width='100vw' mr={2}> Fecha de {tpoTramite === 7 ? "Reinscripción:" : "Rehabilitación:"} <b>{fechaInscripcion === null ? fechaManana : fechaInscripcion}</b> </Typography>
          </Grid>
        </CardContent>
      </Card>
      <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
        <Card sx={{ boxShadow: 5, background: '#e4f0ec', borderColor: '#b8daba',width: '90%',margin:'auto', borderRadius: '5px', border: '3px solid #b8daba', mt: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={11} display='flex'>
                <Typography fontWeight={"bold"}>Completar su email para continuar</Typography>
                {
                    (datosModificados.length > 0 && datosModificados.includes('Email')) &&
                    <Tooltip
                        title={`Se han modificado los siguiente dato: Email` }
                        placement="right"
                    >
                        <WarningAmberOutlinedIcon sx={{ color: 'red !important', ml: 1}}/>
                    </Tooltip>
                }
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
              <Typography fontWeight={'bold'} fontSize={"12px"}>Email</Typography>
                <TextField
                  fullWidth
                  // label="Email"
                  placeholder='Ingrese su email'
                  name="Email"
                  variant={bloquearBtn === true ? "filled" : "outlined"}
                  error={errors.Email ? true : false}
                  helperText={errors.Email ? errors.Email.message : ''}
                  FormHelperTextProps={{ sx:{backgroundColor: errors.Email ? '#e4f0ec' : 'inherit', margin: '0px 0px 0px 0px'}}}
                  {...register('Email', {
                    required: { value: true, message: 'Email es requerido' },
                    pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, message: 'Email invalido' }
                  })}
                  value={datos.Email}
                  onChange={handleInputChange}
                  sx={{backgroundColor: 'white'}}
                  disabled={bloquearBtn }
                />
              </Grid>
              <Grid item xs={5}>
              
              <Typography fontWeight={'bold'} fontSize={"12px"}>Repetir Email</Typography>
                <TextField
                  fullWidth
                  // label="Repetir Email"
                  placeholder='Ingrese su email'
                  name="EmailRepetido"
                  variant={bloquearBtn === true ? "filled" : "outlined"}
                  error={errors.EmailRepetido ? true : false}
                  helperText={errors.EmailRepetido ? errors.EmailRepetido.message : ''}
                  FormHelperTextProps={{ sx:{backgroundColor: errors.EmailRepetido ? '#e4f0ec' : 'inherit', margin: '0px 0px 0px 0px'}}}
                  {...register('EmailRepetido', {
                    required: { value: true, message: 'Repeticion Email es requerido' },
                    pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, message: 'Email invalido' },
                    validate: value => value === getValues('Email') || 'Los emails no coinciden'
                  })}
                  value={datos.EmailRepetido}
                  onChange={handleInputChange}
                  sx={{backgroundColor: 'white'}}
                  disabled={bloquearBtn}
                />
              </Grid>
              
              {/* { tipoUsuario !== "1" && */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <Typography fontWeight={"bold"}>Foto actual</Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {
                          !editarFotoSlice.fotoActual &&  <Typography variant={"caption"}>No tiene foto</Typography>
                        }
                        {
                          editarFotoSlice.fotoActual && <img src={editarFotoSlice?.fotoActual}  alt={"Foto actual del profesional"} style={{ height: "200px", objectFit: "contain" }} />
                        }
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* } */}

              {/* { tipoUsuario !== "1" && */}
              <Grid item xs={6} >
                  <FormControl>
                    <Grid container spacing={2} >
                      <Grid item xs={12}>
                        <Typography fontWeight={"bold"}>¿Actualiza su foto?</Typography>
                        <FormLabel hidden={true} required={true} fontWeight={"bold"} color="primary"><b>¿Actualiza su foto?</b></FormLabel>
                      </Grid>
                      <Grid item xs={3}>
                        <ToggleButtonGroup
                            value={editarFotoSlice.deseaCambiarFoto}
                            exclusive
                            disabled={isEditionDisabled()}
                            sx={{ background: "white", width: "79px" }}
                            color="primary"
                            onChange={handlecambiaFoto}
                            aria-label="text cambiaDocumento"
                        >
                          <ToggleButton sx={{ width: "40px", height: "30px" }} value={true} aria-label="left aligned" selectedcolor="#b8daba"> SI</ToggleButton>
                          <ToggleButton sx={{ width: "40px", height: "30px" }} value={false} aria-label="centered" selectedcolor="#b8daba"> NO</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            {!isEditionDisabled() && editarFotoSlice.deseaCambiarFoto && 
                              <Button variant='contained' color='secondary' onClick={() =>mostrarAlertaTipoArchivos()} size='small'>
                                Subir foto
                              </Button>
                            }
                        </Box>
                      </Grid>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={10}>
                        { editarFotoSlice.deseaCambiarFoto && editarFotoSlice.fotoNueva && tipoUsuario !== "1" &&
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <MiniaturaFotoNueva 
                            disableEdit={isEditionDisabled()} 
                            src={editarFotoSlice.fotoNueva} 
                            onClickEditar={() => setOpenNuevaFoto(true)} 
                            customHeight={"130px"}
                            customWidth={"130px"}
                            />
                        </Box>
                        }
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  </FormControl>
              </Grid>
              {/* } */}
            </Grid>
          </CardContent>
        </Card>
        <Grid container>
          <Grid item md={6} xs={6} sx={{ mt: 3 }}>
            <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("1")} variant="contained" type="submit" color="error" disabled={bloquearBtn} >
              Atras
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
              <Button disabled={bloquearBtn} endIcon={<ArrowForwardIosIcon />} type='submit' variant="contained" color="secondary">
                {loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <input type="file" name="uploadFotoEditar" onChange={changeHandler} style={{ display: 'none' }} ref={hiddenFileInput} accept=".jpg, .jpeg, .png"/>
      <ModalNuevaFoto nuevaFoto={editarFotoSlice.fotoNuevaFullSize} open={openNuevaFoto} onClose={() => setOpenNuevaFoto(false)} datos={datos} setDatos={setDatos}/>
    </Grid>
  );
};