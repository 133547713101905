import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    blobDdjjAdjuntos: null,
};

const ddjjRRSlice = createSlice({
    name: 'ddjjRRReducer',
    initialState,
    reducers: {
        setDdjj: (state, action) => {
            state.blobDdjjAdjuntos = action.payload;
        },
        setClearDdjj: (state) => {
            state.blobDdjjAdjuntos = null;
        },
    },
});

export const { setDdjj, setClearDdjj } = ddjjRRSlice.actions;

export default ddjjRRSlice.reducer;