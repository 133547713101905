import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { obtenerProfConTitulos } from '../BajaPorFallecimiento/bajaFallecimientoApi';

const initialState = {
    titulosRR: [],
    loading: false,
    error: null,
};

export const obtenerTitulosRRAsync = createAsyncThunk('TitulosRR/fetchTitulosRRAsync',
    async data => {
        const { $values } = await obtenerProfConTitulos(data);
        // console.log($values)
        return $values;
    } 
);


export const titulosRRSlice = createSlice({
    name: 'titulosRR',
    initialState,
    reducers: {
        setTitulosRR: (state, action) => {
            state.titulosRR = action.payload;
        },
        clearTitulosRR: (state) => {
            state.titulosRR = [];
        }

    },
    extraReducers: (builder) => {
        return builder
            .addCase(obtenerTitulosRRAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(obtenerTitulosRRAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.titulosRR = action.payload
            })
            .addCase(obtenerTitulosRRAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            });
    }
});

// Selector para obtener el estado 'titulosRR' del estado global
export const selectTitulosRR = (state) => state.titulosRRReducer.titulosRR;

export default titulosRRSlice.reducer;

// Exportar las acciones
export const { setTitulosRR, clearTitulosRR } = titulosRRSlice.actions;