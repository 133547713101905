import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Autocomplete, Button, Select, Grid, TextField, MenuItem, InputLabel, Typography, FormControl, Card, Modal, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { useSelector, useDispatch } from 'react-redux';
import { tipoDocumentoList, getTipoDocumentoAsync } from 'app/features/TipoDocumento/tipoDocumentoSlice';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import { nacionalidadesList, getNacionalidadesAsync } from 'app/features/Nacionalidades/nacionalidadesSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { crearNuevoTramite, obtenerListaTramites, obtenerTokenBajaFallecimiento } from 'app/features/BajaPorFallecimiento/bajaFallecimientoApi';
import { TIPOS_USUARIO } from 'constants/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    option: {
        fontWeight: 'bold',
        color: 'black'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        bomdhadow: 24,
        p: 4,
        '@media (max-width:900px)': {
            top: '93%',
        }
    },
}));

export const FormDatosPersonales = ({ datos, bloquearBtn, setDatos, setValue, ObrasSociales, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setMatriculas, setCantidadTramites, tpoUser, open, setOpen, EstadoCivilList }) => {

    const { handleSubmit } = useForm();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const clases = useStyles();
    const TipoDocumentos = useSelector(tipoDocumentoList);

    // const EstadoCivilList = useSelector(estadoCivilList);
    
    const NacionalidadesList = useSelector(nacionalidadesList);
    const [labelObraSocial, setLabelObraSocial] = useState('');
    const [errorCuit, setErrorCuit] = useState({ display: "none" });
    const [deshabilitarCampo, setDeshabilitarCampo] = useState(false);
    // const [open, setOpen] = useState(true);
    const tipoUsuario = Number(localStorage.getItem('userType'));
    // const {deshabilitarCampo} = useSelector(state => state.codigoQrReducer);
    // const deshabilitarCampo = JSON.parse(localStorage.getItem('deshabilitarCampo'));

    const handleClose = () => setOpen(false);

    const ingresaNacionalidad = (event, value) => {
        if (value !== null) {
            setDatos({
                ...datos,
                Nacionalidad: value.value,
                CodNacionalidad: value.value === 'ARGENTINA' ? 1 : 2
            });
        } else {
            setDatos({
                ...datos,
                Nacionalidad: ''
            });
        }
    }
    

    let valueObraSocial = 0;

    const obtenerTramites = async() => {
        const resp = await obtenerListaTramites();

        if (resp !== false) {
            setCantidadTramites(resp.length);
            if (resp.length === 1 && resp[0].idEstado !== 1) {
                setDeshabilitarCampo(true);
            }
            if(resp.length > 1) {
                setDeshabilitarCampo(true);
            }
        }
        
    }

    const ingresaObraSocial = (event, value) => {

        if (value !== null) {
            setLabelObraSocial(value.label)

            setDatos({
                ...datos,
                ObraSocial: value.label,
                TpoOsocial: Number(value.value)
            });
        } else {
            setLabelObraSocial('')
            setDatos({
                ...datos,
                ObraSocial: '',
                TpoOsocial: ''
            });
        }
    }

    const mascaraCuit = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var cuitNuevo = "";
            let cuit = datos.CuitCuil;

            cuit = cuit.replace(/\-/g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < cuit.length; i++) {

                if (i === 1 || i === 9) {
                    cuitNuevo += cuit[i] + "-";
                } else {
                    cuitNuevo += cuit[i];
                }

            }
            setDatos({
                ...datos,
                CuitCuil: cuitNuevo
            });
        }
    }

    const mascaraDocumento = (evt) => {
        var codigo = evt.which || evt.keyCode;

        if (codigo !== 8) {
            var documentoNuevo = "";
            let documento = datos.NroDocumento;

            documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "").split('');

            for (var i = 0; i < documento.length; i++) {

                if (i === 1 || i === 4) {
                    documentoNuevo += documento[i] + ".";
                } else {
                    documentoNuevo += documento[i];
                }
            }
            setDatos({
                ...datos,
                NroDocumento: documentoNuevo
            });
        }

    }
    const inputHandleChange = (evt) => {
        if (evt.target.name === "Nacimiento") {
            var hoy = new Date();
            var fechaFormulario = new Date(evt.target.value);
            hoy.setHours(0, 0, 0, 0); // Lo iniciamos a 00:00 horas
    
            if (hoy <= fechaFormulario) {
                Swal.fire({
                    title: 'Atención',
                    html: 'La Fecha de Nacimiento no debe ser posterior a la fecha actual',
                    icon: 'warning', showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                });
                setDatos({
                    ...datos,
                    [evt.target.name]: ''
                });
                return false;
            }
        } else if (evt.target.name === "RelacionParentesco") {
            // Actualiza específicamente datos.bajaFallecimiento[0].codParentesco
            const newData = {...datos};
            newData.bajaFallecimiento[0].codParentesco = evt.target.value;
            setDatos(newData);
        } else if (evt.target.name === "sexo") {
            setDatos({
                ...datos,
                [evt.target.name]: Number(evt.target.value)
            });
        } else {
            setDatos({
                ...datos,
                [evt.target.name]: evt.target.value
            });
        }
    }

    
    const onSubmit = data => {
        handleClose();
    }

    let nacionalidadObjeto = [];

    NacionalidadesList.map((nacionalidad, index) => {

        nacionalidadObjeto.push({ label: nacionalidad.descNacionalidad.trim(), value: nacionalidad.descNacionalidad.trim() })
    })
    
    let obraSocialObjeto = [];
    let obraSocialNombre = "";

    ObrasSociales.map((obraSocial, index) => {
        if (obraSocialNombre.toUpperCase() !== obraSocial.sigla.trim().toUpperCase())
            obraSocialObjeto.push({ label: obraSocial.sigla.trim(), value: obraSocial.tpoOSocial })

        obraSocialNombre = obraSocial.sigla.trim();
    })
    
    let ObrasSocialesOrdenadas = [];

    if (obraSocialObjeto.length > 0) {
        obraSocialNombre = "";
        obraSocialObjeto.map((obraSocial, index) => {
            if (obraSocialNombre.toUpperCase() !== obraSocial.label.trim().toUpperCase()) {
                let agregarObraSocial = 0;
                ObrasSocialesOrdenadas.map(obraSocialRepetida => {
                    if (obraSocialRepetida.label.trim().toUpperCase() === obraSocial.label.trim().toUpperCase())
                        agregarObraSocial = 1;
                })
                if (agregarObraSocial === 0)
                    ObrasSocialesOrdenadas.push({ key: index, label: obraSocial.label.trim().toUpperCase(), value: obraSocial.value })
            }
            obraSocialNombre = obraSocial.label.trim();
        })
    }

    useEffect(() => {

        // if (EstadoCivilList.length === 0) {
        //     //llamo a la funcion del Slice
        //     dispatch(getEstadoCivilAsync());
        // }

        if (TipoDocumentos.length === 0) {
            dispatch(getTipoDocumentoAsync());
        }
        if (NacionalidadesList.length === 0) {
            dispatch(getNacionalidadesAsync());
        }
        ObrasSociales.map((obraSocial, index) => {
            if (datos.TpoOsocial !== "" && datos.TpoOsocial !== null && obraSocial.tpoOSocial === Number(datos.TpoOsocial)) {
                setLabelObraSocial(obraSocial.sigla.trim());
            }
        })
    }, [])

    // useEffect(() => {
    //     if( Number(localStorage.userType !== 1)){
    //         obtenerTramites();
    //     }
    // }, [])


    return (
        <Modal
            className={clases.modales}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className={clases.modal}sx={{ boxShadow: 5, width: '60%', borderColor: 'black', borderRadius: '5px', border: '1px solid black'}}>
                <CardContent>
                <Grid item container justifyContent="flex-end" >
                    <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                </Grid>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <span sx={{ mb: 2 }}></span>
                        <Grid container spacing={2}>
                        { tpoUser !== TIPOS_USUARIO.USUARIO_INTERNO &&
                            <>
                                <Grid item xs={12} md={12} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth variant={bloquearBtn === true ? "filled" : "outlined"}>
                                            <InputLabel id="labelEstadoCivil" sx={{ fontWeight: 'bold', color: 'black' }}>Estado Civil *</InputLabel>
                                            <Select
                                                labelId="labelEstadoCivil"
                                                name='EstadoCivil'
                                                onChange={inputHandleChange}
                                                required
                                                value={datos.EstadoCivil}
                                                disabled={bloquearBtn}
                                                label="Estado Civil"
                                            >
                                                { EstadoCivilList.map((eC, index) => 
                                                    <MenuItem key={index} value={eC.estadoCivil}><b>{eC.descripcion}</b></MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                        name='Nacionalidad'
                                        onChange={ingresaNacionalidad}
                                        options={nacionalidadObjeto}
                                        disabled={bloquearBtn}
                                        noOptionsText={'No se encontraron Nacionalidades'}
                                        value={{ label: datos.Nacionalidad !== undefined ? datos.Nacionalidad : '', value: datos.Nacionalidad !== undefined ? datos.Nacionalidad : '' }}
                                        getOptionLabel={(option) => option.label}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        renderInput={(params) => 
                                            <TextField {...params} variant={bloquearBtn === true ? "filled" : "outlined"} required label="Nacionalidad"  />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                    <TextField label="CUIT - CUIL"
                                        name='CuitCuil'
                                        onChange={inputHandleChange}
                                        onKeyUp={mascaraCuit}
                                        inputProps={{ maxLength: 13, style: {fontWeight: 'bold'}}}
                                        value={datos.CuitCuil}
                                        required
                                        disabled={bloquearBtn}
                                        variant={bloquearBtn === true ? "filled" : "outlined"}
                                        fullWidth
                                        type="text"
                                        InputLabelProps={{ sx: { fontWeight: 'bold', color: 'black' }}}
                                    />
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Autocomplete
                                        name='obraSocial'
                                        onChange={ingresaObraSocial}
                                        options={ObrasSocialesOrdenadas}
                                        noOptionsText={'No se encontraron Obras Sociales'}
                                        value={{ label: datos.ObraSocial, value: datos.ObraSocial }}
                                        getOptionLabel={(option) => option.label}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        renderInput={(params) => <TextField {...params} variant={bloquearBtn === true ? "filled" : "outlined"} required label="Obra Social" />}
                                        sx={{backgroundColor: "white"}}
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                            
                            <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                                <Button endIcon={<ArrowForwardIosIcon />} disabled={bloquearBtn} variant="contained" type="submit" color="secondary" >{loaderBtnGuardar === true ? <CircularProgress /> : ''}Guardar</Button>
                            </Grid>

                        </Grid>
                    </form >
                </CardContent>
            </Card>
        </Modal>
    )
}