import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getfoto } from './fotoProfesionalAPI';
const initialState = {
    fotoActual: null,
    loading: false,
    error: null,
    fotoNueva: null,
    fotoNuevaFullSize: null,
    deseaCambiarFoto: null,
    cambiaDNI: null
};


export const fetchFotoProfesionalAsync = createAsyncThunk(
    'fotoProfesional/fetchFotoProfesionalAsync',
    async legajo => await getfoto(legajo)
);

export const editarDatosProfesionalSlice = createSlice({
    name: 'editarDatosProfesional',
    initialState,
    reducers: {
        setFotoNueva: (state, action) => {
            state.fotoNueva = action.payload;
        },
        setFotoNuevaFullSize: (state, action) => {
            state.fotoNuevaFullSize = action.payload;
        },
        setCambiaFoto: (state, action) => {
            state.deseaCambiarFoto = action.payload;
        },
        setCambiaDNI: (state, action) => {
            state.cambiaDNI = action.payload;
        },
        clearFotoNueva: (state) => {
            state.fotoNuevaFullSize = null;
            state.fotoNueva = null;
        }
    },
    extraReducers: (builder) => {
        return builder
            .addCase(fetchFotoProfesionalAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFotoProfesionalAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.fotoActual = action.payload
            })
            .addCase(fetchFotoProfesionalAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            });
    }
});

export const fotoProfesionalSelector = (state) => state.fotoProfesionalReducer;

export default editarDatosProfesionalSlice.reducer;

export const setFotoNueva = editarDatosProfesionalSlice.actions.setFotoNueva;
export const setCambiaFoto = editarDatosProfesionalSlice.actions.setCambiaFoto;
export const setCambiaDNI = editarDatosProfesionalSlice.actions.setCambiaDNI;
export const setFotoNuevaFullSize = editarDatosProfesionalSlice.actions.setFotoNuevaFullSize;
export const clearFotoNueva = editarDatosProfesionalSlice.actions.clearFotoNueva
