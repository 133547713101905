import axios from 'axios';

import {
	CANCELAR_TRAMITE,
	HEADERS_TOKEN,
	MATRICULAS,
	OBTENER_TIEMPO_ESPERA_TRAMITE
} from 'constants/routes/endpoints';


export const marcarDocumentacionComoEnviada = (idLegajoTemporal) => {
	
	return new Promise((resolve, reject) => {

		return axios({
			url: MATRICULAS + `${idLegajoTemporal}/marcarDocumentacionEnviada`,
			method: 'POST',
			headers: HEADERS_TOKEN,
		})
			.then(response => response)
			.then(json => {

				return resolve({ data: json.data });
			})
			.catch(err => {
				var errorJson = err.toJSON();
				console.log(errorJson.config.url);
				console.log(errorJson.message);
				return resolve({ data: {mensaje : 'Por favor vuelva a intentarlo nuevamente'} });
			});
	});
}

export const guardarDatosMatriculas = ({ datos, estado, tpo_tramite, accion, fechaAprobacionDocumentacion, nroResolucionAprobacionDocumentacion, tpo_usuario }) => {

	return new Promise((resolve, reject) => {

		const data = {
            idLegajoTemporal: localStorage.idLegajoTemporal,
            idEstado: estado,
            datosTemporales: datos,
            TpoTramite: tpo_tramite,
            accion: accion,
            fechaAprobacionDocumentacion: fechaAprobacionDocumentacion,
            nroResolucionAprobacionDocumentacion: nroResolucionAprobacionDocumentacion,
        };

        if (tpo_usuario === 11) {
            data.tpo_usuario = tpo_usuario;
        }

		return axios({
			url: MATRICULAS,
			method: 'PUT',
			headers: HEADERS_TOKEN,
			data: data
		})
			.then(response => response)
			.then(json => {
				
				return resolve({ data: json.data });
			})
			.catch(err => {
				var errorJson = err.toJSON();
				console.log(errorJson.config.url);
				console.log(errorJson.message);
				return resolve({ data: {mensaje : 'Por favor vuelva a intentarlo nuevamente'} });
			});
	});
};

export const guardarDatosAdjuntos = ({ datos, estado, tpo_tramite }) => {

	return new Promise((resolve, reject) => {

		return axios({
			url: MATRICULAS + 'EnviarAdjuntoProfesional' ,
			method: 'PUT',
			headers: HEADERS_TOKEN,
			data: {
				idLegajoTemporal: localStorage.idLegajoTemporal,
				idEstado: estado,
				datosTemporales: datos,
				TpoTramite: tpo_tramite
			},
		})
			.then(response => response)
			.then(json => {

				return resolve({ data: json.data });
			})
			.catch(err => {
				var errorJson = err.toJSON();
				console.log(errorJson.config.url);
				console.log(errorJson.message);
				return resolve({ data: {mensaje : 'Por favor vuelva a intentarlo nuevamente'} });
			});
	});
};

export const validarDatosMatriculas = () => {

	return new Promise((resolve, reject) => {

		return axios({
			url: MATRICULAS.slice(0, -1),
			method: 'POST',
			headers: HEADERS_TOKEN,
			data: {
				usuario: encodeURIComponent(localStorage.usuarioValidarDatos),
				password: encodeURIComponent(localStorage.passValidarDatos),
				idLegajoTemporal: Number(localStorage.idLegajoTemporal)
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 500) {
					window.location.href = `/login`;
				}
				return resolve({ data: err.response.status });
			});
	});
};



export const getMatricula = ({ idLegajoTemporalVista, token }) => {

	let idLegajoTemporal = localStorage.idLegajoTemporal;

	//validacion para cuando consultamos los datos desde el login del editar datos
	if (idLegajoTemporalVista !== null && idLegajoTemporalVista !== undefined)
		idLegajoTemporal = idLegajoTemporalVista


	let tokenNuevo = HEADERS_TOKEN;


	if (token !== null && token !== undefined) {
		tokenNuevo = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}
	}


	return new Promise((resolve, reject) => {
		// console.log(tokenNuevo);
		// console.log(idLegajoTemporal);
		return axios({
			url: MATRICULAS + idLegajoTemporal,
			method: 'GET',
			headers: tokenNuevo,
		})
			.then(response => response)
			.then(json => {

				return resolve({ data: json.data });
			})
			.catch(err => {


				if (err.response === undefined) {
					localStorage.clear();
					window.location.href = `/login`;

				}

				if (err.response.status === 401 || err.response.status === 500) {
					localStorage.clear();
					window.location.href = `/login`;
				}
			});
	});
};


export const obtenerTiempoDeEsperaTramite = async() => {
	
	try {
		let resp = await axios.get(`${OBTENER_TIEMPO_ESPERA_TRAMITE}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}


export const cancelarTramite = async() => {
	const idLegajoTemporal = localStorage.idLegajoTemporal;
	try {
		let resp = await axios.post(`${CANCELAR_TRAMITE}/${idLegajoTemporal}`, {headers: HEADERS_TOKEN});
		resp = JSON.parse(resp.data);
		return resp;
	} catch (error) {
		console.log(error)
		return false;
	}
}

export const enviarBeacon = () => {
	const idLegajoTemporal = localStorage.idLegajoTemporal;
	const url = `${CANCELAR_TRAMITE}/${idLegajoTemporal}`;
    navigator.sendBeacon(url);
}

