import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from "@mui/styles";
import { comunicacionList, getComunicacionAsync, postComunicacionAsync, updateComunicationList } from 'app/features/comunicaciones/comunicacionesSlice';
import { postCambioEstadoTramite } from 'app/features/Profesionales/profesionalesAPI';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import CheckIcon from '@mui/icons-material/Check';
import { Button, TextField, Grid, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
    wrapForm: {
        display: "flex",
        justifyContent: "center",
        width: "95%",

        margin: `${theme.spacing(0)} auto`,
        marginBottom: "10px!important",
    },
    wrapText: {
        width: "100%"
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1,
        justifyContent: "center",
        width: "95%",
    },
    button: {
        margin: theme.spacing(1),
    },
})
);

export const TextInputRR = ({ datos, setComunicacionList, setDatos, setIdEstado, tpoTramite }) => {
    const classes = useStyles();
    const [observacion, setObservacion] = useState('');
    const [bloquearBotonChat, setBloquearBotonChat] = useState(false);
    const [mensajeCorrecto, setMensajeCorrecto] = useState({ display: 'none' });
    const [ocultarFormulario, setOcultarFormulario] = useState({ display: '' });
    const dispatch = useDispatch();
    const { handleSubmit } = useForm();

    const enviarMensaje = (evt) => {

        const data = {
            IdLegajoTemporal: Number(localStorage.idLegajoTemporal),
            Message: observacion,
            User: localStorage.usuario,
            UsrType: localStorage.userType,
            TpoTramite: tpoTramite
        };

        setBloquearBotonChat(true);
        dispatch(postComunicacionAsync(data)).then(response => {
            setBloquearBotonChat(false);

            if (response.payload.resultado === true) {
                dispatch(getComunicacionAsync(localStorage.idLegajoTemporal)).then(valor => {

                    setComunicacionList(valor.payload);

                });
                //postCambioEstadoTramite()

                 if (localStorage.userType === "1" || localStorage.userType === "6") {
                    setDatos({
                        ...datos,
                        Observado: true
                    })

                //     datos.Observado = true;
                    setIdEstado(6)
                //     guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: 6, tpo_tramite: localStorage.validacionTitulo === "1" ? 8 : 1 }).then((value) => { });
                 }

                setObservacion('');
                setMensajeCorrecto({ display: '' });
                setOcultarFormulario({ display: 'none' });
                
                setTimeout(() => {
                    setMensajeCorrecto({ display: 'none' });
                    setOcultarFormulario({ display: '' });
                }, 3000);
              
           }

        });

     
    }

    const inputHandleChange = (evt) => {
        setObservacion(evt.target.value);
    }


    return (
        <Fragment>
            <Grid container sx={{ mt: 5 }} style={mensajeCorrecto} className={classes.mensajeCorrecto} align='center'
                direction="column" >
                <Grid item xs={12} aling="center">
                    <CheckIcon
                        sx={{ color: green[600] }}
                        style={{ fontSize: 40 }}
                    />
                    <Typography sx={{ mb: 1 }} ><b>Observación enviada correctamente </b></Typography>
                </Grid>
            </Grid>
            <Typography className={classes.color_texto_aclaracion} style={ocultarFormulario} sx={{ mb: 1 }} >Incluya su observación en el cuadro de texto</Typography>
            <form className={classes.wrapForm} autoComplete="off" style={ocultarFormulario} onSubmit={handleSubmit(enviarMensaje)}>


                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextField
                            name='observacion'
                            multiline
                            rows={4}
                            label="Observacion"
                            value={observacion}
                            disabled={bloquearBotonChat}
                            required
                            onChange={inputHandleChange}
                            className={classes.wrapText} />

                    </Grid>
                    <Grid item md={12} aling='center'>
                        <Button disabled={bloquearBotonChat} type='submit' variant="contained" color="primary"><SendIcon /> 
                        
                        
                        {bloquearBotonChat === true ? <CircularProgress /> : ''}Enviar Observación</Button>
                    </Grid>
                </Grid>
            </form>
        </Fragment >
    )
}



