import React, { useEffect, useState } from 'react';
import {renderToString} from 'react-dom/server'
import { Button, Card, CardContent, Fab, Grid, Typography, Collapse, Divider, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { guardarDatosMatriculas } from 'app/features/Matriculas/matriculasApi';
import { useSelector, useDispatch } from 'react-redux';
import { getProfesionalValidarCuit } from 'app/features/Profesionales/profesionalesAPI';
import { estadoCivilList, getEstadoCivilAsync } from 'app/features/EstadoCivil/estadoCilvilSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { FormDatosPersonales } from './FormDatosPersonales';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { Domicilios } from './Domicilios';
import { GrupoFamiliar } from './GrupoFamiliar';
import { nuevoAdjunto } from 'utils/adjuntosLogic';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        backgroundColor: 'red'
    },
    button: {
        display: "flex!important",
        justifyContent: "flex-end!important"
    },
    color_texto_aclaracion: {
        color: 'gray',
        fontSize: 1
    },
    color_rojo: {
        color: 'red'
    },
    option: {
        fontWeight: 'bold',
        color: 'black'
    },
}));

export const OtrosDatos = ({ datos, libraries, bloquearBtn, setDatos, setValue, ObrasSociales, loaderBtnGuardar, setLoaderBtnGuardar, setBloquearBtn, idEstado, setMatriculas, setCantidadTramites, tpoUser, location, setLocation, datosModificados, datosDomicilioModificados, datosFamiliarModificados}) => {

    const dispatch = useDispatch()
    const [errorCuit, setErrorCuit] = useState({ display: "none" });
    const [deshabilitarCampo, setDeshabilitarCampo] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [openModal1, setOpenModal1] = useState(false);
    const EstadoCivilList = useSelector(estadoCivilList);
    const tipoUsuario = Number(localStorage.userType);
    const editarFotoSlice = useSelector(state => state.editarDatosProfesionalReducer);

    const estadoCivilDescripcion = EstadoCivilList.find(estado => estado.estadoCivil === datos.EstadoCivil)?.descripcion;
    const datosModificadosFiltrado = datosModificados.filter(dato => dato !== 'Email');
    
    const onSubmit = data => {
        let erroresMostrar = "";
        let mostrarErrorDomicilio = 0;
        let documento = datos.NroDocumento;
        documento = documento.replace(/\./g, '').replace(/[a-z ]/g, "");
        let cuit = datos.CuitCuil;
        cuit = cuit.substring(3);
        cuit = cuit.substring(0, 8);

        if(cuit.startsWith("0")) {
            cuit = cuit.substring(1);
        }

        if (datos.CuitCuil.length !== 13 || cuit !== documento) {
            Swal.fire({
                title: 'Atención', html: 'El CUIT-CUIL ingresado no es valido',
                icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            })
            return false;
        }

        if(!editarFotoSlice.fotoActual && !editarFotoSlice.fotoNueva){
            Swal.fire({ title: 'Atención', html: 'Debe cargar una foto actual', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
            return false;
        }

        if(datos.ObraSocial === '' || datos.ObraSocial === 'Sin Datos'){
            Swal.fire({ title: 'Atención', html: 'Debe seleccionar una obra social', icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true});
            return false;
        }

        datos.domicilios.map((domicilio, index) => {

            const errores = [];

            if (domicilio.calle === "") errores.push("calle");
            if (domicilio.celular === "") errores.push("celular");
            if (domicilio.caracteristica === null) errores.push("característica");
            if (domicilio.caracteristica && domicilio.caracteristica.length > 5) errores.push("característica (longitud mayor a 5)");
            if (domicilio.caracteristicaFijo && domicilio.caracteristicaFijo.length > 5) errores.push("característica fija (longitud mayor a 5)");
            if (isNaN(domicilio.caracteristica)) errores.push("característica (no es un número)");
            if (isNaN(domicilio.celular)) errores.push("celular (no es un número)");
            
        
            if (errores.length > 0 && mostrarErrorDomicilio === 0) {
                if (erroresMostrar !== "") erroresMostrar += ", ";
                erroresMostrar += `<b>Domicilios</b>: ${errores.join(", ")}`;
                mostrarErrorDomicilio = 1;
            }

        })

        if (erroresMostrar !== "") {
            Swal.fire({
                title: 'Atención', html: 'Debe completar correctamente los siguientes datos:' + erroresMostrar, 
                icon: 'warning', showCloseButton: true, showCancelButton: false, 
                confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
            });
            return false;
        }

        if (editarFotoSlice.deseaCambiarFoto) {
            let adjuntoDeFoto = datos.adjuntos.find(a => a.fileType === "FotoPerfil");
            if (adjuntoDeFoto) {
                adjuntoDeFoto.file = editarFotoSlice.fotoNueva;
            } else {
                adjuntoDeFoto = nuevoAdjunto("FotoPerfil", "image/jpeg", editarFotoSlice.fotoNueva);
                datos.adjuntos.push(adjuntoDeFoto);
            }
            let adjuntoDeFotoFull = datos.adjuntos.find(a => a.fileType === "FotoPerfilFull");
            if (adjuntoDeFotoFull) {
                adjuntoDeFotoFull.file = editarFotoSlice.fotoNuevaFullSize;
            } else {
                adjuntoDeFotoFull = nuevoAdjunto("FotoPerfilFull", "image/jpeg", editarFotoSlice.fotoNuevaFullSize);
                datos.adjuntos.push(adjuntoDeFotoFull);
            }


        }

        getProfesionalValidarCuit(datos.CuitCuil.replace(/-/gi, '')).then((value) => {
            if (!value.data.response) {
                setErrorCuit({ display: '' })
                return false;
            } else {
                setLoaderBtnGuardar(true)
                setBloquearBtn(true);
                guardarDatosMatriculas({ datos: JSON.stringify(datos), estado: idEstado, tpo_tramite: Number(localStorage.tpoTramite)}).then(async(value) => {
                    setMatriculas(value.data);
                    setLoaderBtnGuardar(false);
                    setBloquearBtn(false);
                    if (value.data.resultado) {
                        setValue("4");
                    } else {
                        Swal.fire({
                            title: 'Atención', html: value.data.mensaje !== '' && value.data.mensaje !== null ? value.data.mensaje : 'Ocurrio un error al guardar los datos. Por favor vuelva a intentarlo',
                            icon: 'warning',showCloseButton: true, showCancelButton: false, confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', allowOutsideClick: true
                        })
                        return false;
                    }
                });
            }
        });
    }

    const handleCollapse = (index, value) => {
        setOpen1(value && (index == 1));
        setOpen2(value && (index == 2));
        setOpen3(value && (index == 3));
    }

    useEffect(() => {
        if (EstadoCivilList.length === 0) {
            dispatch(getEstadoCivilAsync());
        }
    }, [])

    useEffect(() => {
        if ( tipoUsuario === 0) {
            const iconHtml = renderToString(<ArrowDownwardRoundedIcon style={{ fontSize: '24px', verticalAlign: 'middle' }} />);
            Swal.fire({
                title: 'Atención',
                html: `Verifique sus datos personales, domicilios y grupo familiar haciendo clic en los ${iconHtml} correspondientes.`,
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: true
            });
        }
    }, []);
    
    return (
        <>  
            <Typography style={{ fontSize: 14 }} mb={2}>
                <b>Verificar información.</b> 
            </Typography>
            <Grid display='flex' alignItems='center' pb={2}>

                <Fab size="small" color="secondary" aria-label="edit" onClick={() => handleCollapse(1, !open1)}>
                    { open1?<ArrowUpwardRoundedIcon />:<ArrowDownwardRoundedIcon /> }
                </Fab>
                <Typography ml={2} fontSize={18}> Datos Personales </Typography>
                {
                    datosModificadosFiltrado.length > 0 && 
                    <Tooltip
                        title={`Se han modificado los siguientes datos: ${datosModificadosFiltrado.join(", ")}` }
                        placement="right"
                    >
                        <WarningAmberOutlinedIcon sx={{ color: 'red !important', ml: 1}}/>
                    </Tooltip>
                }
            </Grid>
            <Collapse in={open1}>
                <Card sx={{ boxShadow: 5, background: '#e4f0ec', width: '60%', borderColor: '#b8daba', borderRadius: '5px', border: '3px solid #b8daba'}} >
                    <CardContent>
                        <Grid container>
                            {(localStorage.userType !== "1" && bloquearBtn !== true) &&
                                <Grid item sx={{ width: '25%', position: 'absolute', right: '22%' }}>
                                    <Fab size="small" color="secondary" aria-label="edit"onClick={() => setOpenModal1(true)} >
                                        <EditIcon />
                                    </Fab>
                                </Grid>
                            }
                            <Grid item xs={12} md={12} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography>Estado Civil: <b>{estadoCivilDescripcion}</b></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Nacionalidad: <b>{datos.Nacionalidad}</b></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography>CUIT/CUIL: <b>{datos.CuitCuil}</b></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Obra Social: <b>{datos.ObraSocial}</b></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Collapse>
            <Divider sx={{ my: 2 }} />
            <Grid display='flex' alignItems='center' pb={2}>
                <Fab size="small" color="secondary" aria-label="edit" onClick={() => handleCollapse(2, !open2)}>
                    { open2?<ArrowUpwardRoundedIcon />:<ArrowDownwardRoundedIcon /> }
                </Fab>
                <Typography ml={2} fontSize={18}> Domicilios </Typography>
                {
                    datosDomicilioModificados.length > 0 &&
                    <Tooltip
                        title={`Se han modificado los siguientes domicilios: ${datosDomicilioModificados[0].changedProperties.join(", ")}` }
                        placement="right"
                    >
                        <WarningAmberOutlinedIcon sx={{ color: 'red !important', ml: 1}}/>
                    </Tooltip>
                }
            </Grid>
            <Collapse in={open2}>
                <Domicilios datos={datos} libraries={libraries} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} location={location} setLocation={setLocation} ValorTab={setValue} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} editarDatos={0} setMatriculas={setMatriculas} />
            </Collapse>
            <Divider sx={{ my: 2 }} />
            <Grid display='flex' alignItems='center' pb={2}>
                <Fab size="small" color="secondary" aria-label="edit" onClick={() => handleCollapse(3, !open3)}>
                    { open3?<ArrowUpwardRoundedIcon />:<ArrowDownwardRoundedIcon /> }
                </Fab>
                <Typography ml={2} fontSize={18}> Grupo Familiar Primario  </Typography>
                {
                    datosFamiliarModificados.length > 0 &&
                    <Tooltip
                    title={`Se han modificado los siguientes datos: ${datosFamiliarModificados.length > 1 
                        ? datosFamiliarModificados.map(dato => dato.changedProperties.filter(prop => prop !== 'estado').map(prop => prop === 'codNacionalidad' ? 'Nacionalidad' : prop).join(", ")).join("; ") 
                        : datosFamiliarModificados[0].changedProperties.filter(prop => prop !== 'estado').map(prop => prop === 'codNacionalidad' ? 'Nacionalidad' : prop).join(", ")}`}
                        placement="right"
                    >
                        <WarningAmberOutlinedIcon sx={{ color: 'red !important', ml: 1}}/>
                    </Tooltip>
                }
            </Grid>
            <Collapse in={open3}>
                <Grid container>
                    <GrupoFamiliar datos={datos} setDatos={setDatos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setValue={setValue} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} />
                </Grid>
            </Collapse>
            <Grid container>
                <Grid item md={6} xs={6} sx={{ mt: 3 }} >
                    <Button startIcon={<ArrowBackIosIcon />} onClick={() => setValue("2")} variant="contained" type="submit" color="error" disabled={bloquearBtn} >
                        Atras
                    </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid sx={{ mt: 3 }} container justifyContent="flex-end">
                        <Button disabled={bloquearBtn} endIcon={<ArrowForwardIosIcon />} onClick={onSubmit} variant="contained" color="secondary" >
                            {loaderBtnGuardar === true ? <CircularProgress /> : ''}Continuar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                openModal1 &&
                <FormDatosPersonales datos={datos} bloquearBtn={bloquearBtn} setBloquearBtn={setBloquearBtn} setDatos={setDatos} setValue={setValue} ObrasSociales={ObrasSociales} idEstado={idEstado} loaderBtnGuardar={loaderBtnGuardar} setLoaderBtnGuardar={setLoaderBtnGuardar} setMatriculas={setMatriculas} open={openModal1} setOpen={setOpenModal1} EstadoCivilList={EstadoCivilList}/>
            }
        </>
    );
}